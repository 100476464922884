import { OfferDto } from "@hub-la/sdk-bff-product"

export const getOfferLongerPlan = (offer: OfferDto) => {
  if (offer.plans && offer?.plans?.length > 0) {
    const longerPlan = offer.plans?.sort((a, b) => b.installments - a.installments)[0]

    return longerPlan
  }
  return
}

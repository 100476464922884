import { useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, AlertDescription, AlertTitle, Badge, Button, Card, CardContent, Label, Separator } from '@hub-la/shadcn'

import { Check, Circle, Edit } from 'lucide-react'
import { BRAZILIAN_BANKS } from '../../domain/constants/brazilian-banks'
import { Document } from '../../domain/vos/document'
import { formatAddress } from '../../domain/vos/format-address'
import { SoftDescriptor } from '../../domain/vos/soft-descriptor'
import { HasPendency } from '../../usecases/has-pendency'
import { FormSteps, Values } from '../pages/account-verification-modal'
import { FormHeader } from './form-header'

type Props = {
  onComplete: () => void
  onFinish?: () => void
  isSuccess: boolean
  loading?: boolean
  setStep: (step: number) => void
}

const ReviewAndSave: React.FC<Props> = ({ onComplete, loading, isSuccess, onFinish, setStep }) => {
  const { t } = useTranslation()
  const { values, isValid } = useFormikContext<Values>()

  const hasPendency = new HasPendency(values.pendencies)
  const hasPersonalInfoPendency = hasPendency.hasAnyOfPendencies([
    'identity.name',
    'identity.email',
    'identity.phone',
    'identity.document.value',
  ])
  const hasIdWallPendency = hasPendency.hasAnyOfPendencies([
    'liveness',
    'forense_id',
    'datamatch',
    'ocr',
    'data_bureaus',
    'facematch',
    'facelink',
    'hubla_vencimento_cnh',
    'SERPRO',
    'documentoscopia',
  ])

  const getHeader = (): { title: string; subtitle: string } => {
    if (isSuccess) {
      return {
        title: t('accountVerification.form.reviewAndSave.title.success'),
        subtitle: t('accountVerification.form.reviewAndSave.subtitle.success'),
      }
    } else if (hasPendency.hasAnyPendency()) {
      return {
        title: t('accountVerification.form.reviewAndSave.title.error'),
        subtitle: t('accountVerification.form.reviewAndSave.subtitle.error'),
      }
    }
    return {
      title: t('accountVerification.form.reviewAndSave.title.default'),
      subtitle: t('accountVerification.form.reviewAndSave.subtitle.default'),
    }
  }

  const Section = ({ title, children, hasError = false, onEdit }) => (
    <div className="space-y-2">
      <div className="flex justify-between items-center">
        <h3 className={`text-base font-semibold ${hasError ? 'text-red-500' : ''}`}>{title}</h3>
        <Badge variant="outline" className="rounded-lg cursor-pointer hover:bg-secondary h-8" onClick={onEdit}>
          <Edit className="w-3 h-3 mr-2" /> Atualizar
        </Badge>
      </div>
      <Card className="bg-secondary shadow-none">
        <CardContent className="pt-5">{children}</CardContent>
      </Card>
    </div>
  )

  const InfoItem = ({ label, value }) => (
    <div className="space-y-1">
      <Label>{label}</Label>
      <p className="text-muted-foreground text-sm">{value ? value : '-'}</p>
    </div>
  )

  return (
    <div className="space-y-6 w-full">
      <FormHeader title={getHeader().title} subtitle={getHeader().subtitle} />
      {isSuccess && (
        <div className="w-fit">
          <Button onClick={onFinish}>Pronto</Button>
        </div>
      )}

      <Section title={t('Dados empresariais')} onEdit={() => setStep(FormSteps.COMPANY_DATA)}>
        <InfoItem
          label={t('Tipo de empresa')}
          value={t(`accountVerification.form.modality.${values.identity.document.type}`)}
        />
      </Section>

      <Section
        title={t('Dados pessoais do administrador da empresa')}
        hasError={hasPersonalInfoPendency}
        onEdit={() => setStep(FormSteps.PERSONAL_INFO)}
      >
        {hasPersonalInfoPendency && (
          <Alert variant="destructive" className="mb-4">
            <AlertTitle>{t('pendencies.personalInfo.title')}</AlertTitle>
            <AlertDescription>{t('pendencies.personalInfo.subtitle')}</AlertDescription>
          </Alert>
        )}
        <div className="space-y-4">
          <InfoItem
            label={t('Nome completo')}
            value={values.identity.legalRepresentative?.fullName || values.identity.fullName}
          />
          <Separator className="my-2" />
          <InfoItem label={t('Email')} value={values.identity.email} />
          <Separator className="my-2" />
          <InfoItem
            label={t('CPF')}
            value={Document.build(
              values.identity.legalRepresentative?.document.value || values.identity.document.value,
            ).getValue()}
          />
          <Separator className="my-2" />
          <InfoItem label={t('Endereço')} value={formatAddress(values.identity.billingAddress)} />
        </div>
      </Section>

      <Section title={t('Dados públicos')} onEdit={() => setStep(FormSteps.PUBLIC_INFO)}>
        <InfoItem
          label={t('Descrição no extrato')}
          value={SoftDescriptor.build(values.businessConfiguration.creditCard?.softDescriptor ?? '').withPrefix()}
        />
      </Section>

      <Section title={t('Dados bancários')} onEdit={() => setStep(FormSteps.WITHDRAW_INFO)}>
        {values.isPixKey ? (
          <InfoItem label={t('accountVerification.form.pixKey.title')} value={values.identity.document.value} />
        ) : (
          <div className="space-y-4">
            <InfoItem
              label={t('accountVerification.form.bankAccount.bank')}
              value={BRAZILIAN_BANKS.find((bank) => bank.code === values.bankAccount.bankNumber)?.name}
            />
            <Separator className="my-2" />
            <InfoItem
              label={t('accountVerification.form.bankAccount.agency')}
              value={`${values.bankAccount.agencyNumber}${values.bankAccount.agencyCheckNumber}`}
            />
            <Separator className="my-2" />
            <InfoItem
              label={`${t('accountVerification.form.bankAccount.account')} + ${t(
                'accountVerification.form.bankAccount.accountDigit',
              )}`}
              value={`${values.bankAccount.accountNumber}${
                values.bankAccount.accountCheckNumber ? `-${values.bankAccount.accountCheckNumber}` : ''
              }`}
            />
            <Separator className="my-2" />
            <InfoItem
              label={t('accountVerification.form.bankAccount.accountType')}
              value={t(`accountVerification.form.bankAccount.types.${values.bankAccount.accountType}`)}
            />
          </div>
        )}
      </Section>

      <Section
        title={t('Verificar identidade')}
        hasError={hasIdWallPendency}
        onEdit={() => setStep(FormSteps.VERIFY_IDENTITY)}
      >
        {hasIdWallPendency && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>
              {t('Não conseguimos verificar sua identidade através das informações fornecidas.')}
            </AlertDescription>
          </Alert>
        )}
        <div className="flex items-center space-x-2">
          <Label>{t('Status')}:</Label>
          <p className="ml-4 text-sm text-muted-foreground">
            {values.sdkToken
              ? t('accountVerification.form.idwall.status.success')
              : t('accountVerification.form.idwall.status.pending')}
          </p>
          {values.sdkToken ? (
            <Check className="text-green-500 w-4 h-4" />
          ) : (
            <Circle className="text-muted-foreground w-4 h-4" />
          )}
        </div>
      </Section>

      {!isSuccess && (
        <div className="flex justify-end">
          <Button disabled={!isValid} onClick={onComplete}>
            {loading ? 'Carregando...' : t('accountVerification.form.submit')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default ReviewAndSave

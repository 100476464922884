import { UpdateProductSettingsPageRequestDto } from '@hub-la/sdk-bff-product'
import { Button, Card, CardContent, Input, Textarea } from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { Plus } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Section } from '../../../components/section'
import { useSaveSettings } from '../../../hooks/settings/use-save-settings'

type Values = Pick<UpdateProductSettingsPageRequestDto, 'faq'>
const validationSchema = Yup.object().shape({
  faq: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        question: Yup.string().min(5, 'Pergunta muito curta!').max(50, 'Pergunta muito longa!'),
        answer: Yup.string().min(3, 'Resposta muito curta!').max(700, 'Resposta muito longa!'),
      }),
    )
    .min(1, 'Você precisa adicionar pelo menos um item')
    .max(8, 'O máximo de itens é 8'),
})
const makeInitialValues = ({ faq }: Values): Values => {
  return {
    faq: faq ?? [{ question: '', answer: '' }],
  }
}

type Props = {
  faq?: Values['faq']
  loading?: boolean
}

export const FaqSection: React.FC<Props> = ({ faq, loading }) => {
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: saveSettings } = useSaveSettings()
  const { t } = useTranslation()

  const formik = useFormik<Values>({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: makeInitialValues({ faq }),
    onSubmit: async (values) => {
      await saveSettings({ productId, type: 'page', data: values })
    },
  })

  return (
    <Section
      title={t('settings.productPage.faq.title')}
      subtitle={t('settings.productPage.faq.subtitle')}
      formik={formik}
    >
      <div className="space-y-4 w-full">
        {(formik.values.faq ?? []).map((faq, idx) => (
          <Card key={idx}>
            <CardContent className="space-y-4 pt-6">
              <Input
                placeholder={t('settings.productPage.faq.input.title')}
                name={`faq[${idx}].question`}
                value={faq.question}
                onChange={formik.handleChange}
                maxLength={50}
                disabled={loading}
              />

              <Textarea
                placeholder={t('settings.productPage.faq.input.subtitle')}
                name={`faq[${idx}].answer`}
                value={faq.answer}
                onChange={formik.handleChange}
                maxLength={700}
                disabled={loading}
                className="min-h-[100px]"
              />

              <p className="text-sm text-muted-foreground">{`${faq.answer.length}/700 caracteres.`}</p>
            </CardContent>
          </Card>
        ))}

        {(formik.values.faq?.length ?? 0) < 8 && (
          <Button
            variant="outline"
            size="sm"
            onClick={() => formik.setFieldValue('faq', [...(formik.values.faq ?? []), { question: '', answer: '' }])}
            loading={loading}
          >
            <Plus className="mr-2 h-4 w-4" />
            {t('settings.productPage.faq.input.add')}
          </Button>
        )}
      </div>
    </Section>
  )
}

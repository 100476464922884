export const pt = {
  title: 'Configurações',
  tabs: {
    profile: 'Minha conta',
    collaborators: 'Colaboradores',
    notifications: 'Notificações',
  },
  collaboratorsList: { button: 'Adicionar colaborador', description: 'Permissões' },
  createCollaborator: {
    title: 'Adicionar colaborador',
    description:
      'Adicione as informações do colaborador e defina suas permissões dentro da plataforma. Só um detalhe, o colaborador já precisa ter conta na Hubla.',
  },
  collaboratorForm: {
    nameLabel: 'Nome',
    emailLabel: 'E-mail',
    subpermissionsDetails: 'Detalhes',
    permissionsTitle: 'Permissões',
    permissionsHelpLink: 'Entenda os níveis de permissões disponíveis',
    submitButton: 'Adicionar colaborador',
    updateButton: 'Atualizar colaborador',
  },

  selectUserLoginNavigationTarget: {
    title: 'Escolha como acessar',
    creatorNavigationTarget: {
      title: 'Acessar como produtor',
      subtitle: 'Para fazer a gestão dos produtos que você vende',
    },
    userNavigationTarget: {
      title: 'Acessar minhas compras',
      subtitle: 'Para acessar um produto que comprou',
    },
    checkboxDontAskAgain: 'Não me perguntar novamente',
  },
  editCollaborator: {
    title: 'Editar colaborador',
    description: 'Edite as informações do colaborador e defina suas permissões dentro da plataforma.',
  },
  profile: {
    title: 'Perfil',
    subtitle:
      'Seu perfil é uma parte importante do seu negócio. Coloque um nome, descrição e uma foto para passar mais credibilidade.',
    picture: 'Foto',
    firstName: 'Nome',
    lastName: 'Sobrenome',
    username: 'Nome de usuário',
    about: 'Escreva a bio do seu perfil',
    socialMedia: 'Compartilhe suas redes sociais',
    social: {
      instagram: 'https://instagram.com/',
      twitter: 'https://x.com/',
    },
    createLink: 'Crie o link de acesso para sua página',
    linkPrefix: 'https://hub.la/',
  },
  notifications: {
    title: 'Notificações',
    description:
      'Receba notificações sobre atividades dos membros no seu app Hubla, confirmações de pagamentos e mais.',
    receiveAbout: 'Receber notificações sobre',
    informationInNotifications: 'Informações nas notificações',
    sound: 'Som das notificações',
    enableSound: 'Ativar som?',
    options: {
      adminJoined: 'Administrador entrou no grupo',
      memberJoined: 'Membro entrou no seu grupo',
      nonMemberJoined: 'Usuário não identificado entrou no seu grupo',
      purchased: 'Vendas do grupo',
      removed: 'Usuário foi removido do grupo',
      whitelisted: 'Usuário foi adicionado à lista de permissões',
      postOwnerCommentAlert: 'Usuário comentou em um conteúdo que eu produzi',
      threadParticipationReplyAlert: 'Usuário respondeu a uma discussão da qual eu participo ou sou dono',
    },
    data: {
      paymentMethod: 'Método de pagamento',
      productName: 'Nome do produto',
      userName: 'Nome do usuário',
      userEmail: 'E-mail do usuário',
      userPhone: 'Telefone do usuário',
    },
  },
  save: 'Salvar',
  loginPreferences: {
    title: 'Preferências de login',
    userType: {
      creator: 'Produtor',
      buyer: 'Comprador',
    },
  },
  errors: {
    accountUserNotFound: 'O usuário não foi encontrado. É necessario que o usuário tenha uma conta na Hubla.',
  },
}

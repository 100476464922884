export const pt = {
  dropdown: {
    header: {
      showProfile: 'Ver perfil',
      editProfile: 'Editar perfil',
    },
    links: {
      dashboard: 'Dashboard de produtor',
      purchases: 'Acessar minhas compras',
      affiliates: 'Afiliados',
      blog: 'Hubla blog',
      support: 'Suporte & ajuda',
      bankAccount: 'Conta bancária',
      collaborators: 'Gestão de colaboradores',
      selectAccount: 'Acessar outra conta',
      logout: 'Sair da conta',
    },
  },
}

import { UploadStatus } from '@hub-la/fe-asset'
import { Progress } from '@hub-la/shadcn'
import { CircleCheck, Loader2 } from 'lucide-react'

type Props = {
  name: string
  progress: number
  status: UploadStatus
}

export const Item = ({ name, progress, status }: Props) => {
  const getStatusIcon = (status: UploadStatus) => {
    if (status === UploadStatus.SUCCESS) {
      return <CircleCheck className="h-5 w-5 text-primary" />
    }
    return <Loader2 className="h-5 w-5 animate-spin" />
  }

  const progressValue = status === UploadStatus.SUCCESS ? 100 : progress

  return (
    <div className="flex items-center px-6 py-3">
      <div className="flex-1 mr-4">
        <p className="text-sm mb-2 break-all">{name}</p>
        <Progress value={progressValue} indicatorColor="bg-primary" className="h-1 mr-3" />
      </div>
      {getStatusIcon(status)}
    </div>
  )
}

import { Alert, AlertDescription, Button, Input, useIsMobile } from '@hub-la/shadcn'
import { toDate } from '@hub-la/utils'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { useGetPartners } from '../../hooks/use-get-partners'
import { useInvitePartner } from '../../hooks/use-invite-partner'
import { getTermsOfUse, parseToInt, percentMask } from '../../pages/utils'
import { CoProducersList } from './co-producers-list'
import { ExpiresDate } from './expires-date'
import { TermsOfUse } from './terms-of-use'

interface FormProps {
  commission: number
  name?: string | null
  email: string
  expiresDateOption?: 'withDeadline' | 'withoutDeadline'
  expiresDate: Date | null
  termsOfUse?: boolean
}

interface Params {
  groupId: string
  receiverId?: string
}

interface InvitePartnerErrors {
  details: string
}

const MAX_PERCENTAGE = 9999

export const InviteForm: React.FC = () => {
  const isMobile = useIsMobile()
  const { groupId, receiverId } = useParams<Params>()
  const invitePartner = useInvitePartner()
  const partners = useGetPartners(groupId, receiverId)
  const { t } = useTranslation()

  const maxCommission = 9999 - (partners.data?.partnersCommission || 0) * 100
  const invitePartnerErrors = invitePartner.error as InvitePartnerErrors

  const formik = useFormik<FormProps>({
    initialValues: {
      email: '',
      commission: 0,
      termsOfUse: false,
      expiresDate: null,
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email('O e-mail precisa estar em um formato válido')
        .required('E-mail é um campo obrigatório'),
      commission: yup
        .number()
        .min(0.01, 'A comissão deve ser maior que 0.01%')
        .max(maxCommission, `A comissão deve ser menor que ${percentMask(maxCommission)}%`)
        .required('Comissão é um campo obrigatório'),
      termsOfUse: yup.boolean().oneOf([true], 'Para convidar um parceiro é preciso concordar com os termos de uso.'),
      expiresDateOption: yup.string().required('Selecione uma opção'),
      expiresDate: yup.mixed().when('expiresDateOption', {
        is: 'withDeadline',
        then: yup
          .date()
          .min(new Date(), 'A data de expiração deve ser maior que a data atual')
          .required('Selecione uma data')
          .nullable(),
      }),
    }),
    onSubmit: ({ email, commission, expiresDate, expiresDateOption }) => {
      invitePartner.mutate({
        id: receiverId,
        email,
        commission: commission / 100,
        expiresAt: expiresDateOption === 'withDeadline' ? expiresDate?.toISOString() : null,
        groupId,
        termsText: getTermsOfUse(),
      })
    },
    isInitialValid: false,
    enableReinitialize: true,
  })

  useEffect(() => {
    if (formik.status !== 'filled' && partners.data?.partnerEditing) {
      const { commission, expiresAt, user } = partners.data.partnerEditing
      formik.setValues({
        name: user.name,
        email: user.email as string,
        commission: (commission?.sell?.percent || 0) * 100,
        termsOfUse: false,
        expiresDateOption: expiresAt ? 'withDeadline' : 'withoutDeadline',
        expiresDate: expiresAt ? toDate(expiresAt) : null,
      })
      formik.setStatus('filled')
    }
  }, [formik, partners.data?.partnerEditing])

  const handleOnChangeExpiresDate = (date: Date | null) => {
    formik.setFieldTouched('expiresDate')
    formik.setFieldValue('expiresDate', date)
  }

  const handleOnChangeCommission = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value === '') return
    const convertedValue = parseToInt(value)
    if (convertedValue <= MAX_PERCENTAGE) {
      formik.setFieldTouched('commission')
      formik.setFieldValue('commission', convertedValue)
    }
  }

  const expiresDateOptionError =
    formik.touched.expiresDateOption && formik.errors.expiresDateOption ? formik.errors.expiresDateOption : ''
  const expiresDateError = formik.touched.expiresDate && formik.errors.expiresDate ? formik.errors.expiresDate : ''

  return (
    <div className="grid grid-cols-1 sm:grid-cols-9 gap-8">
      <div className="sm:col-span-5 space-y-7">
        <h3 className="text-2xl font-bold">{t(`Receiver.inviteForm.${receiverId ? 'edit' : 'invite'}_title`)}</h3>
        <div className="space-y-5">
          {!receiverId && (
            <div className="space-y-2">
              <label htmlFor="email" className="block text-sm font-medium text-secondary-foreground">
                {t('Receiver.inviteForm.fields.email')}
              </label>
              <Input
                id="email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={formik.touched.email && formik.errors.email ? 'border-red-500' : ''}
              />
              {formik.touched.email && formik.errors.email && (
                <p className="mt-1 text-sm text-red-500">{formik.errors.email}</p>
              )}
            </div>
          )}
          <div className="space-y-2">
            <label htmlFor="commission" className="block text-sm font-medium text-secondary-foreground">
              {t('Receiver.inviteForm.fields.commission')}
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">%</span>
              <Input
                id="commission"
                name="commission"
                type="text"
                value={formik.values.commission ? percentMask(formik.values.commission) : ''}
                onChange={handleOnChangeCommission}
                onBlur={formik.handleBlur}
                className={`pl-7 ${formik.touched.commission && formik.errors.commission ? 'border-red-500' : ''}`}
              />
            </div>
            {formik.touched.commission && formik.errors.commission && (
              <p className="mt-1 text-sm text-red-500">{formik.errors.commission}</p>
            )}
          </div>
          <div className="space-y-2">
            <h4 className="text-lg font-medium">{t('Receiver.inviteForm.fields.end_date_partnership')}</h4>
            <p className="text-sm text-muted-foreground">{t('Receiver.inviteForm.instruction')}</p>
          </div>
          <ExpiresDate
            onChangeExpiresDate={handleOnChangeExpiresDate}
            onChangeOption={(value) => formik.setFieldValue('expiresDateOption', value)}
            selectedDate={formik.values.expiresDate}
            selectedExpiresDateOption={formik.values.expiresDateOption}
            error={!!expiresDateOptionError || !!expiresDateError}
            helperText={expiresDateOptionError || expiresDateError}
          />
          <TermsOfUse
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.termsOfUse && !!formik.errors?.termsOfUse}
            helperText={formik.touched.termsOfUse ? formik.errors?.termsOfUse : ''}
          />
        </div>
        {['success', 'error'].includes(invitePartner.status) && (
          <Alert variant={invitePartner.isSuccess ? 'default' : 'destructive'}>
            <AlertDescription>
              {invitePartner.isSuccess
                ? 'Tudo certo :)'
                : t(`Receiver.errors.${invitePartnerErrors.details ? invitePartnerErrors.details : 'general'}`)}
            </AlertDescription>
          </Alert>
        )}
        <div className={`flex ${!isMobile ? 'flex-row' : 'flex-col-reverse'} items-center gap-4`}>
          <Button
            variant="default"
            size="lg"
            className={`${isMobile ? 'w-full' : ''}`}
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid || partners.isFetching}
            loading={invitePartner.isLoading}
          >
            {t(`Receiver.inviteForm.${receiverId ? 'edit' : 'invite'}_submit_button`)}
          </Button>
          {receiverId && <p className="text-sm text-muted-foreground">{t('Receiver.inviteForm.edit_instruction')}</p>}
        </div>
      </div>
      <div className="sm:col-span-4">
        {(formik.touched.commission || !isMobile) && (
          <CoProducersList
            newPartner={{
              name: partners.data?.partnerName || t('Receiver.inviteForm.defaultName'),
              commission: formik.values.commission,
            }}
            partnersCommission={partners.data?.partnersCommission || 0}
          />
        )}
      </div>
    </div>
  )
}

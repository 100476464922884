import { Card, cn } from '@hub-la/shadcn'
import { ChevronRight } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

type Props = {
  title: string
  description: string
  provider: string
  imageUrl: string
  disabled?: boolean
  loading?: boolean
}

export const IntegrationCard = ({ title, description, provider, imageUrl, disabled, loading }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleCardClick = () => {
    if (!disabled && !loading) {
      history.push({
        pathname: '/dashboard/integrations',
        search: `provider=${provider}`,
      })
    }
  }

  return (
    <Card
      className={cn(
        'group p-3 w-full transition-all duration-300 ease-in-out',
        'flex flex-col sm:flex-row items-start sm:items-center justify-between gap-6',
        'border',
        disabled ? 'bg-muted' : 'bg-card border-none cursor-pointer hover:bg-secondary hover:border',
        loading && 'animate-pulse',
      )}
      onClick={handleCardClick}
    >
      <div className="flex items-center gap-4 w-full sm:w-auto">
        <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-md overflow-hidden">
          <img src={imageUrl} alt={title} className="w-12 h-12 object-contain" />
        </div>
        <div className="flex-grow">
          <h3 className="text-lg font-semibold">{title}</h3>
          <p className="text-sm text-muted-foreground mt-1 line-clamp-2">{description}</p>
        </div>
      </div>
      <div className="flex items-center mt-4 sm:mt-0">
        <span className="text-sm font-medium text-muted-foreground mr-2">
          {disabled ? t('buttons.wait') : t('buttons.settings')}
        </span>
        {!disabled && !loading && <ChevronRight className="w-5 h-5 text-muted-foreground" />}
      </div>
    </Card>
  )
}

import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue, ToggleGroup, ToggleGroupItem } from "@hub-la/shadcn"
import { useTranslation } from "react-i18next"
import { PurchaseExperienceEnum } from "../../../../../../domain/enums/purchase-experience.enum"
import { PriceFormat } from "@hub-la/sdk-bff-product"

interface PurchaseExperienceSelectorProps {
  onChange: (theme: PurchaseExperienceEnum) => void
  value: PurchaseExperienceEnum
}

export const PurchaseExperienceSelector = (props: PurchaseExperienceSelectorProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.preferences.purchaseExperience.label')}</h3>
      <Select onValueChange={props.onChange} value={props.value}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder={t('offer.checkoutTab.preferences.purchaseExperience.label')} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value={PurchaseExperienceEnum.ONE_STEP}>{t(`offer.checkoutTab.preferences.purchaseExperience.oneStep`)}</SelectItem>
            <SelectItem value={PurchaseExperienceEnum.TWO_STEP}>{t(`offer.checkoutTab.preferences.purchaseExperience.twoStep`)}</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>

  )
}

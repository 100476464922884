import { useGetUser } from '@hub-la/fe-get-user'
import { Button } from '@hub-la/shadcn'
import { Banknote, RefreshCcw } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

type CardProps = {
  href: string
  icon: React.ReactNode
  text: string
}

const ActionButton: React.FC<CardProps> = ({ href, icon, text }) => {
  const history = useHistory()

  return (
    <Button variant="secondary" className="w-full sm:w-auto" onClick={() => history.push(href)}>
      <div className="flex items-center gap-2">
        {icon}
        <span className="text-sm">{text}</span>
      </div>
    </Button>
  )
}

export const Header: React.FC = () => {
  const { t } = useTranslation()
  const { data: currentUser } = useGetUser()

  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center w-full space-y-4 sm:space-y-0">
      <h1 className="text-xl sm:text-2xl lg:text-3xl font-semibold">
        {currentUser?.name ? t('hello', { name: currentUser.name.split(' ').at(0) }) : t('title')}
      </h1>
      <div className="flex flex-col md:flex-row space-y-4 md:space-x-4 md:space-y-0 w-full sm:w-auto">
        <ActionButton href="/user_invoices" icon={<Banknote className="h-5 w-5" />} text={t('userTransactions')} />
        <ActionButton
          href="/user_subscriptions"
          icon={<RefreshCcw className="h-4 w-4" />}
          text={t('userSubscriptions')}
        />
      </div>
    </div>
  )
}

import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { Events, makePostEventData, SectionResponse } from '@hub-la/fe-post'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@hub-la/shadcn'
import { ChevronRight, MoveVertical } from 'lucide-react'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetMoveSectionOptions } from '../../../usecases/get-move-section-options'
import { useMovePostSection } from '../../hooks/use-move-post-section'

type Props = {
  postId: string
  userId: string
  productId: string
  onClose: () => void
  sectionId: string
  sections: SectionResponse[]
}

export const MoveSectionMenu: React.FC<Props> = ({ sections, onClose, postId, productId, userId, sectionId }) => {
  const [open, setOpen] = useState(false)

  const { t } = useTranslation()
  const movePost = useMovePostSection()
  const container = useContainer()

  const analytics = container.get(Analytics)

  const handleClose = () => {
    onClose()
    setOpen(false)
  }

  const onMove = (sectionId: string) => {
    handleClose()
    movePost.mutateAsync({ sectionId, postId }).then(() => {
      analytics.track(
        Events.HUB.SECTION.MOVE_SUBMITTED,
        makePostEventData({
          userId,
          productId,
        }),
      )
    })
  }

  const options = new GetMoveSectionOptions().execute({ sectionId, sections })

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <div className="flex items-center w-full cursor-pointer">
          <MoveVertical className="text-primary mr-2 h-4 w-4" />

          <span className="flex-1 text-primary">{t('dashboard.menu.moveTo')}</span>

          <ChevronRight className="text-primary h-4 w-4" />
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="max-h-44 w-56 overflow-y-auto">
        {options.map((option) => (
          <DropdownMenuItem key={option.id} onSelect={() => onMove(option.id)}>
            {option.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

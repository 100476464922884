import { cn } from '@hub-la/shadcn'
import { MuxPlayerRefAttributes } from '@mux/mux-player-react/.'
import { forwardRef } from 'react'
import MuxPlayer from './mux-player'

const MuxPlayerRewrite = forwardRef<MuxPlayerRefAttributes, any>((props, ref) => (
  <MuxPlayer
    ref={ref}
    {...props}
    className={cn(
      'overflow-hidden w-full h-full max-h-[calc(100vh-4rem)] rounded-none lg:rounded-lg shadow-lg bg-background',
      '[--seek-backward-button:none]',
      '[--seek-forward-button:none]',
      '[--media-accent-color:#DC2626!important]',
      '[&_media-preview-time-display]:text-primary-foreground [&_media-preview-time-display]:font-semibold',
      '[&::part(poster-layer)]:hidden',
      '[&::part(control-bar_bottom)]:flex [&::part(control-bar_bottom)]:bg-gradient-to-t [&::part(control-bar_bottom)]:from-black/60 [&::part(control-bar_bottom)]:to-transparent',
      '[&::part(bottom_time_range)_::slotted(media-preview-thumbnail)]:bg-[#DC2626]',
      '[&::part(bottom_time_range)]:inline-block [--media-range-bar-color:#DC2626] [--media-time-range-buffered-color:#ffffff4d] [--media-range-track-background:#ffffff33] [--media-range-track-height:2px] [--media-preview-border-radius:8px] [--media-preview-thumbnail-border-radius:8px] [--media-preview-thumbnail-border:2px_solid_#DC2626] [--media-preview-chapter-background:rgba(0,0,0,0.7)] [--media-preview-time-background:rgba(0,0,0,0.7)] [--media-box-arrow-display:none] [--media-text-color:white]',
      '[&::part(option_option-selected)]:text-[#DC2626]!important [&::part(option_option-selected)]:font-semibold',
      "[&_media-playback-rate-listbox[part~='listbox']::part(option-selected)]:text-[#DC2626]!important [&_media-playback-rate-listbox[part~='listbox']::part(option-selected)]:font-semibold",
      '[&::part(bottom_playback-rate_selectmenu)]:flex',
      '[&_button]:transition-all [&_button]:duration-200 [&_button]:ease-in-out [&_button:hover]:opacity-80',
      '[&_media-control-bar]:pb-2',
      '[&_svg]:fill-gray-200',
      // Fix for popover background colors
      '[&_media-menu-button::part(listbox)]:bg-background',
      '[&::part(rendition)]:text-primary-foreground',
      '[&_media-menu-button::part(listbox)]:text-primary-foreground',
      '[&_media-menu-button::part(option):hover]:bg-gray-700',
      props.className,
    )}
  />
))

MuxPlayerRewrite.displayName = 'MuxPlayerRewrite'

export { MuxPlayerRewrite }

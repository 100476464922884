import { Button, Input, Label } from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Trans, useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import { Link as RouterLink } from 'react-router-dom'
import { EmailAlreadyExistsError } from '../../../domain/errors/email-already-exists-error'
import { EmailAndPhoneAlreadyInUseError } from '../../../domain/errors/email-and-phone-already-in-use-error'
import { PhoneNumberAlreadyExistsError } from '../../../domain/errors/phone-number-exists-error'
import { AuthCard } from '../../components/auth-card/auth-card'
import { AuthLayout } from '../../components/auth-layout'
import { LetsStartCard } from '../../components/lets-start-card'
import { MetaTags } from '../../components/meta-tags'
import { PasswordStatus } from '../../components/password-status'
import { PhoneNumberStyled } from '../../components/phone-number-field/phone-number-styled'
import { usePostSignUpByEmail } from '../../hooks/use-post-sign-up-by-email'
import { signUpSchema } from '../../validations/signup'

const SignUpCard = () => {
  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { error, mutate, isLoading, isSuccess, isError, reset } = usePostSignUpByEmail({
    onError: (error) => {
      const isPhoneNumberAlreadyExists = [PhoneNumberAlreadyExistsError, EmailAndPhoneAlreadyInUseError].some(
        (errorPhone) => error instanceof errorPhone,
      )
      if (isPhoneNumberAlreadyExists) {
        form.setFieldError('phoneNumber', new PhoneNumberAlreadyExistsError().message)
      }

      const isEmailAlreadyExists = [EmailAlreadyExistsError, EmailAndPhoneAlreadyInUseError].some(
        (errorEmail) => error instanceof errorEmail,
      )

      if (isEmailAlreadyExists) {
        form.setFieldError('email', new EmailAlreadyExistsError().message)
      }
    },
  })

  const form = useFormik({
    initialValues: {
      email: '',
      phoneNumber: '',
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: async (values) => {
      const captcha = await executeRecaptcha?.('signup')

      mutate({ ...values, captcha })
    },
    validationSchema: signUpSchema,
  })

  const hideErrorsOnSnackbar = [
    PhoneNumberAlreadyExistsError,
    EmailAlreadyExistsError,
    EmailAndPhoneAlreadyInUseError,
  ].some((errorType) => error instanceof errorType)

  if (isSuccess) return <LetsStartCard />

  return (
    <AuthCard title="Crie sua conta" description="Informe seus dados para continuar">
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="email">E-mail</Label>
            <Input
              id="email"
              name="email"
              type="email"
              autoComplete="off"
              onChange={form.handleChange}
              value={form.values.email}
            />
            {form.touched.email && form.errors.email && (
              <p className="text-sm text-red-500">
                <Trans i18nKey={form.errors.email} components={[<a href="/signin" />]} />
              </p>
            )}
          </div>
          <div className="space-y-2">
            <PhoneNumberStyled
              label="Telefone com DDD"
              onChange={(value) => form.setFieldValue('phoneNumber', (value ?? '').toString())}
              value={form.values.phoneNumber}
            />
            {form.touched.phoneNumber && form.errors.phoneNumber && (
              <p className="text-sm text-red-500">
                <Trans i18nKey={form.errors.phoneNumber} components={[<a href="/signin" />]} />
              </p>
            )}
          </div>
          <div className="space-y-2">
            <Label htmlFor="password">Senha</Label>
            <Input
              id="password"
              name="password"
              type="password"
              autoComplete="new-password"
              onChange={form.handleChange}
              value={form.values.password}
            />
            {form.touched.password && form.errors.password && (
              <p className="text-sm text-red-500">{t(form.errors.password)}</p>
            )}
          </div>
          <div className="space-y-2">
            <Label htmlFor="passwordConfirmation">Confirmar senha</Label>
            <Input
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              autoComplete="new-password"
              onChange={form.handleChange}
              value={form.values.passwordConfirmation}
            />
            {form.touched.passwordConfirmation && form.errors.passwordConfirmation && (
              <p className="text-sm text-red-500">{t(form.errors.passwordConfirmation)}</p>
            )}
          </div>
          <PasswordStatus passwordValue={form.values.password} />
          <Button type="submit" className="w-full" disabled={isLoading}>
            Criar conta
          </Button>
        </form>
      </FormikProvider>

      <div className="relative my-4">
        <hr />
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-background px-2 text-xs text-muted-foreground">
          {t('Ou')}
        </span>
      </div>
      <p className="text-center text-sm text-muted-foreground">
        Já possui uma conta na Hubla?{' '}
        <RouterLink className="text-lime-600" to="/signin">
          Faça seu login
        </RouterLink>
      </p>

      {/* <Snackbar open={isError && !hideErrorsOnSnackbar} onClose={() => reset()} variant="negative">
        {t((error as Error)?.message)}
      </Snackbar> */}
    </AuthCard>
  )
}

const SignUpPage = () => {
  return (
    <AuthLayout>
      <MetaTags />
      <SignUpCard />
    </AuthLayout>
  )
}

export { SignUpPage }

import {
  Button,
  Card,
  CardContent,
  CopyClipboardButton,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  useIsMobile,
} from '@hub-la/shadcn'
import { toDate } from '@hub-la/utils'
import { Loader2 } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Phone } from '../../domain/vos/phone'
import defaultGroupAvatar from '../assets/default-group-avatar.png'
import { useGenerateWhitelistLink } from '../hooks/use-generate-whitelist-link'
import { useGetGroup } from '../hooks/use-get-group'
import { useGetWhitelist } from '../hooks/use-get-whitelist'
import { useRemoveWhitelistUser } from '../hooks/use-remove-whitelist-user'

export const WhitelistPage = () => {
  const { t } = useTranslation()
  const { groupId } = useParams<{ groupId: string }>()
  const isMobile = useIsMobile()
  const [searchTerm, setSearchTerm] = useState('')
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [userToRemove, setUserToRemove] = useState<string | null>(null)
  const [generatedLinks, setGeneratedLinks] = useState<string[]>([])
  const [linkDialog, setLinkDialog] = useState(false)

  const { data: group } = useGetGroup(groupId)
  const { data: whitelistData, isLoading, error } = useGetWhitelist(groupId)
  const { mutateAsync: removeUser } = useRemoveWhitelistUser()
  const { mutateAsync: generateLink } = useGenerateWhitelistLink()

  const handleRemoveClick = (userId: string) => {
    setUserToRemove(userId)
    setConfirmDialog(true)
  }

  const handleConfirmRemove = async () => {
    if (userToRemove) {
      await removeUser({ groupId, id: userToRemove }).then(() => {
        setConfirmDialog(false)
        setUserToRemove(null)
      })
    }
  }

  const handleGenerateLink = async () => {
    setLinkDialog(true)
    if (group?.groupResourcesType) {
      const links = await Promise.all(
        group.groupResourcesType.map(async (resourceType) => {
          const result = await generateLink({ groupId, resourceType })
          return result
        }),
      )
      setGeneratedLinks(links)
    }
  }

  if (isLoading) return <Loader2 className="mx-auto mt-8 h-10 w-10 animate-spin" />
  if (error) return <div>Error: {t((error as Error).message)}</div>

  const filteredWhitelist =
    (whitelistData ?? []).filter(
      (user) =>
        user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.ims?.telegram?.includes(searchTerm) ||
        user.ims?.whatsapp?.includes(searchTerm),
    ) || []

  return (
    <div className="max-w-screen-2xl m-auto p-8 space-y-4">
      {group && (
        <>
          <img
            src={group.picture ?? defaultGroupAvatar}
            alt={group.name}
            className="max-w-[157px] max-h-[90px] rounded-lg mx-auto mb-4 object-cover"
          />
          <h2 className="text-2xl font-bold text-center">{group.name}</h2>
        </>
      )}
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Whitelist</h2>
        <Button variant="outline" onClick={handleGenerateLink}>
          {t('whitelist.generateInviteLink')}
        </Button>
      </div>
      <Input
        placeholder={t('whitelist.searchPlaceholder')}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Card>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('whitelist.table.Name')}</TableHead>
                <TableHead>{t('whitelist.table.PhoneNumber')}</TableHead>
                {!isMobile && <TableHead>{t('whitelist.table.CreatedAt')}</TableHead>}
                <TableHead>{t('whitelist.table.Action')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredWhitelist.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.name || t('whitelist.errors.nameNotRegistered')}</TableCell>
                  <TableCell>
                    {new Phone(user.ims?.whatsapp || user.ims?.telegram || '').getFormattedNumber()}
                  </TableCell>
                  {!isMobile && (
                    <TableCell>{moment(toDate(user?.createdAt) ?? '').format('D MMM. YYYY HH:mm')}</TableCell>
                  )}
                  <TableCell>
                    <Button variant="destructive" onClick={() => handleRemoveClick(user.id!)}>
                      {t('whitelist.remove')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <Dialog open={confirmDialog} onOpenChange={setConfirmDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('whitelist.modal.remove.title')}</DialogTitle>
            <DialogDescription>{t('whitelist.modal.remove.description')}</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="secondary" onClick={() => setConfirmDialog(false)}>
              {t('whitelist.modal.remove.buttons.cancel')}
            </Button>
            <Button variant="destructive" onClick={handleConfirmRemove}>
              {t('whitelist.modal.remove.buttons.remove')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={linkDialog} onOpenChange={setLinkDialog}>
        <DialogContent className="flex flex-col gap-4">
          <DialogHeader>
            <DialogTitle>{t('whitelist.generateLink.title')}</DialogTitle>
            <DialogDescription>{t('whitelist.generateLink.description')}</DialogDescription>
          </DialogHeader>
          {generatedLinks.map((link) => (
            <CopyClipboardButton
              className="mx-auto w-min max-w-full"
              labelClassName="max-w-80"
              label={link}
              copyContent={link}
              tooltipCopiedLabel={t('copyClipboard.inviteLinkCopied')}
              tooltipCopyLabel={t('copyClipboard.copyInviteLink')}
            />
          ))}
        </DialogContent>
      </Dialog>
    </div>
  )
}

import { Card, CardContent } from '@hub-la/shadcn'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CurrencyField } from '../../../../../components/currency-field'
import { InstallmentsSelect } from '../../../../../components/installments-select'
import { PromotionField } from '../../../../../components/promotion-field'

const OneTimeCard = () => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardContent>
        <Controller
          render={({ field: { name, onChange, value, ...props }, fieldState }) => (
            <CurrencyField
              label={t('offer.pricingTab.pricing.price.label')}
              error={Boolean(fieldState.error?.type)}
              helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
              onChange={onChange}
              value={value}
              name={name}
              {...props}
            />
          )}
          name="pricing.price"
        />
        <Controller
          render={({ field: { name, onChange, value, ...props }, fieldState }) => (
            <PromotionField
              label={t('offer.pricingTab.pricing.promotion.label')}
              error={Boolean(fieldState.error?.type)}
              helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
              onChange={onChange}
              value={value}
              name={name}
              {...props}
            />
          )}
          name="pricing.oldPrice"
        />
        <Controller
          render={({ field: { onChange, value, name }, fieldState }) => (
            <InstallmentsSelect
              label={t('offer.pricingTab.pricing.installments.label')}
              maxInstallment={12}
              error={Boolean(fieldState.error?.type)}
              helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
              onChange={onChange}
              value={value}
              name={name}
            />
          )}
          name="pricing.installments"
        />
      </CardContent>
    </Card>
  )
}

const PriceConfiguration = () => {
  return (
    <div className="flex flex-col gap-6">
      <OneTimeCard />
    </div>
  )
}

export { PriceConfiguration }

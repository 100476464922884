import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CreateEventProduct } from '../components/create-event-product'
import { Events } from './events/future-events'

type DashboardEventTabTypes = 'futures' | 'past'

export const DashboardEvents = memo(() => {
  const [tab, setTab] = useState<DashboardEventTabTypes>('futures')

  const history = useHistory()
  const { t } = useTranslation()

  /** @todo The #products is temporarily due to the lack of navigation on chatpay-firebase */
  /** @description This have to be set to properly go back when seeing a subscription detail */
  const onTabChange = (newTab: string) => {
    history.push(`${history.location.pathname}?tab=${newTab}#events`)
    setTab(newTab as DashboardEventTabTypes)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tabParam = urlParams.get('tab')
    if (tabParam) {
      setTab(tabParam as DashboardEventTabTypes)
    }
  }, [])

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl leading-tight font-semibold">{t('eventsTitle')}</h1>

        <CreateEventProduct />
      </div>

      <Tabs value={tab} onValueChange={onTabChange}>
        <div className="w-full overflow-x-auto">
          <TabsList variant="underlined">
            <TabsTrigger value="futures" variant="underlined">
              {t('eventTabs.futures')}
            </TabsTrigger>

            <TabsTrigger value="past" variant="underlined">
              {t('eventTabs.past')}
            </TabsTrigger>
          </TabsList>
        </div>

        <TabsContent value="futures">
          <Events timeScope="future" />
        </TabsContent>

        <TabsContent value="past">
          <Events timeScope="past" />
        </TabsContent>
      </Tabs>
    </div>
  )
})

import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { SmartInstallment } from '../domain/dtos/smart-installment'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetSmartInstallment {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(id: string): Promise<SmartInstallment> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/smart-installments/${id}`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new GeneralError()
  }
}

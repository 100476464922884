import { ContainerProvider } from '@hub-la/fe-container'
import { I18nextProvider } from 'react-i18next'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { QueryParamProvider } from 'use-query-params'
import { QueryParamAdapter } from './components/query-param-adapter'
import i18n from './i18n'
import { getQueryOptions } from './utils/query-string-options'
import { Toaster } from '@hub-la/shadcn'

const queryClient = new QueryClient()

export const DashboardSubscriptionsProvider = ({ children, container }) => {
  return (
    <QueryParamProvider adapter={QueryParamAdapter} options={getQueryOptions()}>
      <ContainerProvider container={container}>
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </I18nextProvider>
        <Toaster />
      </ContainerProvider>
    </QueryParamProvider>
  )
}

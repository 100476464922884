import { Alert, AlertDescription, AlertTitle, Button, Input, Label } from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import { useContext, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Phone } from '../../domain/vos/phone'
import { AuthRedirectContext } from '../../providers/auth-redirect-provider'
import { useAuthenticateWithCode } from '../hooks/use-authenticate-with-code'
import { verifyCodePhoneSchema } from '../validations/verify-code-phone'
import { AuthCard } from './auth-card/auth-card'
import { CompleteRegistrationCard } from './complete-registration/complete-registration-card'
import { CountdownButton } from './countdown-button'

type PhoneVerifyCodeCardProps = {
  authId?: string
  phoneNumber: string
  nextRetryDate?: Date | string
  retryCode: () => void
  loadingRetry?: boolean
  isWhatsapp?: boolean
}

const PhoneVerifyCodeCard = ({
  authId,
  phoneNumber,
  nextRetryDate,
  retryCode,
  loadingRetry,
  isWhatsapp,
}: PhoneVerifyCodeCardProps) => {
  const [showCompleteRegistration, setShowCompleteRegistration] = useState<boolean>(false)
  const { t } = useTranslation()

  const { executeRecaptcha } = useGoogleReCaptcha()
  const { redirectAutoIfAuthenticated } = useContext(AuthRedirectContext)
  const { isError, error, mutate, isLoading, reset, isSuccess } = useAuthenticateWithCode({
    onSuccess: (data) => {
      // if is not using password, user need complete registration on next step
      if (!data?.isUsingPassword) {
        setShowCompleteRegistration(true)
        return
      }
      redirectAutoIfAuthenticated()
    },
  })

  const form = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: async (values) => {
      if (!authId) return
      const captcha = await executeRecaptcha?.('phone_verify_code')

      mutate({ code: parseInt(values.code), authId, isWhatsapp, captcha })
    },
    validationSchema: verifyCodePhoneSchema,
    validateOnChange: true,
    validateOnMount: true,
  })

  if (showCompleteRegistration) return <CompleteRegistrationCard />

  return (
    <AuthCard
      title="Verifique seu telefone"
      description={
        <>
          Enviamos um código de verificação para
          <br />
          <strong>{Phone.build(phoneNumber).getFormattedNumber()}</strong>
          <br />
          Inseriu o número de telefone errado? <a href="/signin">Altere aqui</a>
        </>
      }
    >
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit} className="space-y-4 w-full">
          <div className="space-y-2">
            <Label htmlFor="code">Código de verificação</Label>
            <Input
              type="text"
              id="code"
              name="code"
              autoComplete="one-time-code"
              onChange={form.handleChange}
              value={form.values.code}
              className={form.touched.code && form.errors.code ? 'border-red-500' : ''}
            />
            {form.touched.code && form.errors.code && (
              <p className="mt-1 text-xs text-red-500">{t(form.errors.code)}</p>
            )}
          </div>
          <Button type="submit" className="w-full" disabled={!form.isValid || isSuccess || isLoading}>
            {isLoading ? 'Carregando...' : 'Confirmar código'}
          </Button>
          {nextRetryDate && (
            <CountdownButton endDate={nextRetryDate} onClick={() => retryCode()} isLoading={loadingRetry}>
              Reenviar código de verificação
            </CountdownButton>
          )}
        </form>
      </FormikProvider>
      <div className="relative my-4">
        <hr />
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-background px-2 text-xs text-muted-foreground">
          {t('Ou')}
        </span>
      </div>
      <p className="text-center text-sm text-muted-foreground">
        {t('Não possui uma conta na Hubla?')}{' '}
        <RouterLink className="text-lime-600" to="/signup">
          Crie sua conta
        </RouterLink>
      </p>
      {isError && (
        <Alert variant="destructive" className="mt-4">
          <AlertTitle>{t('error')}</AlertTitle>
          <AlertDescription>{t((error as Error)?.message)}</AlertDescription>
        </Alert>
      )}
    </AuthCard>
  )
}

export { PhoneVerifyCodeCard }

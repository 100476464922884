import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import moment from 'moment'
import { DownloadInvoicesXlsxInput } from '../domain/dtos/download-invoices-xlsx-input'
import { ExportInvoicesOutput } from '../domain/dtos/export-invoices-output'
import { ExportAlreadyInProgressError } from '../domain/errors/export-already-in-progress'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { GetOfferIds } from './get-offer-ids'

export class DownloadInvoicesXlsx {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: DownloadInvoicesXlsxInput): Promise<ExportInvoicesOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/invoices/background-export`,
      body: this.makeBody(input),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    if (response.statusCode === HttpStatusCode.CONFLICT) {
      throw new ExportAlreadyInProgressError()
    }

    throw new GeneralError()
  }

  private makeBody(input: DownloadInvoicesXlsxInput) {
    const offerIds = new GetOfferIds().execute({ offerIds: input.offerIds, offers: input.offers })

    return {
      offerIds,
      timezone: moment().format('Z'),
      filters: {
        startDate: input.startDate,
        endDate: input.endDate,
        status: input.statuses,
        type: input.types,
        paymentMethod: input.methods,
      },
      receiverEmail: input.receiverEmail || undefined,
    }
  }
}

export const pt = {
  errors: {
    general: 'Erro inesperado. Tente novamente.',
  },
  copyClipboard: {
    successOneClick: 'Link do checkout 1-clique copiado com sucesso!',
    error: 'Erro ao copiar. Tente novamente.',
    copyInviteLink: 'Copiar link do convite',
    inviteLinkCopied: 'Link do convite copiado',
    copyOfferLink: 'Copiar link da oferta',
    offerLinkCopied: 'Link da oferta copiado',
    copyUtm: 'Copiar UTMs',
    utmCopied: 'UTMs copiados',
    copyShortLink: 'Copiar link',
    shortLinkCopied: 'Link copiado',
  },
  actions: {
    label: 'Ações',
    show: 'Mostrar na página do produtor',
    hide: 'Ocultar da página do produtor',
    delete: 'Deletar produto',
  },
  menu: {
    'member-area': 'Área de membros',
    offers: 'Ofertas',
    cohorts: 'Turmas',
    members: 'Membros',
    waitlist: 'Lista de espera',
    settings: 'Configurações',
    shortlinks: 'Links',
    tickets: 'Ingressos',
    participants: 'Participantes',
    toggleMenu: 'Alternar menu de navegação',
  },
  header: {
    title: 'Membros',
    description: 'Gerencie seus membros de uma maneira fácil e centralizada.',
    backToDashboard: 'Retornar ao painel',
  },
  deleteModal: {
    title: 'Deletar produto',
    subtitle:
      'Ao deletar o seu produto, ele ficará com as vendas desativadas e não será mais exibido para você. Além disso, a página de vendas dele será desativada. Essa ação não pode ser revertida, mas, sempre que você quiser, basta criar um novo produto. Caso você tenha deletado seu produto por engano, entre em contato com o nosso suporte.',
    warning: 'Você não conseguirá reverter esta ação.',
    cancel: 'Cancelar',
    submit: 'Deletar',
  },
  visibilityModal: {
    title: 'Ocultar produto da página do produtor',
    subtitle:
      'Ao esconder o produto da sua página do produtor, ele não será mais exibido para quem acessar sua página. Mas fique tranquilo, os links individuais do seu produto continuam funcionando. E você poderá, sempre que quiser, voltar a exibir o produto na sua página.',
    cancel: 'Cancelar',
    submit: 'Ocultar produto',
  },
  eventLocation: {
    isLocationNotDefined: 'Local ainda não definido',
    title: 'Local',
    requiredField: 'Campo obrigatório',
    invalidZipCode: 'CEP inválido',
    country: 'País',
    state: 'Estado',
    city: 'Cidade',
    neighborhood: 'Bairro',
    zipCode: 'CEP',
    street: 'Rua',
    number: 'Número',
    complement: 'Complemento',
  },
  changeStatusModal: {
    isSelling: {
      title: 'Seu produto está pronto',
      subtitle: 'Você já pode começar a vender.',
    },
    isWaitlisted: {
      title: 'A lista de espera foi ativada com sucesso!',
      subtitle: 'Seu produto está pronto para receber novos leads.',
    },
    isNotSelling: {
      title: 'As vendas foram desativadas',
      subtitle: 'Você pode retomar as vendas do seu produto a qualquer momento.',
    },
    close: 'Fechar',
  },
  'member-area': {
    tabs: {
      content: 'Conteúdos',
      groups: 'Grupos',
      settings: 'Personalização',
    },
    content: {
      title: 'Escolha sua área de membros',
      subtitle: 'Defina a área de membros do seu produto entre as duas opções abaixo:',
      internal: 'Área de membros Hubla',
      external: 'Área de membros externa',
      externalForm: {
        label: 'Link de acesso a área de membros externa',
        helperText: 'Adicione o link de direcionamento para acesso do cliente a sua área de membros externa.',
        options: 'Opções',
        switch: 'Adicionar mensagem com instruções de acesso para os clientes',
        instructions: 'Instruções de acesso',
      },
    },
    groups: {
      createGroup: 'Adicionar grupo',
      createGroupModal: {
        title: 'Crie um novo grupo',
        confirm: 'Criar o grupo',
        disclaimer: 'Custo adicional de R$ 0,99 por venda aprovada',
        description: {
          whatsapp: {
            title: 'Grupo',
            label: 'WhatsApp',
            advantage_1: 'Suporta até 1.020 alunos',
            advantage_2: 'Opcão mais acessível para o público brasileiro',
            advantage_3: 'Maior taxa de resposta e de fácil acesso entre os alunos',
          },
          telegram: {
            title: 'Grupo',
            label: 'Telegram',
            advantage_1: 'Suporta até 200 mil alunos',
            advantage_2: 'Novos alunos conseguem ter acesso às mensagens antigas',
            advantage_3: 'O admin pode ativar e desativar mensagens entre alunos',
          },
          telegram_channel: {
            title: 'Canal de Transmissão',
            label: 'Telegram',
            advantage_1: 'Suporta até 200 mil alunos',
            advantage_2: 'Apenas admins podem mandar mensagens',
            advantage_3: 'Alunos ficam invisíveis uns dos outros',
          },
        },
      },
      addGroupModal: {
        title: 'Adicione um grupo',
        subTitle: 'Crie um novo grupo ou vincule um grupo existente de outro produto.',
        cards: {
          newGroup: {
            title: 'Criar novo grupo',
            description: 'Crie um novo grupo ou canal de transmissão, liberando o acesso para seus alunos.',
          },
          bindExistentGroup: {
            title: 'Vincular grupo existente',
            description: 'Vincule um grupo existente de um outro produto, liberando o acesso para seus alunos.',
            select: 'Selecione um grupo existente',
            modal: {
              cancel: 'Cancelar',
              confirm: 'Vincular grupo',
            },
          },
        },
      },
      unbindGroupModal: {
        title: 'Deseja desvincular esse grupo?',
        actionButtons: {
          cancel: 'Cancelar',
          delete: 'Desvincular grupo',
        },
      },
      deleteGroupModal: {
        title: 'Deseja deletar esse grupo?',
        subTitle:
          'Lembre-se que essa ação é irreversível, e também terá efeito em produtos que possuem esse grupo vinculado.',
        actionButtons: {
          cancel: 'Cancelar',
          delete: 'Deletar grupo',
        },
      },
      resourceType: {
        whatsapp: 'Grupo de WhatsApp',
        telegram: 'Grupo de Telegram',
        telegram_channel: 'Canal de Telegram',
      },
      deleteResource: 'Deletar',
      unbindResource: 'Desvincular',
      editResource: 'Editar',
      editResourceDrawer: {
        title: 'Editar grupo',
        confirm: 'Salvar alterações',
        cancel: 'Cancelar',
        cohorts: 'Acessos',
        shouldHaveCohort: 'Não é possível desabilitar todas as turmas do seu grupo',
        toolTip: 'Determine quais turmas darão acesso ao grupo.',
      },
      enter: 'Entrar no grupo',
      copyLink: 'Copiar link de acesso',
      addFreeMembers: 'Adicionar membros gratuitos',
      linkCopied: 'Link copiado com sucesso!',
      actionsDisclaimer: 'Você precisa ativar o produto para entrar no grupo.',
      addDisclaimer: 'Você atingiu o limite de grupos para esse produto.',
      empty: 'Nenhum grupo',
      emptySubtitle: 'Ainda não há grupos adicionados. Adicione grupos para começar a se conectar com seus membros.',
    },
    settings: {
      durationSubtitle: {
        article: 'Publicação',
        video: '',
        image: 'Imagem',
        pdf: 'páginas',
      },
      postSelectModal: {
        title: 'Selecionar conteúdo',
        description: 'Apenas conteúdos do tipo vídeo é listado abaixo.',
        none: 'O módulo não possui conteúdos do tipo vídeo.',
      },
      customHighlight: {
        title: 'Personalizar banner',
        description: 'Personalize o banner que será exibido na sua área de membros.',
        save: 'Salvar',
        post: {
          button: {
            without: 'Selecionar conteúdo',
            has: 'Alterar conteúdo',
          },
        },
        inputs: {
          videoAsset: {
            title: 'Enviar um vídeo',
            subtitle: 'Tamanho recomendado: 1171x626px',
            errorMessage: 'Envie o vídeo acima para salvar.',
          },
          desktopAsset: {
            title: 'Enviar uma imagem',
            label: 'Banner Desktop',
            subtitle: 'Tamanho recomendado: 1171x626px',
            errorMessage: 'Envie a imagem acima para salvar.',
          },
          mobileAsset: {
            title: 'Enviar uma imagem',
            label: 'Banner Mobile',
            subtitle: 'Tamanho recomendado: 1171x626px',
            errorMessage: 'Envie a imagem acima para salvar.',
          },
        },
        options: {
          recommendation: {
            label: 'Banner com recomendação automática',
            description:
              'Nosso algoritmo determinará os conteúdos mais relevantes para exibir no banner. Com a recomendação automática, cada membro terá uma experiência única.',
          },
          post: {
            label: 'Banner com recomendação manual',
            description:
              'Selecione esta opção para escolher manualmente um conteúdo que deseja destacar no banner para todos os membros. Caso o membro não tenha acesso ao conteúdo escolhido, ele receberá uma recomendação automática dos conteúdos mais relevantes.',
          },
          video: {
            label: 'Banner com vídeo',
            description:
              'Selecione esta opção para fazer upload de um vídeo que será exibido no banner para todos os alunos.',
          },
          image: {
            label: 'Banner com imagem',
            description:
              'Selecione esta opção para fazer upload de uma imagem que será exibido no banner para todos os alunos.',
          },
        },
      },
      customLogo: {
        title: 'Logo',
        description: 'Adicione seu logo, ele irá aparecer no menu superior da sua área de membros.',
        options: {
          showLogo: {
            label: 'Exibir logo',
            description: 'Adicione seu logo, ele irá aparecer no menu superior da sua área de membros.',
          },
          logoAsset: {
            title: 'Enviar uma imagem',
            subtitle: 'Tamanho recomendado: 180x60px',
            errorMessage: 'Envie a imagem acima para salvar.',
          },
        },
      },
      toggleThumbnail: {
        title: 'Preferência de layout',
        description: 'Personalize o formato no qual seus conteúdos ou módulos serão exibidos na área de membros.',
        options: {
          thumbnail: {
            label: 'Exibir título',
            description:
              'Selecione essa opção para exibir o título do conteúdo ou do módulo no layout da sua área de membros.',
          },
        },
      },
      togglePostComment: {
        title: 'Área de comentários',
        description:
          'Ative e desative a área de comentários dentro da sua área de membros, e possibilite que seus membros possam interagir com você e entre si.',
        options: {
          postComment: {
            label: 'Ativar área de comentários',
            description:
              'Selecione essa opção para exibir a área de comentários em todos os conteúdos publicados na sua área de membros.',
          },
        },
      },
      togglePdfWatermark: {
        title: 'Marca d’água em PDF',
        description: 'Proteja seus conteúdos contra compartilhamento de materiais.',
        options: {
          thumbnail: {
            label: 'Exibir marca d’água em PDF',
            description:
              'Para evitar compartilhamento de materiais, toda vez que algum cliente fazer o download de seus arquivo pdf, adicionaremos um marca d’água com o Telefone e o ID do cliente na Hubla.',
          },
        },
      },
      toggleModuleHighlight: {
        title: 'Módulo em destaque',
        description: 'Personalize o modo de exibição dos seus conteúdos e módulos.',
        options: {
          thumbnail: {
            label: 'Ativar módulo em destaque',
            description: 'Selecione essa opção para exibir todos os módulos em uma única linha.',
          },
        },
      },
    },
  },
  settings: {
    tabs: {
      basicInfo: 'Informações básicas',
      productPage: 'Página do produto',
      trackers: 'Pixel',
      myPartners: 'Co-produção',
      affiliates: 'Afiliados',
      welcomeMessage: 'Mensagem de boas-vindas',
    },
    basicInfo: {
      title: 'Sobre o seu produto',
      subtitle: 'Preencha as informações básicas sobre o seu produto e se destaque.',
      categories: {
        homeAndConstruction: 'Casa e Construção',
        privateclub: 'Clube Privado',
        culinaryAndGastronomy: 'Culinária e Gastronomia',
        design: 'Design',
        personalDevelopment: 'Desenvolvimento Pessoal',
        law: 'Direito',
        education: 'Educação',
        investmentsAndFinancialEducation: 'Educação Financeira',
        professionalEducation: 'Educação Profissional',
        entrepreneurship: 'Empreendedorismo',
        entertainment: 'Entretenimento',
        spirituality: 'Espiritualidade',
        games: 'Games',
        hobbies: 'Hobbies',
        languages: 'Idiomas',
        digitalMarketing: 'Marketing Digital',
        milesAndTrips: 'Milhas e Viagens',
        fashionAndBeauty: 'Moda e Beleza',
        musicAndArts: 'Música e Artes',
        news: 'Notícias',
        promotionsAndCashback: 'Promoções e Cashback',
        relationship: 'Relacionamentos',
        signalAndTradingRooms: 'Salas de Sinais e Negociações',
        healthAndFitness: 'Saúde e Fitness',
        bets: 'Apostas Esportivas',
        crypto: 'Cripto',
        investments: 'Investimentos e Trading',
        travel: 'Milhas e Viagens',
        wellness: 'Saúde e Fitness',
        other: 'Outro',
      },
      input: {
        cover: {
          title: 'Capa do produto',
          subtitle: 'JPEG, PNG, GIF ou SVG acima de 820x440 pixels',
        },
        name: 'Nome do produto',
        category: 'Categoria',
        description: 'Descrição',
      },
    },
    trackers: {
      title: 'Pixel de rastreamento',
      subtitle: 'Configure diversas opções de estratégias de vendas para o seu produto.',
      fields: {
        facebook: {
          id: 'Meta Pixel',
          accessToken: 'API de conversões',
          verified: 'Domínio verificado',
          verifiedSubtitle:
            'Com as atualizações do Facebook, para atender os requisitos da Apple para o iOS 14, você só poderá rodar campanhas que são otimizadas com eventos de conversão com um domínio verificado.',
          domain: {
            label: 'Domínio',
            notVerified: '',
            step_1: '1. Digite o domínio que você usa no Facebook Business Manager.',
            helper_1:
              'O domínio deve estar verificado no Business Manager. Caso tenha qualquer dúvida. Por favor, nos chame no suporte.',
            step_2: '2. Siga os passos para configurar seu CNAME com seu registro de domínio.',
            helper_2: 'Um CNAME é usado para apontar um subdomínio que será usado no fluxo do evento de conversão.',
            step_2_1: '2.1. Vá até a configuração de DNS do seu registro de domínio.',
            step_2_2: '2.2. Adicione um CNAME {{domain}} apontando para pixel.hub.la',
            step_2_3: '2.3. Salve o DNS.',
            helper_3: 'Se você estiver inseguro quanto a este processo. Por favor, contate o seu registro de domínio.',
            helper_4:
              'Um certificado será gerado automaticamente após a verificação. A propagação do DNS pode levar até 72 horas.',
          },
        },
        tagManager: 'Google Tag Manager',
        googleAnalytics: 'Google Analytics 4',
      },
    },
    productPage: {
      cover: {
        title: 'Personalize sua página do produto',
        subtitle: 'Personalize a sua página do produto para alcançar melhores resultados no momento da venda.',
        input: {
          title: 'Plano de fundo',
          subtitle: 'JPEG, PNG, GIF ou SVG acima de 1440x1024 pixels',
        },
      },
      buyCta: {
        title: 'Botão de vendas',
        subtitle: 'Configure qual será o valor exibido no botão de acordo com o seu pitch de vendas',
        disclaimer:
          'Por padrão, exibimos no botão o menor valor mensal entre todos os planos. Mas caso opte por outra estratégia, você pode personalizar o texto que é exibido no botão.',
        input: {
          switch: 'Personalizar botão de vendas',
          text: 'Texto',
        },
      },
      media: {
        title: 'Mídia',
        subtitle:
          'Adicione mídias que ficarão em destaque na sua página. Elas podem servir para complementar visualmente o seu produto, quebrar a objeção do cliente ou até mesmo mostrar uma prova social.',
        input: {
          title: 'Adicionar arquivo(s)',
          subtitle: 'Imagens ou vídeos do YouTube',
          add: 'Adicionar',
        },
        youtube: {
          title: 'Insira a URL do vídeo do YouTube',
          label: 'URL do YouTube',
          cancel: 'Cancelar',
          add: 'Adicionar',
          error: 'A URL precisa ser do YouTube',
        },
      },
      description: {
        title: 'Descrição',
        subtitle:
          'A descrição do seu produto pode aumentar significativamente a conversão de compra na sua página de vendas. Seja claro, breve e conte um pouco sobre o seu produto.',
        input: {
          title: 'Tópico',
          subtitle: 'Adicione uma descrição',
          add: 'Adicionar nova sessão',
        },
      },
      advantages: {
        title: 'Vantagens',
        subtitle:
          'Adicione uma lista de vantagens para esclarecer ao cliente, qual tipo de acesso, conteúdo e outros benefícios ele irá adquirir ao fazer parte do seu produto.',
        input: {
          subtitle: 'Descreva a vantagem',
          add: 'Adicionar novo item',
        },
      },
      faq: {
        title: 'Perguntas frequentes',
        subtitle:
          'Ao criar uma lista de perguntas frequentes, você reduz a quantidade de clientes que acionam o seu time de suporte, ou até mesmo deixam de participar do seu produto por conta de uma dúvida.',
        input: {
          title: 'Escreva sua pergunta',
          subtitle: 'Escreva sua resposta',
          add: 'Adicionar nova pergunta',
        },
      },
    },
    myPartners: {
      status: {
        invited: 'Aguardando aceite',
        cancellation_requested: 'Cancelamento solicitado',
        rejected: 'Recusada',
        accepted: 'Ativa',
        canceled: 'Cancelada',
        finished: 'Finalizada',
      },
      deleteModal: {
        title: 'Deseja cancelar vínculo com parceiro?',
        description: 'Essa ação necessita que o seu parceiro confirme a solicitação de cancelamento.',
        cancel: 'Voltar',
        confirm: 'Solicitar cancelamento',
      },
      empty: 'Nenhum parceiro ainda',
      emptySubtitle: 'Adicione parceiros para co-produzir o seu produto.',
    },
    affiliates: {
      title: 'Afiliados',
      subtitle: 'Configure e gerencie seu programa de afiliados para esse produto.',
      switch: 'Programa de afiliados',
      helperText: 'Ofereça uma comissão para pessoas que queiram promover seu produto.',
      input: {
        type: 'Tipo de comissão',
        sell: '% comissão por compra',
        renewal: '% comissão por renovação',
        option_1: 'Primeira compra e renovação',
        option_2: 'Apenas primeira compra',
        option_3: 'Apenas renovação',
      },
      preferences: {
        title: 'Preferências de afiliados:',
        any: 'Qualquer um pode se afiliar ao produto',
        members: 'Somente membros do produto podem se afiliar',
      },
    },
    affiliatesEvent: {
      title: 'Afiliados',
      subtitle: 'Configure e gerencie seu programa de afiliados para esse evento.',
      switch: 'Programa de afiliados',
      helperText: 'Ofereça uma comissão para pessoas que queiram promover seu evento.',
      input: {
        type: 'Tipo de comissão',
        sell: '% comissão por compra',
        renewal: '% comissão por renovação',
        option_1: 'Primeira compra e renovação',
        option_2: 'Apenas primeira compra',
        option_3: 'Apenas renovação',
      },
      preferences: {
        title: 'Preferências de afiliados:',
        any: 'Qualquer um pode se afiliar ao evento',
        members: 'Somente membros do evento podem se afiliar',
      },
    },
    welcomeMessage: {
      title: 'Mensagem de boas-vindas',
      subtitle: 'Personalize a mensagem de boas-vindas que será enviada para os membros do seu produto.',
      files: 'Arquivos',
    },
  },
  waitlist: {
    subscribersAmount: 'Contatos',
    conversionsAmount: 'Convertidos',
    empty: 'Nenhum contato ainda',
    emptySubtitle:
      'Para capturar novos contatos, ative a lista de espera do seu produto e compartilhe sua página do produto.',
    export: {
      title: 'Exportar contatos',
      fileExtension: 'Extensão do arquivo',
      confirm: 'Exportar',
      cancel: 'Cancelar',
      placeholder: 'Selecione o formato',
    },
    waitlistStatus: {
      allMembers: 'Todos os contatos',
      notMember: 'Não convertidos',
      convertedMember: 'Convertidos',
    },
  },
  productStatus: {
    isDraft: 'Rascunho',
    isPublished: 'Vendendo',
    isSelling: 'Vendendo',
    isWaitlisted: 'Lista de espera',
    isNotEnabled: 'Desativado',
    isNotSelling: 'Desativado',
    isUnavailable: 'Recusado',
    isDeleted: 'Desativado',
  },
  memberType: {
    seller: 'Produtor',
    partner: 'Co-produtor',
    free: 'Gratuito',
    buyer: 'Pago',
  },
  tabs: {
    participants: {
      title: 'Participantes',
      tabActive: 'Participantes',
      tabInvites: 'Convites enviados',
      actions: {
        search: {
          label: 'Buscar',
          placeholder: 'Buscar',
        },
        memberType: { default: 'Tipo', seller: 'Produtor', partner: 'Co-produtor', free: 'Gratuito', buyer: 'Pagante' },
        addMember: 'Adicionar participante(s)',
        export: 'Exportar',
      },
      table: {
        member: {
          label: 'Participante',
          tooltip: 'Nome completo do participante.',
        },
        memberId: { label: 'ID do participante', tooltip: 'Identificador único do participante.' },
        email: { label: 'E-mail' },
        cohorts: { label: 'Ingresso' },
        phoneNumber: { label: 'Telefone' },
      },
      removeFreeMember: 'Remover participante gratuito',
      changeCohortMember: 'Alterar ingresso do participante',
      removeFreeMemberModal: {
        title: 'Deseja remover participante gratuito do evento?',
        description: 'Ao realizar essa ação, você estará removendo o acesso do participante ao evento.',
        confirmText: 'Remover participante gratuito',
      },
      changeMembersCohortsBar: {
        selecteds: '{{total}} participantes selecionados',
        changeCohorts: 'Alterar ingresso do(s) participante(s)',
      },
      changeMembersCohortsModal: {
        title: 'Alterar ingresso do participante',
        description:
          'Selecione os ingressos que deseja dar acesso. Ao alterar as ingresso atuais do membro, você está alterando seus níveis de acesso aos recursos do produto.',
        membersSelectTag: '{{total}} participantes selecionados',
        cohortsSelect: {
          label: 'Novo ingresso',
          placeholder: 'Selecione uma turma',
          all: 'Todas as ingresso ({{total}})',
        },
        cancel: 'Cancelar',
        confirm: 'Alterar ingresso',
        snackbar: {
          successOne: 'ingresso alterada(s) com sucesso!',
          successMany: '{{count}} participantes tiveram suas turmas alteradas com sucesso!',
          failOne: 'Erro na alteração da(s) ingresso. Tente novamente.',
          failMany: 'Erro na alteração da(s) ingresso dos participantes {{users}}. Tente novamente.',
          genericError: 'Erro inesperado na alteração da(s) ingresso. Tente novamente.',
        },
        fieldErrors: {
          min: 'Você precisa selecionar pelo menos uma turma',
        },
      },
    },
    members: {
      title: 'Membros ativos',
      actions: {
        search: {
          label: 'Buscar',
          placeholder: 'Buscar...',
        },
        memberType: { default: 'Tipo', seller: 'Produtor', partner: 'Co-produtor', free: 'Gratuito', buyer: 'Pagante' },
        addMember: 'Adicionar membro(s)',
        export: 'Exportar',
      },
      table: {
        member: {
          label: 'Cliente',
          tooltip: 'Nome completo do cliente.',
        },
        memberId: { label: 'ID do cliente', tooltip: 'Identificador único do cliente.' },
        email: { label: 'E-mail' },
        cohorts: { label: 'Turma' },
        phoneNumber: { label: 'Telefone' },
        expiresAt: { label: 'Remoção', tooltip: 'Data de remoção automática prevista.' },
      },
      removeFreeMember: 'Remover membro gratuito',
      changeCohortMember: 'Alterar turma(s) do membro',
      removeFreeMemberModal: {
        title: 'Deseja remover membro gratuito do produto?',
        description: 'Ao realizar essa ação, você estará removendo o acesso do membro ao produto.',
        confirmText: 'Remover membro gratuito',
      },
      changeMembersCohortsBar: {
        selecteds: '{{total}} selecionado(s)',
        changeCohorts: 'Alterar turma',
      },
      changeMembersCohortsModal: {
        title: 'Alterar turma(s) do membro',
        description:
          'Selecione a(s) turma(s) que deseja dar acesso. Ao alterar as turmas atuais do membro, você está alterando seus níveis de acesso aos recursos do produto.',
        membersSelectTag: '{{total}} selecionado(s)',
        cohortsSelect: {
          label: 'Nova(s) turma(s)',
          all: 'Todas as turmas ({{total}})',
        },
        cancel: 'Cancelar',
        confirm: 'Alterar turma(s)',
        snackbar: {
          successOne: 'Turma(s) alterada(s) com sucesso!',
          successMany: '{{count}} membros tiveram suas turmas alteradas com sucesso!',
          failOne: 'Erro na alteração da(s) turma(s). Tente novamente.',
          failMany: 'Erro na alteração da(s) turma(s) dos membros {{users}}. Tente novamente.',
          genericError: 'Erro inesperado na alteração da(s) turma(s). Tente novamente.',
        },
        fieldErrors: {
          min: 'Você precisa selecionar pelo menos uma turma',
        },
      },
    },
    invites: {
      title: 'Convites pendentes',
      inputs: {
        email: {
          label: 'E-mail',
        },
        fullName: {
          label: 'Nome completo',
        },
        phoneNumber: {
          label: 'Telefone com DDD',
        },
      },
      actions: {
        search: {
          label: 'Buscar',
          placeholder: 'Buscar e-mail',
        },
        export: 'Exportar',
      },
      table: {
        email: 'E-mail',
        inviteLink: 'Link de convite',
        status: 'Status',
        createdAt: 'Enviado em',
        actions: 'Ações',
      },
      rowActions: {
        deleteInviteTooltip: 'Deletar convite',
        copyLinkTooltip: 'Copiar link do convite',
      },
      cancelInviteModal: {
        title: 'Deseja deletar o convite enviado?',
        description:
          'Ao realizar essa ação, você estará deletando o convite enviado, ou seja, mesmo que o cliente acesse o link fornecido, esse link não estará mais ativo.',
        confirmText: 'Deletar convite',
      },
      successCopyLink: 'Link de convite copiado com sucesso!',
    },
    oldMembers: {
      title: 'Membros anteriores',
      actions: {
        search: {
          label: 'Buscar',
          placeholder: 'Buscar...',
        },
        export: 'Exportar',
      },
      table: {
        email: { label: 'E-mail' },
        phoneNumber: { label: 'Telefone' },
        member: {
          label: 'Cliente',
          tooltip: 'Nome completo do cliente.',
        },
        memberId: { label: 'ID do cliente', tooltip: 'Identificador único do cliente.' },
        sinceAt: { label: 'Removido', tooltip: 'Data em que o cliente deixou de ser membro do produto.' },
      },
    },
  },
  drawerTransformFreeMembers: {
    title: '{{total}} membros anteriores',
    actions: {
      transform: 'Transformar em membro gratuito',
    },
    snackbar: {
      success: 'Membros anteriores foram adicionados como gratuitos com sucesso!',
      error: 'Erro na transformação dos clientes <0>{{users}}</0> em membro gratuito. Tente novamente.',
    },
  },
  genericConfirmModal: {
    cancel: 'Cancelar',
    confirm: 'Confirmar',
  },
  invitePage: {
    title: {
      cancelled: 'Convite cancelado pelo o produtor',
      unspecified: 'Convite não especificado',
      alreadyAccepted: 'Convite aceito com sucesso!',
      invited: 'Você foi convidado para ser membro do produto {{productName}}',
      expired: 'Seu convite expirou!',
      errorDefault: 'Erro inesperado para carregar o convite.',
      notFound: 'Convite não encontrado ou excluído.',
    },
    errorButtons: {
      userGroups: 'Acessar minhas compras',
      access: 'Acessar produto',
    },
    description: {
      unspecified: 'O convite não foi especificado.',
      cancelled: 'O convite foi cancelado pelo produtor, peça um novo convite para ter acesso ao produto.',
      alreadyAccepted: 'Acesse seu produto agora.',
      expired: 'Solicite um novo convite para ter acesso ao produto.',
      withoutAccount: 'Para aceitar o convite e ter acesso ao produto, preencha as informações pendentes abaixo:',
      errorDefault: 'Erro inesperado entre em contato com o suporte.',
      notFound: 'O convite não está mais disponível, peça um novo convite ao produtor para ter acesso ao produto.',
    },
    acceptInvite: 'Aceitar convite',
    inviteFreeTime: 'Acesso gratuito por: {{days}} dias',
    inviteLifetime: 'Acesso gratuito ilimitado',
    inputs: {
      email: {
        label: 'E-mail',
      },
      fullName: {
        label: 'Nome completo',
      },
      phoneNumber: {
        label: 'Telefone com DDD',
      },
    },
    errors: {
      phoneNumber: {
        required: 'Telefone é obrigatório',
        invalid: 'Telefone inválido',
      },
      fullName: {
        required: 'Nome completo é obrigatório',
        firstAndLastName: 'O nome completo deve conter nome e sobrenome.',
      },
    },
    inviteErrors: {
      alreadyPhoneNumber:
        'O telefone informado já está associado a outra conta! Escolha outro telefone ou entre em contato com o suporte para a alteração da sua conta.',
      unsupportedStatus: 'O convite não está mais disponível.',
      notFound: 'O convite excluído ou não encontrado.',
      invalidType: 'O convite não é válido.',
      emailMismatch: 'O e-mail informado não corresponde ao e-mail do convite.',
    },
  },
  totalMembers: '{total} membros',
  addFreeMembersModal: {
    title: 'Adicionar membro(s) gratuito(s)',
    cohortTooltip: 'Ao aceitar o convite, o membro gratuito será adicionado a sua turma padrão.',
    description:
      'Ao adicionar novos membros gratuitos via e-mail, eles receberão o acesso ao produto gratuitamente pelo tempo determinado por você.',
    alert:
      'O cliente receberá um link de convite no e-mail informado, e ao aceitá-lo se tornará um membro gratuito do produto.',
    inputs: {
      cohort: {
        label: 'Turma',
      },
      emails: {
        label: 'E-mail(s)',
        helperText: 'Para adicionar um e-mail, pressione a tecla enter ou cole os e-mails separados por vírgula.',
        invalidEmail: 'Um ou mais emails digitados estão inválidos e não foram adicionados',
      },
      days: {
        label: 'Dia(s)',
      },
      lifetimeCheckbox: 'Conceder acesso gratuito vitalício para o(s) membro(s)',
    },
    errors: {
      emails: {
        min: 'Adicione pelo menos um e-mail pressionando a tecla enter.',
        required: 'Adicione pelo menos um e-mail pressionando a tecla enter.',
      },
      days: {
        min: 'É necessário adicionar pelo menos um dia',
        required: 'É necessário adicionar pelo menos um dia',
      },
    },
    actions: {
      cancel: 'Cancelar',
      submit: 'Adicionar membro(s) gratuito(s)',
    },
    snackbar: {
      success: 'Membro(s) gratuito(s) foram convidados com sucesso!',
      error: 'Erro ao criar convite para:\n{{users}}',
      defaultError: 'Erro inesperado, nenhum convite foi criado! Tente novamente.',
    },
  },
  addFreeParticipantsModal: {
    title: 'Adicionar participante(s) gratuito(s)',
    cohortTooltip: 'Ao aceitar o convite, o membro gratuito será adicionado a sua turma padrão.',
    description:
      'O cliente receberá um link de convite no e-mail informado, e ao aceitá-lo se tornará participante gratuito do seu evento.',
    alert:
      'O cliente receberá um link de convite no e-mail informado, e ao aceitá-lo se tornará um membro gratuito do produto.',
    inputs: {
      cohort: {
        label: 'Ingresso',
      },
      emails: {
        label: 'E-mail(s)',
        helperText: 'Para adicionar um e-mail, pressione a tecla enter ou cole os e-mails separados por vírgula.',
      },
      days: {
        label: 'Dia(s)',
      },
      lifetimeCheckbox: 'Conceder acesso gratuito vitalício para o(s) membro(s)',
    },
    errors: {
      emails: {
        min: 'Adicione pelo menos um e-mail pressionando a tecla enter.',
        required: 'Adicione pelo menos um e-mail pressionando a tecla enter.',
      },
      days: {
        min: 'É necessário adicionar pelo menos um dia',
        required: 'É necessário adicionar pelo menos um dia',
      },
    },
    actions: {
      cancel: 'Cancelar',
      submit: 'Adicionar membro(s) gratuito(s)',
    },
    snackbar: {
      success: 'Membro(s) gratuito(s) foram convidados com sucesso!',
      error: 'Erro ao criar convite para:\n{{users}}',
      defaultError: 'Erro inesperado, nenhum convite foi criado! Tente novamente.',
    },
  },
  emptyInvites: 'Nenhum resultado encontrado.',
  emptyInvitesSubtitle: 'Ainda não há convites criados. Adicione membros gratuitos e libere o acesso ao seu produto.',
  emptyMembers: 'Nenhum resultado encontrado.',
  emptyActiveMembersSubtitle: 'Ajuste seus critérios de busca ou redefina os filtros para obter mais resultados.',
  emptyOldMembersSubtitle: 'Ainda não há membros anteriores.',
  transformFreeMembersModal: {
    title: 'Transformar em membro gratuito',
    description:
      'Ao transformar membros anteriores em membros gratuitos, eles receberão o acesso ao produto gratuitamente pelo tempo determinado por você.',
    alert:
      'O cliente receberá um convite no seu e-mail cadastrado, e ao aceitá-lo se tornará um membro gratuito do produto.',
    selectedMembers: '{{total}} membro(s) selecionado(s)',
    inputs: {
      days: {
        label: 'Dia(s)',
      },
      lifetimeCheckbox: 'Conceder acesso gratuito vitalício para o(s) membro(s)',
    },
    errors: {
      days: {
        min: 'É necessário adicionar pelo menos um dia',
      },
    },
    actions: {
      cancel: 'Cancelar',
      submit: 'Transformar em membro gratuito',
    },
  },
  transformFreeParticipantModal: {
    title: 'Transformar em membro gratuito',
    description:
      'Ao transformar membros anteriores em membros gratuitos, eles receberão o acesso ao produto gratuitamente pelo tempo determinado por você.',
    alert:
      'O cliente receberá um convite no seu e-mail cadastrado, e ao aceitá-lo se tornará um membro gratuito do produto.',
    selectedMembers: '{{total}} membro(s) selecionado(s)',
    inputs: {
      days: {
        label: 'Dia(s)',
      },
      lifetimeCheckbox: 'Conceder acesso gratuito vitalício para o(s) membro(s)',
    },
    errors: {
      days: {
        min: 'É necessário adicionar pelo menos um dia',
      },
    },
    actions: {
      cancel: 'Cancelar',
      submit: 'Transformar em membro gratuito',
    },
  },
  singleInviteValidationEnum: {
    product_no_space: 'O produto não possui mais espaço.',
    already_has_subscription: 'Já possui uma assinatura ativa.',
    already_exists_invite: 'Já existe um convite para este e-mail.',
    error_creating_invite: 'Erro inesperado.',
  },
  // shortlink
  emptyShortlinks: 'Nenhum resultado encontrado.',
  emptyShortlinksSubtitle: 'Ainda não há links criados.',
  shortlinks: {
    types: {
      CHECKOUT: 'Checkout',
      CHECKOUT_ONE_CLICK: 'Checkout 1-clique',
      PRODUCT: 'Página de produto (Hubla)',
      PRODUCT_EXTERNAL: 'Página de venda externa',
      MEMBER_AREA: 'Área de membros (Hubla)',
      MEMBER_AREA_EXTERNAL: 'Área de membros externa',
    },
    type: {
      checkout: 'Checkout',
      checkout_one_click: 'Checkout 1-clique',
      produt: 'Página de produto (Hubla)',
      product_external: 'Página de venda externa',
      members_area: 'Área de membros',
      members_area_external: 'Área de membros externa',
    },
    sections: {
      checkouts: 'Checkout',
      products: 'Página de vendas',
      memberArea: 'Área de membros',
    },
    utmFields: {
      utmSource: 'Origem',
      utmMedium: 'Mídia',
      utmCampaign: 'Campanha',
      utmContent: 'Conteúdo',
      utmTerm: 'Termo',
    },
    table: {
      name: 'Nome',
      url: 'URL encurtada',
      utms: `UTM's configuradas`,
      clicks: 'Cliques',
      options: {
        edit: 'Editar',
        duplicate: 'Duplicar',
        delete: 'Deletar',
      },
      tooltips: {
        url: 'URL encurtada para facilitar compartilhamento e o rastreamento via UTMs.',
        utms: 'Parâmetros de UTMs que serão aplicados a URL encurtada gerada para garantir o rastreamento.',
        clicks: 'Quantidade de cliques realizados na URL encurtada disponibilizada',
      },
      isDefault: 'Padrão',
      emptyStateTitle: 'Nenhum resultado encontrado',
    },
    buttons: {
      addShortlink: 'Novo link',
      copyUrl: 'Copiar URL',
      cancel: 'Cancelar',
      deleteLink: 'Deletar link',
      create: 'Criar novo link',
      edit: 'Salvar alterações',
    },
    modals: {
      delete: {
        title: 'Deseja deletar esse link?',
        description: 'Essa ação é irreversível. Não será possível recuperar esse link, nem visualizar seu dados.',
      },
    },
    errors: {
      alreadyExists: 'Já existe um link com essa URL encurtada.',
      validation: {
        required: 'Campo obrigatório',
        mustBeUrl: 'Deve ser uma URL válida',
        leastOne: 'Pelo menos um UTM deve ser preenchido',
        notAllowedRedirect: 'Não é permitido redirecionar para um outro link curto',
      },
    },
    instruction: {
      utm: `Copie e cole o código em sua página de vendas externa para garantir o repasse de UTM's no checkout`,
    },
    snackbar: {
      createSuccess: 'Link criado com sucesso!',
      editSuccess: 'Link atualizado com sucesso!',
      error: 'Erro ao criar link. Tente novamente.',
    },
  },
  offers: {
    count: '{{count}} oferta(s)',
    create: 'Criar oferta',
    table: {
      name: 'Oferta',
      price: 'Preço',
      checkout: 'Checkout',
      isDefault: 'Padrão',
    },
    offerSwitchStatus: {
      defaultIsNotPossible:
        'Não é possível alterar o status da oferta padrão, pois reflete o estado atual do produto. Ao ativar o produto, a oferta padrão será ativada automaticamente.',
    },
    options: {
      edit: 'Editar',
      copyOneClick: 'Copiar link de checkout 1-clique',
    },
    plans: {
      monthly: 'mensal',
      quarterly: 'trimestral',
      semiannually: 'semestral',
      annually: 'anual',
    },
    emptyState: 'Nenhuma oferta encontrada.',
  },
  cohorts: {
    create: 'Criar turma',
    count: '{{count}} turma(s)',
    table: {
      name: 'Turma',
      createdAt: 'Data de criação',
      isDefault: 'Padrão',
    },
    emptyState: 'Nenhuma turma encontrada.',
  },
  participants: {
    emptyState: 'Nenhum participante encontrado.',
  },
  tickets: {
    create: 'Novo Ingresso',
    emptyState: 'Nenhum ingresso encontrado.',
    emptyStateSubtitle: 'Crie um novo ingresso para o seu evento.',
  },
}

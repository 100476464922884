import { alpha, createBreakpoints, createSpacing } from '@mui/system'
import { TOKENS_LIGHT } from './theme/tokens/tokens'
import { Tokens } from './theme/tokens/tokens.types'

export const shouldNotForwardProps = (prop: string) => {
  return ![
    'marginTop',
    'marginLeft',
    'marginRight',
    'marginBottom',
    'internalVariant',
    'textAlign',
    'm',
    'mr',
    'mt',
    'fullHeight',
    'fullWidth',
    'ml',
    'mb',
    'margin',
    'marginX',
    'marginY',
    'mx',
    'my',
    'hasHover',
    'iconOnly',
    'bgHeadCell',
    'iconPosition',
  ].includes(prop)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any

export const spacing = createSpacing(4)

export const breakpoints = createBreakpoints({})

export type Shape = 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge' | 'full' | 'noRounding'

export type Elevation = 'flat' | 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge'

export const shape = (value: Shape) => {
  const shapes = {
    noRounding: '0',
    extraSmall: '4px',
    small: '8px',
    medium: '12px',
    large: '16px',
    extraLarge: '28px',
    full: '50%',
  }

  return shapes[value]
}

export const elevation = (value: Elevation) => {
  const elevations = {
    extraSmall: '2px 4px 0',
    small: '2px 8px 0',
    medium: '8px 16px 0',
    large: '16px 24px 0',
    extraLarge: '24px 32px 0',
  }

  if (value === 'flat') {
    return ''
  }

  return `${elevations[value]} ${alpha(TOKENS_LIGHT.onBackground, 0.16)}`
}

export const palette = (token: keyof Tokens) => (props: Any) => {
  return props['theme']['palette'][token].main
}

export const _palette = (props: Any) => (token: keyof Tokens) => {
  return props['theme']['palette'][token].main
}

export function withUtility<Props extends Record<string, Any>>(
  fn: ({ palette }: { palette: (token: keyof Tokens) => Any; isLight: boolean }, props: Props) => Any,
) {
  return (props: Props) => {
    const isLight = props['theme']['palette'].mode === 'light'

    return fn({ palette: _palette(props), isLight }, props)
  }
}

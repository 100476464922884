import { EmptyLayout, FullLayout, PartialLayout } from '@hub-la/shadcn'
import { BannerCollapsible } from '../../presentation/pages/offer/checkout/components/customization/banner-collapsible'
import { FooterBannerCollapsible } from '../../presentation/pages/offer/checkout/components/customization/footer-banner-collapsible'
import { GuaranteeSealCollapsible } from '../../presentation/pages/offer/checkout/components/customization/guarantee-seal-collapsible'
import { SideBannerCollapsible } from '../../presentation/pages/offer/checkout/components/customization/side-banner-collapsible'
import { CheckoutLayoutType } from '../enums/checkout-layout-type.enum'

export type CheckoutLayoutTypeOption = {
  id: CheckoutLayoutType
  icon: JSX.Element
  components: any[]
}

const layoutTypeFieldOptions: CheckoutLayoutTypeOption[] = [
  {
    id: CheckoutLayoutType.NO_BANNER,
    icon: <EmptyLayout className="w-4 h-4" />,
    components: [GuaranteeSealCollapsible]
  },
  {
    id: CheckoutLayoutType.TOP_AND_BOTTOM_BANNER,
    icon: <PartialLayout className="w-4 h-4" />,
    components: [BannerCollapsible, FooterBannerCollapsible, GuaranteeSealCollapsible]
  },
  {
    id: CheckoutLayoutType.FULL_BANNER,
    icon: <FullLayout className="w-4 h-4" />,
    components: [BannerCollapsible, FooterBannerCollapsible, SideBannerCollapsible, GuaranteeSealCollapsible]
  },
]

export { layoutTypeFieldOptions }

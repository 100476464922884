import { ImageUploader } from "@hub-la/fe-asset"
import { Controller } from "react-hook-form"
import { useParams } from "react-router-dom"
import { CustomCollapsible } from "../../../../../components/custom-collapsible"
import { useTranslation } from "react-i18next"
import { BannerLayout } from "@hub-la/shadcn"

export const BannerCollapsible = () => {
  const { t } = useTranslation()

  const { productId } = useParams<{ productId: string, offerId: string }>()

  return (
    <CustomCollapsible title={t(`offer.checkoutTab.customization.banner.title`)} Icon={BannerLayout}>
      <div className="grid gap-4">
        <div className="flex justify-between">
          <div>
            <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.customization.banner.mobile.label')}</h3>
            <p className="text-sm text-muted-foreground">{t('offer.checkoutTab.customization.banner.mobile.description')}</p>
          </div>
          <Controller
            name="checkout.bannerMobileUrl"
            render={({ field }) => (
              <ImageUploader
                name={field.name}
                path={`/checkout/${productId}`}
                className="w-24 h-14"
                value={field.value}
                onChange={(file) => field.onChange(file?.URL)}
              />
            )}
          />
        </div>
        <div className="flex justify-between">
          <div>
            <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.customization.banner.desktop.label')}</h3>
            <p className="text-sm text-muted-foreground">{t('offer.checkoutTab.customization.banner.desktop.description')}</p>
          </div>
          <Controller
            name="checkout.bannerDesktopUrl"
            render={({ field }) => (
              <ImageUploader
                name={field.name}
                path={`/checkout/${productId}`}
                className="w-24 h-14"
                value={field.value}
                onChange={(file) => field.onChange(file?.URL)}
              />
            )}
          />
        </div>
      </div>
    </CustomCollapsible>
  )
}

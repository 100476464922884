export const pt = {
  whitelist: {
    generateInviteLink: 'Gerar link de convite',
    searchPlaceholder: 'Procure um nome ou usuário',
    table: {
      Name: 'Nome',
      PhoneNumber: 'Contato',
      CreatedAt: 'Entrou em',
      Action: 'Ações',
    },
    remove: 'Remover',
    modal: {
      remove: {
        title: 'Remover o usuário da whitelist?',
        description: 'Essa ação é irreversível. Não será possível recuperar o usuário.',
        buttons: {
          cancel: 'Cancelar',
          remove: 'Remover',
        },
      },
    },
    generateLink: {
      title: 'Compartilhe o link de convite para sua Whitelist',
      description:
        'Este link só pode ser usado uma vez. Para dar acesso a mais pessoas você precisa gerar vários links.',
    },
    copyLink: 'Copiar link',
  },
  errors: {
    general: 'Ocorreu um erro. Por favor, tente novamente mais tarde.',
    undefined: 'Ocorreu um erro. Por favor, tente novamente mais tarde.',
  },
  copyClipboard: {
    inviteLinkCopied: 'Link do convite copiado',
    copyInviteLink: 'Copiar link do convite',
  },
}

export const FLAGS = {
  CHECKOUT: {
    CHECKOUT_MULTIGATEWAY_FALLBACK_SETTINGS: 'checkoutMultigatewayFallbackSettings', // MAINTAIN
    IS_SABRINA_CHECKOUT_CUSTOMIZATION: 'isSabrinaCheckoutCustomization', // MAINTAIN
  },
  EVENTS: {
    IS_EVENTS_ENABLED: 'isEventsEnabled',
  },
  KYC: {
    IS_CUSTOM_REJECTED_MESSAGE_ON_KYC: 'isCustomRejectedMessageOnKyc',
    SHOULD_UPDATE_BANK_ACCOUNT: 'shouldUpdateBankAccouunt'
  },
}

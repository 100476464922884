import { useTranslation } from 'react-i18next'
import { OfferSection } from '../../../../components/offer-section'
import { Options } from './components/options'
import { PriceConfiguration } from './components/price-configuration'

const PricingEventContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <OfferSection title={t('offer.pricingTab.pricing.title')}>
        <div className="flex flex-col gap-4">
          <PriceConfiguration />
        </div>
      </OfferSection>
      <OfferSection title={t('offer.pricingTab.options.title')}>
        <Options />
      </OfferSection>
    </>
  )
}

export { PricingEventContent }

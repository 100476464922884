import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { ListProductsInput } from '../domain/dtos/list-products-input'
import { ListProductsResponse } from '../domain/dtos/list-products-response'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class ListProducts {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: ListProductsInput, signal?: AbortSignal): Promise<ListProductsResponse> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_PRODUCT_URL}/products`,
      params: {
        page: input.page,
        pageSize: input.pageSize,
      },
      signal,
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new GeneralError()
  }
}

const balances = {
  available: {
    label: 'Disponível para saque',
    tooltip: 'Valor total que está disponível para saque na sua conta',
  },
  receivable: {
    label: 'Valores a receber',
    tooltip: 'Valor que está pendente de liberação e que você ainda irá receber no futuro.',
  },
  contested: {
    label: 'Em contestação',
    tooltip: 'Valor total que está em contestação de chargeback',
  },
  reserved: {
    label: 'Reserva de saldo',
    tooltip:
      'Saldo mínimo mantido durante um período para cobrir reembolsos, estornos ' +
      'e chargebacks solicitados pelos compradores. Somente sendo utilizada caso ' +
      'você não tenha saldo disponível.',
  },
  transferable: {
    label: 'No caminho de seu banco',
    tooltip: 'Saques que estão sendo processados e em breve estarão disponíveis no seu banco',
  },
  requestWithdraw: 'Solicitar saque',
}

const filters = {
  sources: {
    sales: 'Venda',
    withdrawals: 'Saque',
    upgrades: 'Upgrade de plano',
    refunds: 'Reembolso',
    chargebacks: 'Chargeback',
    renewals: 'Renovação',
  },
  bookAccounts: {
    available: 'Disponível para saque',
    receivable: 'Valores a receber',
    contested: 'Valores em contestação',
    reserved: 'Reserva de saldo',
    bankAccount: 'Dados Bancários & Conta',
    ratesAndTimelines: 'Taxas & Prazos',
  },
  lastXDays: 'Últimos {{range}} dias',
  exportSpreadsheet: 'Exportar planilha',
  date: 'Filtrar por data',
  today: 'Hoje',
  thisWeek: 'Esta semana',
  thisMonth: 'Este mês',
  thisSemester: 'Este semestre',
  thisYear: 'Este ano',
}

const invoicePaymentMethod = {
  PAYMENT_METHOD_PIX: 'PIX',
  PAYMENT_METHOD_CARD: 'Cartão de crédito',
  PAYMENT_METHOD_BOLETO: 'Boleto',
}

const withdrawalDetails = {
  bankAccount: {
    title: 'Conta bancária',
    name: 'Nome',
    bankName: 'Banco',
    bankAgency: 'Agência',
    bankNumber: 'Conta',
    bankAccountType: 'Tipo de conta',
    bankType: {
      1: 'Conta Corrente',
      2: 'Conta poupança',
    },
  },
  isPixKey: 'Saque realizado via chave PIX, conforme dados cadastrados.',
  paymentData: {
    title: 'Dados de pagamento',
    amount: 'Valor do saque',
    tax: 'Taxa',
    tooltip: 'Uma taxa de {{value}} foi cobrada pela transferência.',
    total: 'Valor liquido',
  },
  rejectionInfo: {
    title: 'Saque Rejeitado',
    description: 'Descrição',
    reasons: {
      'Informed tax ID does not match account owner': 'CPF ou CNPJ informado não corresponde ao proprietário da conta',
      'Invalid CPF or CNPJ': 'CPF ou CNPJ inválido',
      'Invalid account number': 'Número da conta inválido',
      'Receiver bank encountered an internal error': 'Banco destinatário encontrou um erro interno',
      'Receiving bank timed out': 'Tempo limite do banco destinatário atingido',
      'Target account is blocked': 'Conta de destino bloqueada',
      'Target account is closed': 'Conta de destino fechada',
      'The transfer was rejected by the receiving bank': 'A transferência foi rejeitada pelo banco destinatário',
    },
  },
}

const invoiceDetails = {
  customer: {
    name: 'Cliente',
    email: 'E-mail',
    cellphone: 'Celular',
  },
  customerLabel: 'Cliente',
  invoiceLabel: 'Dados da fatura',
  paymentLabel: 'Dados de pagamento',
  commissionTotalValueInCents: 'Valor total da comissão',
  commissionTotalValuePercent: 'Porcentagem total da comissão',
  payment: {
    createdAt: 'Data de criação',
    paidAt: 'Data de pagamento',
    methodLabel: 'Método de pagamento',
    type: 'Tipo de pagamento',
    recurrence: 'Recorrência',
    affiliateCommission: 'Comissão de afiliados',
    productPrice: 'Valor do produto',
    couponDiscount: 'Cupom de desconto',
    couponName: 'Cupom utilizado',
    companyFee: 'Taxa da Hubla',
    companyFeeTooltipText:
      'Esta incluso nesta compra a Taxa de serviço da Hubla e os custos da transação e do grupo. Dúvidas?',
    companyFeeTooltipLink: 'Saiba mais aqui.',
    otherFee: 'Outras taxas',
    total: 'Valor Líquido',
    hasMembershipFee: 'Possui taxa de adesão',
    hasMembershipFeeTrue: 'Sim',
    hasMembershipFeeFalse: 'Não',
    netValueCents: 'Valor líquido',
    totalValueCents: 'Valor total',
  },
  product: 'Produto',
  group: 'Grupo',
  logsLabel: 'Histórico',
  logs: {
    title: 'Título',
    description: 'Descrição',
    createdAt: 'Data de criação',
    loading: 'Buscando histórico da fatura...',
    notFound: 'Nenhum histórico de fatura encontrado...',
  },
  movements: 'Movimentações',
  movementsIsEmpty: 'Nenhuma movimentação encontrada...',
}

const invoiceStatus = {
  STATUS_UNSPECIFIED: 'Não especificado',
  STATUS_DRAFT: 'Racunho',
  STATUS_UNPAID: 'Não pago',
  STATUS_PAID: 'Pago',
  STATUS_PARTIALLY_PAID: 'Parcialmente pago',
  STATUS_OVERDUE: 'Atrasado',
  STATUS_VOID: 'Cancelado/Descartado',
  STATUS_RETURNED: 'Reembolsado',
  STATUS_PARTIALLY_RETURNED: 'Parcialmente reembolsado',
}

const withdrawalStatus = {
  WITHDRAWAL_PENDING: 'Solicitado',
  WITHDRAWAL_TAX_FEE: 'Taxa de saque',
  WITHDRAWAL_SUCCEEDED: 'Realizado',
  WITHDRAWAL_FAILED: 'Falhou',
  WITHDRAWAL_CANCELED: 'Cancelado',
}

const transactionSource = {
  available: {
    SALE: 'Venda realizada',
    SALE_RELEASED: 'Valor da venda liberado para saque',
    RENEWAL: 'Renovação realizada',
    RENEWAL_RELEASED: 'Valor da renovação liberado para saque',
    UPGRADE: 'Upgrade de plano realizado',
    UPGRADE_RELEASED: 'Valor do upgrade plano liberado para saque',
    WITHDRAWAL_PENDING: 'Saque solicitado',
    WITHDRAWAL_TAX_FEE: 'Taxa de saque',
    WITHDRAWAL_SUCCEEDED: 'Saque efetuado com sucesso',
    WITHDRAWAL_FAILED_AFTER_SUCCEEDED: 'Saque rejeitado',
    WITHDRAWAL_FAILED: 'Saque rejeitado',
    WITHDRAWAL_CANCELED: 'Saque cancelado',
    REFUND: 'Reembolso efetuado com sucesso',
    CHARGEBACK_DISPUTE_OPENED: 'Fatura contestada como chargeback',
    CHARGEBACK_ACCEPTED_AFTER_DISPUTE: 'Chargeback realizado após a disputa',
    CHARGEBACK_ACCEPTED_WITHOUT_DISPUTE: 'Chargeback realizado sem disputa',
    CHARGEBACK_REJECTED: 'Chargeback recusado',
    INTERNAL_TRANSFER: 'Transferência interna de valor',
    MIGRATED_BALANCE: 'Migração (saldo inicial)',
    RESERVED_BALANCE: 'Transferência de valor para reserva de segurança',
    RESERVED_BALANCE_RELEASED: 'Valor de reserva de segurança liberado para saque',
  },
  receivable: {
    SALE: 'Venda realizada',
    SALE_RELEASED: 'Valor da venda liberado para saque',
    RENEWAL: 'Renovação realizada',
    RENEWAL_RELEASED: 'Valor da renovação liberado para saque',
    UPGRADE: 'Upgrade de plano realizado',
    UPGRADE_RELEASED: 'Valor do upgrade plano liberado para saque',
    WITHDRAWAL_PENDING: 'Saque solicitado',
    WITHDRAWAL_TAX_FEE: 'Taxa de saque',
    WITHDRAWAL_SUCCEEDED: 'Saque efetuado com sucesso',
    WITHDRAWAL_FAILED_AFTER_SUCCEEDED: 'Saque rejeitado',
    WITHDRAWAL_FAILED: 'Saque rejeitado',
    WITHDRAWAL_CANCELED: 'Saque cancelado',
    REFUND: 'Reembolso efetuado com sucesso',
    CHARGEBACK_DISPUTE_OPENED: 'Fatura contestada como chargeback',
    CHARGEBACK_ACCEPTED_AFTER_DISPUTE: 'Chargeback realizado após a disputa',
    CHARGEBACK_ACCEPTED_WITHOUT_DISPUTE: 'Chargeback realizado sem disputa',
    CHARGEBACK_REJECTED: 'Chargeback recusado',
    INTERNAL_TRANSFER: 'Transferência interna de valor',
    MIGRATED_BALANCE: 'Migração (saldo inicial)',
    RESERVED_BALANCE: 'Transferência de valor para reserva de segurança',
    RESERVED_BALANCE_RELEASED: 'Valor de reserva de segurança liberado para saque',
  },
  contested: {
    CHARGEBACK_DISPUTE_OPENED: 'Fatura contestada como chargeback',
    CHARGEBACK_ACCEPTED_AFTER_DISPUTE: 'Chargeback realizado após a disputa',
    CHARGEBACK_ACCEPTED_WITHOUT_DISPUTE: 'Chargeback realizado sem disputa',
    CHARGEBACK_REJECTED: 'Chargeback recusado',
    INTERNAL_TRANSFER: 'Transferência interna de valor',
    MIGRATED_BALANCE: 'Migração (saldo inicial)',
  },
  reserved: {
    REFUND: 'Transferência de valor para reembolso',
    RESERVED_BALANCE: 'Transferência de valor para reserva de segurança',
    RESERVED_BALANCE_RELEASED: 'Valor de reserva de segurança liberado para saque',
    INTERNAL_TRANSFER: 'Transferência interna',
  },
}

const recurrent = {
  monthly: 'mês',
  quarterly: 'três meses',
  semiannually: 'seis meses',
  annually: '1 ano',
}

const withdrawalRequestModal = {
  title: 'Solicitar saque',
  description:
    'A transferência será realizada via PIX e o valor estará disponível em sua conta em alguns minutos. Uma taxa de R$ 3,00 será cobrada pela transferência.',
  fields: {
    value: 'Valor',
  },
  availableBalance: 'Saldo disponível para saque: {{balance}}',
  valueExceeded: 'Valor excedeu o saldo disponível',
  transfer: 'Transferir {{value}}',
  internalError: 'Ocorreu um erro interno ao tentar realizar o saque, entre em contato com o suporte.',
}

const exportMovementsModal = {
  title: 'Exportar planilha',
  successMessage: `A sua planilha está sendo gerada e será enviada no email {{email}} quando estiver pronta. Esta ação pode demorar de 5-10min dependendo do período de data selecionado.`,
  conflictErrorMessage:
    'Já existe uma exportação em andamento para o período selecionado. Verifique seu email. Caso não tenha recebido, entre em contato com o suporte.',
  generalErrorMessage: 'Erro ao exportar a planilha. Entre em contato com o suporte.',
  fields: {
    filterByDate: {
      label: 'Filtrar por data',
      options: {
        last7days: 'Últimos 7 dias',
        last15days: 'Últimos 15 dias',
        last30days: 'Últimos 30 dias',
        last60days: 'Últimos 60 dias',
        last90days: 'Últimos 90 dias',
      },
    },
  },
  buttons: {
    export: 'Exportar',
    cancel: 'Cancelar',
    getIt: 'Entendido',
  },
}

const movementsGrid = {
  today: 'Hoje',
  of: 'de',
  perPage: 'por página',
  headers: {
    icon: '',
    date: 'Data',
    invoiceId: 'ID da fatura',
    description: 'Descrição',
    releaseDate: 'Data de liberação',
    value: 'Valor',
    balance: 'Saldo',
  },
  headersHint: {
    invoiceId: 'Identificador único da fatura criada.',
    description: 'Descrição',
    releaseDate: 'Data prevista para a liberação',
  },
  transactionDescription: {
    SELL: 'Venda',
    RENEWAL: 'Renovação',
    UPGRADE: 'Upgrade de plano',
    WITHDRAWAL_PENDING: 'Saque solicitado',
    WITHDRAWAL_TAX_FEE: 'Taxa de saque',
    WITHDRAWAL_SUCCEEDED: 'Saque efetuado com sucesso',
    WITHDRAWAL_FAILED_AFTER_SUCCEEDED: 'Saque rejeitado',
    WITHDRAWAL_FAILED: 'Saque rejeitado',
    WITHDRAWAL_CANCELED: 'Saque cancelado',
    REFUND: 'Reembolso',
    CHARGEBACK_DISPUTE_OPENED: 'Disputa de contestação aberta',
    CHARGEBACK_ACCEPTED_AFTER_DISPUTE: 'Chargeback realizado após a disputa',
    CHARGEBACK_ACCEPTED_WITHOUT_DISPUTE: 'Chargeback realizado sem disputa',
    CHARGEBACK_REJECTED: 'Chargeback rejeitado',
    INTERNAL_TRANSFER: 'Transferência interna',
    MIGRATED_BALANCE: 'Migração (saldo inicial)',
  },
  transactionHint: {
    SELL: '',
    RENEW: '',
    UPGRADE: '',
    WITHDRAWAL_PENDING:
      'Esse processo pode levar até 3 dias úteis, clique aqui para ver o histórico da sua solicitação.',
    WITHDRAWAL_TAX_FEE: '',
    WITHDRAWAL_SUCCEEDED: '',
    WITHDRAWAL_FAILED_AFTER_SUCCEEDED: '',
    WITHDRAWAL_FAILED: '',
    WITHDRAWAL_CANCELED: '',
    REFUND: '',
    CHARGEBACK_DISPUTE_OPENED: '',
    CHARGEBACK_ACCEPTED_AFTER_DISPUTE:
      'Quando um cliente contesta uma transação e o valor é devolvido ao comprador pelo emissor do cartão após disputa',
    CHARGEBACK_ACCEPTED_WITHOUT_DISPUTE:
      'Quando um cliente contesta uma transação e o valor é devolvido ao comprador pelo emissor do cartão',
    CHARGEBACK_REJECTED:
      'Emissor do cartão negou o pedido de chargeback do cliente e o dinheiro voltou para sua carteira',
    INTERNAL_TRANSFER: 'Transferência de saldo de uma conta Hubla para outra',
    MIGRATED_BALANCE: 'Esse valor corresponde ao seu saldo inicial quando o extrato da Hubla foi criado',
  },
}

const bankAccount = {
  ownershipTitle: 'Titularidade da conta',
  ownershipSubtitle: 'Informações sobre o titular da conta.',
  businessConfigurationTitle: 'Configurações de pagamento',
  businessConfigurationSubtitle: 'Veja e edite as configurações de pagamento da sua conta.',
  pixKeySubtitle: 'Chave PIX cadastrada para recebimento de saques.',
  pixKey: 'Chave ({{keyType}})',
  softDescriptor: 'Descrição na fatura do cartão',
  softDescriptorTooltip: 'A descrição que aparecerá na fatura do cartão do seu cliente.',
  fullName: 'Nome Completo',
  email: 'E-mail',
  phoneNumber: 'Telefone',
  bankAccountTitle: 'Dados bancários',
  bankAccountSubtitle: 'Informações bancárias cadastradas',
  updateBankAccount: 'Alterar dados bancários',
  bank: 'Banco',
  agency: 'Agência',
  account: 'Conta',
  accountType: 'Tipo de conta',
  documentTypeTag: {
    0: 'Não especificado',
    1: 'Pessoa jurídica (CNPJ)',
    2: 'Pessoa física (CPF)',
    3: 'Pessoa física (CPF)',
    100: 'Não declarado',
  },
  documentTypeEnum: {
    0: 'Não especificado',
    1: 'CNPJ',
    2: 'CPF',
    3: 'Passaporte',
    100: 'Não declarado',
  },
  bankAccountTypeEnum: {
    0: 'Não especificado',
    1: 'Corrente',
    2: 'Poupança',
  },
  statusTag: {
    0: 'Não especificado',
    1: 'Verificado',
    2: 'Falha ao ativar',
    3: 'Verificando dados...',
    default: 'Pendente',
  },
}

const ratesAndTimelines = {
  title: 'Taxas e prazos atuais',
  subtitle: 'Condições de taxas e prazos atuais da conta na plataforma.',
  rates: 'Taxa',
  settlementDays: 'Prazo de recebimento',
  settlementDaysFor: {
    creditCard: '{{days}} dias (Cartão de crédito)',
    pix: '{{days}} dias (Pix)',
  },
  securityReserveTitle: 'Reserva de segurança para cobrir reembolsos e chargebacks',
  securityReserveDescription: '{{currentPercent}}% por 30 dias. <0>Saiba mais</0>',
  ratesText: '{{commission}}% + {{transactionFee}} por venda aprovada',
  featureRatesText: {
    0: '',
    1: '+ {{transactionFee}} por venda de produtos com grupo',
  },
  creditCardSettlementDays: 'Cartão de Crédito: {{days}} dias',
  boletoSettlementDays: 'Boleto: {{days}} dias',
  pixSettlementDays: 'PIX: {{days}} dias',
}

const errors = {
  general: 'Algo deu errado, tente novamente mais tarde.',
  required: 'Campo obrigatório.',
}

export const pt = {
  title: 'Financeiro',
  subtitle: 'Acesse suas informações financeiras, como taxas, saldo e extrato',
  financialStatement: 'Extrato',
  save: 'Salvar',
  balances,
  filters,
  invoicePaymentMethod,
  invoiceDetails,
  invoiceStatus,
  transactionSource,
  recurrent,
  withdrawalRequestModal,
  withdrawalDetails,
  withdrawalStatus,
  exportMovementsModal,
  movementsGrid,
  bankAccount,
  ratesAndTimelines,
  errors,
}

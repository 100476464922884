import { TemporaryBusinessResponse } from '@hub-la/fe-get-user'
import { FormSteps } from '../presentation/pages/account-verification-modal'

export class IsStepCompleted {
  private account?: TemporaryBusinessResponse

  constructor(account?: TemporaryBusinessResponse) {
    this.account = account
  }

  public execute(step: FormSteps): boolean {
    switch (step) {
      case FormSteps.COMPANY_DATA:
        return !!this.account?.businessIdentity?.identity?.document?.type
      case FormSteps.WITHDRAW_INFO:
        return !!this.account?.businessIdentity?.payout?.isPixKey || !!(
          this.account?.businessIdentity?.payout?.bankAccount.bankNumber &&
          this.account?.businessIdentity?.payout?.bankAccount.accountType &&
          this.account?.businessIdentity?.payout?.bankAccount.agencyNumber &&
          this.account?.businessIdentity?.payout?.bankAccount.accountNumber &&
          this.account?.businessIdentity?.payout?.bankAccount.accountCheckNumber
        )
      case FormSteps.PERSONAL_INFO:
        return !!(
          this.account?.businessIdentity?.identity?.fullName &&
          this.account?.businessIdentity?.identity?.email &&
          this.account?.businessIdentity?.identity?.phone &&
          this.account?.businessIdentity?.identity?.document?.value &&
          this.account?.businessIdentity?.identity?.document.countryCode &&
          this.account?.businessIdentity?.identity?.billingAddress?.postalCode &&
          this.account?.businessIdentity?.identity?.billingAddress.neighborhood &&
          this.account?.businessIdentity?.identity?.billingAddress.street &&
          this.account?.businessIdentity?.identity?.billingAddress.number &&
          this.account?.businessIdentity?.identity?.billingAddress.city &&
          this.account?.businessIdentity?.identity?.billingAddress.state
        )
      case FormSteps.PUBLIC_INFO:
        return !!this.account?.businessIdentity?.businessConfiguration?.creditCard?.softDescriptor
      case FormSteps.VERIFY_IDENTITY:
        return !!this.account?.sdkToken
      default:
        return false
    }
  }
}

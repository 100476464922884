import { IFile, Uploader } from '@hub-la/fe-asset'
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@hub-la/shadcn'
import { ImageIcon, PlaySquare, PlusCircle } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { YoutubeObject } from '../../../../domain/dtos/youtube-object'
import { GetYoutubeUrlModal } from './get-youtube-url-modal'

type Props = {
  productId: string
  onImageInput: (file?: IFile) => void
  onYoutubeInput: (youtubeObject: YoutubeObject) => void
  disabled?: boolean
}

export const InputMedia: React.FC<Props> = ({ onImageInput, onYoutubeInput, productId, disabled }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [youtubeModalOpen, setYoutubeModalOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <div className="h-fit">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" disabled={disabled} loading={loading}>
            <PlusCircle className="w-4 h-4 mr-2" />
            {t('settings.productPage.media.input.add')}
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent>
          <DropdownMenuItem>
            <Uploader
              name="asset"
              path={productId}
              setLoading={setLoading}
              onChange={(file) => {
                onImageInput(file)
              }}
            >
              <div className="flex items-center">
                <ImageIcon className="w-4 h-4 mr-2" />

                <span>Imagem</span>
              </div>
            </Uploader>
          </DropdownMenuItem>

          <DropdownMenuItem onSelect={() => setYoutubeModalOpen(true)}>
            <div className="flex items-center">
              <PlaySquare className="w-4 h-4 mr-2" />

              <span>YouTube</span>
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <GetYoutubeUrlModal
        open={youtubeModalOpen}
        onClose={() => setYoutubeModalOpen(false)}
        onSubmit={onYoutubeInput}
      />
    </div>
  )
}

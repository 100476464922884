import { ImageUploader } from "@hub-la/fe-asset"
import { AlignCenterVertical, AlignStartVertical, Palette } from "lucide-react"
import { Controller } from "react-hook-form"
import { useParams } from "react-router-dom"
import { CustomCollapsible } from "../../../../../components/custom-collapsible"
import { Tabs, TabsList, TabsTrigger } from "@hub-la/shadcn"
import { useFeatureFlag } from '@hub-la/fe-core-feature-flag'
import { useTranslation } from "react-i18next"
import { LogoPosition } from "libs/frontend/modules/offer/src/lib/domain/enums/logo-position.enum"
import { ColorPicker } from "../../../../../components/color-picker"
import { CtaCustomizationOptions } from "./cta-customization-options"

interface LogoPositionSelectorProps {
  onChange: (theme: LogoPosition) => void
  value: LogoPosition
}

export const LogoPositionSelector = (props: LogoPositionSelectorProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.customization.logo.label')}</h3>
      <Tabs
        value={props.value}
        onValueChange={(value) => props.onChange(value as LogoPosition)}
        className="max-w-max"
      >
        <TabsList className="flex">
          <TabsTrigger value={LogoPosition.LEFT}>
            <AlignStartVertical className="h-4 w-4" />
          </TabsTrigger>
          <TabsTrigger value={LogoPosition.CENTER}>
            <AlignCenterVertical className="h-4 w-4" />
          </TabsTrigger>
        </TabsList>
      </Tabs >
      <span className="text-sm text-muted-foreground">
        {t(`offer.checkoutTab.customization.logo.${props.value}`)}
      </span>
    </div >
  )
}

export const AppearenceCollapsible = () => {
  const { t } = useTranslation()

  const { offerId, productId } = useParams<{ productId: string, offerId: string }>()

  const isCheckoutV4EnabledToOffer = useFeatureFlag().isFlagEnabled('isCheckoutV4EnabledToOffer', {
    offerId
  })

  return (
    <CustomCollapsible defaultOpen title={t(`offer.checkoutTab.customization.appearence.title`)} Icon={Palette}>
      <div className="grid gap-4">
        <div className="flex justify-between">
          <div>
            <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.customization.logo.label')}</h3>
            <p className="text-sm text-muted-foreground">{t('offer.checkoutTab.customization.logo.description')}</p>
          </div>
          <Controller
            name="checkout.creatorLogoUrl"
            render={({ field }) => (
              <ImageUploader
                name={field.name}
                path={`/checkout/${productId}`}
                className="w-24 h-14"
                value={field.value}
                onChange={(file) => field.onChange(file?.URL)}
              />
            )}
          />
        </div>
        {isCheckoutV4EnabledToOffer &&
          <>
            <Controller
              name="checkout.logoPosition"
              render={({ field }) => (
                <LogoPositionSelector
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <div>
              <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.customization.appearence.bgColor')}</h3>
              <ColorPicker name="checkout.bgColor" />
            </div>
            <CtaCustomizationOptions />
          </>
        }
      </div>
    </CustomCollapsible>
  )
}

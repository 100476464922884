import { AccountDropdownProvider } from '@hub-la/fe-account-dropdown'
import { AccountVerificationBanner } from '@hub-la/fe-account-verification'
import { container } from '@hub-la/fe-container'
import { useRoleplay } from '@hub-la/fe-roleplay'
import { useIsMobile } from '@hub-la/shadcn'
import React, { useCallback, useEffect, useState } from 'react'
import { AppHeader } from './app-header'
import { MenuSidebar } from './menu-sidebar'
import { useHistory } from 'react-router-dom'

type Props = {
  children: React.ReactNode
  hideSidebar?: boolean
  disablePadding?: boolean
  disableAppHeader?: boolean
}

export const DashboardContainer: React.FC<Props> = ({ children, hideSidebar, disablePadding, disableAppHeader }) => {
  const [toggleSidebar, setToggleSidebar] = useState(() => localStorage.getItem('toggleSidebar') === 'true')
  const { isRoleplay } = useRoleplay()
  const isMobile = useIsMobile()
  const history = useHistory()

  useEffect(() => {
    localStorage.setItem('toggleSidebar', String(toggleSidebar))
  }, [toggleSidebar])

  const handleToggleSidebar = useCallback((value: boolean) => {
    setToggleSidebar(value)
  }, [])

  return (
    <div className="flex min-h-screen">
      {!disableAppHeader && (
        <AccountDropdownProvider container={container}>
          <AppHeader isToggleSidebar={toggleSidebar} onToggleSidebar={handleToggleSidebar} hideSidebar={hideSidebar} />
        </AccountDropdownProvider>
      )}

      {!isMobile && !hideSidebar && <MenuSidebar isToggleSidebar={toggleSidebar} />}

      <main
        className={`
        flex-grow w-full bg-container-background
        ${isRoleplay ? 'mt-[92px]' : disableAppHeader ? '' : 'mt-[52px]'}
      `}
      >
        {!isRoleplay && history.location.pathname.includes('dashboard') ? <AccountVerificationBanner /> : null}

        <div
          className={`
          mx-auto
          ${disablePadding ? 'pt-2 sm:pt-3' : 'container p-6 sm:p-10'}
        `}
        >
          {children}
        </div>
      </main>
    </div>
  )
}

import { Input } from '@hub-la/shadcn'
import { Search } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ConfirmExportModal } from '../../components/confirm-export-modal'
import { SelectOffers } from '../../components/select-offers'
import { SelectStatus } from '../../components/select-status'
import { SelectDateRangeCalendar } from './select-date-range-calendar'

export const Filters = ({ setFilters, filters, offers, isLoading }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col md:flex-row justify-between gap-3">
      <div className="flex flex-col w-full md:w-auto md:flex-row flex-wrap gap-3">
        <div className="relative h-10">
          <Search className="absolute w-4 h-4 right-3 top-1/2 transform -translate-y-1/2 text-muted-foreground z-10" />
          <Input
            type="text"
            placeholder={t('search')}
            className="md:w-[250px] w-full"
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value, page: 1 })}
          />
        </div>
        <SelectOffers
          offers={offers}
          value={filters.offerIds}
          onChange={(value) => setFilters((prevState) => ({ ...prevState, offerIds: value, page: 1 }))}
          isLoading={isLoading}
        />
        <SelectDateRangeCalendar
          value={filters}
          onChange={(value) => setFilters((prevState) => ({ ...prevState, ...value }))}
          onSelectChange={(value) => setFilters((prevState) => ({ ...prevState, dateRangeBy: value }))}
          selectValue={filters.dateRangeBy}
          onClearDate={() =>
            setFilters((prevState) => ({
              ...prevState,
              dateRangeBy: 'createdAt',
              startDate: undefined,
              endDate: undefined,
            }))
          }
        />
        <SelectStatus
          value={filters.statuses}
          onChange={(value) => setFilters((prevState) => ({ ...prevState, statuses: value, page: 1 }))}
        />
      </div>
      <div className="flex flex-col justify-between w-full md:w-auto md:flex-row md:justify-end gap-3">
        <div>
          <ConfirmExportModal filters={filters} offers={offers} />
        </div>
      </div>
    </div>
  )
}

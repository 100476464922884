import { useContainer } from '@hub-la/fe-container'
import { Auth } from '@hub-la/fe-core-auth'
import Cookies from 'js-cookie'
import { createContext, useContext, useEffect } from 'react'
import { Envs } from '../envs'
import { useAutoAuthenticateSession } from '../hooks/use-auto-authenticate-session'

const AuthContext = createContext({})

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AutoAuthProvider = (props) => {
  const container = useContainer()
  const auth = container.get(Auth)
  useAutoAuthenticateSession()

  useEffect(() => {
    auth.listen(async (currentUser) => {
      if (!currentUser) return

      const idToken = await currentUser?.getIdToken(true)
      Cookies.set('__session', idToken, {
        expires: 15,
        domain: Envs.COOKIE_DOMAIN,
        secure: true,
        sameSite: 'strict',
      })
    })
  }, [auth])

  return <AuthContext.Provider value={{}}>{props.children}</AuthContext.Provider>
}

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { CartesianGrid, Line, LineChart, ReferenceLine, XAxis, YAxis } from 'recharts'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { formatDate } from '../../../../domain/vos/format-date'
import { Money } from '../../../../domain/vos/money'
import { GetIndexOfToday } from '../../../../usecases/get-index-of-today'
import { InfoTooltip } from '../../../components/info-tooltip'
import { StatusBox } from '../../../components/status-box'
import { useGetRevenue } from '../../../hooks/use-get-revenue'
import { useRefetch } from '../../../hooks/use-refetch'
import { Loading } from './loading'

export const Revenue = ({ filters, offers, isLoading }) => {
  const { period, startDate, endDate, offerIds } = filters
  const { t } = useTranslation()

  const { data, isFetching, refetch } = useGetRevenue({ period, startDate, endDate, offers, offerIds }, isLoading)

  useRefetch({ period, startDate, endDate, offers, offerIds }, refetch)

  if (isFetching || isLoading) {
    return <Loading />
  }

  const chartConfig = {
    current: {
      label: 'Período atual',
      color: 'hsl(var(--chart-1))',
      opacity: 1,
    },
    previous: {
      label: 'Período anterior',
      color: 'hsl(var(--chart-2))',
      opacity: 1,
    },
  }

  const values = data.datasets
    .at(0)
    ?.data.map((item) => Math.max(item.y || 0, item.comparative || 0))
    .filter((item) => item !== null)

  const maxValue = values && values?.length > 0 ? Math.max(...values) : 0

  const yAxisUpperLimit = maxValue > 0 ? Math.ceil(maxValue * 1.1) : 1

  const indexOfToday = new GetIndexOfToday().execute({ datasets: data.datasets, period })

  return (
    <div className="w-full h-full">
      <Card className="flex flex-col gap-8">
        <CardHeader className="pb-0 space-y-0">
          <div className="flex items-center gap-2">
            <p className="text-sm font-normal text-muted-foreground">{t('revenue.title')}</p>
            <InfoTooltip title={t('revenue.description')} />
          </div>
          <CardDescription className="flex flex-col gap-2">
            <h3 className="font-semibold text-4xl tracking-tight text-primary">
              {Money.build(data.current).getValue()}
            </h3>
            <StatusBox
              indicator={data.indicator}
              status={data.status}
              description={`${data.percentage} ${t('variation', {
                previous: Money.build(data.previous).getValue(),
              })}`}
            />
          </CardDescription>
        </CardHeader>

        <CardContent className="px-0">
          <ChartContainer config={chartConfig} className="h-[290px] px-6">
            <LineChart width={600} height={290} data={data.datasets.at(0)?.data}>
              <CartesianGrid stroke="hsl(var(--border))" strokeOpacity={0.5} vertical={false} />
              <XAxis
                key="date"
                dataKey="date"
                type="category"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                allowDuplicatedCategory={false}
                tickFormatter={(value: string) => formatDate(value, period)}
                interval="preserveStartEnd"
              />

              <YAxis hide key="y" dataKey="y" type="number" domain={[0, yAxisUpperLimit]} allowDataOverflow={false} />

              <ChartTooltip
                content={
                  <ChartTooltipContent
                    className="w-56 bg-card rounded-lg p-2 shadow-lg"
                    labelFormatter={(value: string) => formatDate(value, period, 'D MMM. YYYY')}
                    valueFormatter={(value: string) => formatCurrency(Number(value))}
                  />
                }
              />

              {indexOfToday && (
                <ReferenceLine x={indexOfToday} stroke="#74796F" strokeDasharray="6 6" strokeWidth={1} />
              )}

              <Line
                dataKey="comparative"
                type="monotoneX"
                name={chartConfig['previous'].label}
                stroke={chartConfig['previous'].color}
                strokeOpacity={chartConfig['previous'].opacity}
                strokeWidth={2}
                dot={false}
              />

              <Line
                dataKey="y"
                type="monotoneX"
                name={chartConfig['current'].label}
                stroke={chartConfig['current'].color}
                strokeOpacity={chartConfig['current'].opacity}
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  )
}

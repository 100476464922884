import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { useRoleplay } from '@hub-la/fe-roleplay'
import { useSignOut } from '@hub-la/fe-sign-out'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@hub-la/shadcn'
import { DropdownMenuLabel } from '@radix-ui/react-dropdown-menu'
import Cookies from 'js-cookie'
import { ChevronDown, Info, LogOut, Pen, SendToBack, Shapes, ShoppingBag, Undo, Users } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Envs } from '../../envs'
import { getAppHost, getSiteUrl, isAtSite, routes } from '../../utils/routes'
import avatar from '../assets/default-user-avatar.png'
import { useContainer } from '../hooks/use-container'

export const AccountDropdown: React.FC<any> = ({ push, id, firstName, picture, username, bankAccount }) => {
  const { t } = useTranslation()
  const { execute: signOut } = useSignOut()
  const { signOut: signOutRoleplay, isRoleplay } = useRoleplay()
  const container = useContainer()
  const analytics = container.get(Analytics)
  const events = AnalyticsEvent.MAIN_MENU
  const userPicture = picture || String(avatar)

  const onClickMenuLink = (route: string, event: string): void => {
    analytics.track(event)

    if (route.startsWith('/')) {
      // When is internal route push to the route
      push(route.replace(window.location.origin, ''))
    } else {
      window.location.href = route
    }
  }

  const onClickSignOut = async (): Promise<void> => {
    if (isRoleplay) {
      await signOutRoleplay()
    }
    signOut().then(() => {
      analytics.track(events.LOGOUT_CLICKED)
      isAtSite() && window.location.replace(`${getAppHost()}/logout`)
    })
  }

  const onClickHandleSelectAccount = async (): Promise<void> => {
    if (isRoleplay) {
      await signOutRoleplay()
    }
    push(routes.ROLEPLAY)
  }

  const getUsername = () => {
    return (username && username.length > 1 ? username : id) ?? ''
  }

  const leaveNewExperience = () => {
    Cookies.set('new_experience', 'false', {
      expires: 365,
      domain: Envs.COOKIE_DOMAIN,
      sameSite: 'lax',
    })

    const url = new URL(window.location.href)
    const hostnameParts = url.hostname.split('.')

    // Check if the current subdomain is `app`
    if (hostnameParts[0] === 'app2') {
      // Change the subdomain to `app2`
      hostnameParts[0] = 'app'
    } else {
      // Add `app2` as the subdomain
      hostnameParts.unshift('app')
    }
    url.hostname = hostnameParts.join('.')

    // Check if the current path contains "dashboard"
    if (url.pathname.includes('dashboard')) {
      url.pathname = '/dashboard'
    } else {
      url.pathname = '/user_groups'
    }

    try {
      ;(window as any).Appcues?.track('leave_new_experience')
      console.log('Left new experience')
    } catch (e) {
      console.error('Appcues is not setup up correctly')
    }

    // Redirect to the new URL in 300ms
    setTimeout(() => {
      window.location.href = url.toString()
    }, 300)
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative px-1 rounded-full">
          <ChevronDown className="h-4 w-4 mr-1" />
          <Avatar className="h-8 w-8">
            <AvatarImage src={userPicture} alt={firstName} className="object-cover" />
            <AvatarFallback>{firstName ? firstName?.charAt(0) : 'U'}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-64" align="end" forceMount>
        <DropdownMenuLabel className="font-semibold">
          <a href={getSiteUrl(`/${username}`)} className="text-base font-semibold leading-none truncate">
            <DropdownMenuItem
              onClick={() => onClickMenuLink(getSiteUrl(`/${username}`), events.EDIT_PROFILE_CLICKED)}
              className="flex p-1.5 items-center gap-1 justify-between cursor-pointer"
            >
              {firstName && firstName.length > 1
                ? `${firstName.split(' ').at(0)} ${firstName.split(' ').length > 1 ? firstName.split(' ').at(1) : ''}`
                : t('dropdown.header.showProfile')}
              <Button
                variant="ghost"
                className="p-1 h-fit z-[60]"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onClickMenuLink(routes.SETTINGS, events.EDIT_PROFILE_CLICKED)
                }}
              >
                <Pen className="h-4 w-4" />
              </Button>
            </DropdownMenuItem>
          </a>
        </DropdownMenuLabel>
        <DropdownMenuGroup className="space-y-1">
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => onClickMenuLink(routes.DASHBOARD, events.PRODUCTS_CLICKED)}
            className="cursor-pointer"
          >
            <Shapes className="mr-2 h-4 w-4" />
            <span>{t('dropdown.links.dashboard')}</span>
          </DropdownMenuItem>
          {!isRoleplay && (
            <DropdownMenuItem
              onClick={() => onClickMenuLink(routes.PURCHASES, events.PURCHASES_CLICKED)}
              className="cursor-pointer"
            >
              <ShoppingBag className="mr-2 h-4 w-4" />
              <span>{t('dropdown.links.purchases')}</span>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            onClick={() => {
              analytics.track(events.SUPPORT_CLICKED)
              window.open(routes.SUPPORT)
            }}
            className="cursor-pointer"
          >
            <Info className="mr-2 h-4 w-4" />
            <span>{t('dropdown.links.support')}</span>
          </DropdownMenuItem>
          {!isRoleplay && (
            <DropdownMenuItem
              onClick={() => onClickMenuLink(routes.COLLABORATORS, events.COLLABORATORS_CLICKED)}
              className="cursor-pointer"
            >
              <Users className="mr-2 h-4 w-4" />
              <span>{t('dropdown.links.collaborators')}</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
        <DropdownMenuSeparator className="my-1" />
        <DropdownMenuItem onClick={onClickHandleSelectAccount} className="cursor-pointer">
          <Undo className="mr-2 h-4 w-4" />
          <span>{t('dropdown.links.selectAccount')}</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator className="my-1" />
        <DropdownMenuItem onClick={leaveNewExperience} className="cursor-pointer" id="back-to-old-experience">
          <SendToBack className="mr-2 h-4 w-4" />
          <span>Voltar para experiência anterior</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={onClickSignOut} className="cursor-pointer">
          <LogOut className="mr-2 h-4 w-4" />
          <span>{t('dropdown.links.logout')}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

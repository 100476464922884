import { Input, Label } from "@hub-la/shadcn"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ColorPicker } from "../../../../../components/color-picker"
import { InstallmentsSelect } from "../../../../../components/installments-select"
import { SwitchToggleChildren } from "../../../../../components/switch-toggle-children"
import { OfferSchemaValidationType } from "../../../../../validations/offer-validation"

export const CtaCustomizationOptions = () => {
  const { t } = useTranslation()

  const { watch, setValue } = useFormContext<OfferSchemaValidationType>()

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name !== "checkout.isCustomCta") {
        return
      }
      if (!value.checkout?.isCustomCta) {
        setValue('checkout.purchaseButtonText', '')
        setValue('checkout.purchaseButtonBgColor', '')
        setValue('checkout.purchaseButtonTextColor', '')
      }
      if (value.checkout?.isCustomCta) {
        setValue('checkout.purchaseButtonTextColor', '#FFFFFF')
        setValue('checkout.purchaseButtonBgColor', '#FFFFFF')
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <div className="space-y-4">
      <SwitchToggleChildren name="checkout.isCustomCta" label={t('offer.checkoutTab.customization.appearence.cta.label')}>
        <div className="space-y-4">
          <div>
            <Label>{t('offer.checkoutTab.customization.appearence.cta.bgColor')}</Label>
            <ColorPicker name="checkout.purchaseButtonBgColor" />
          </div>
          <div>
            <Label>{t('offer.checkoutTab.customization.appearence.cta.text')}</Label>
            <Controller
              name="checkout.purchaseButtonText"
              render={({ field }) => <Input value={field.value} onChange={field.onChange} />}
            />
          </div>
          <div>
            <Label>{t('offer.checkoutTab.customization.appearence.cta.textColor')}</Label>
            <ColorPicker name="checkout.purchaseButtonTextColor" />
          </div>
        </div>
      </SwitchToggleChildren>
    </div>
  )
}

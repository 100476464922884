import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from "@hub-la/shadcn"
import { useGetOffers } from '../../hooks/use-get-offers'
import { CreateRefundRequestModal } from './components/create-refund-request-modal'
import { InstructionRules } from './components/instruction-rules'
import { RefundTable } from './components/refund-table'
import { AppHeader } from './components/app-header'

export const UserRefundPage = () => {
  const { t } = useTranslation()
  const { data: offers = [], isLoading, isFetching } = useGetOffers()

  const [showCreateRefundRequestModal, setShowCreateRefundRequestModal] = useState(false)

  return (
    <div className="flex flex-col container gap-4">
      <AppHeader />
      <div className="pb-10 px-6 lg:px-0 mx-auto">
        <div className="flex flex-col space-y-8">
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-4 sm:space-y-0">
            <h1 className="text-3xl font-bold">{t('userRefund.title')}</h1>
            <Button
              disabled={!offers.length}
              onClick={() => setShowCreateRefundRequestModal(true)}
            >
              {isLoading || isFetching ? (
                <span className="animate-spin mr-2">&#8987;</span>
              ) : null}
              {t('userRefund.buttons.createRefund')}
            </Button>
          </div>
          <RefundTable offers={offers} />
          <InstructionRules />
        </div>
        <CreateRefundRequestModal
          offers={offers}
          isOpen={showCreateRefundRequestModal}
          isLoading={isLoading || isFetching}
          onClose={() => setShowCreateRefundRequestModal(false)}
        />
      </div>
    </div >
  )
}

import { Button } from '@hub-la/shadcn'
import { FileDown, X } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookAccount } from '../../../domain/dtos/book-accounts'
import { TransactionSource } from '../../../domain/dtos/get-moviments.dto'
import { TimeRangePicker } from '../../components/time-range-picker/time-range-picker'
import { TransactionSourceFilter, useTransactionSourcesFilter } from '../../hooks/use-transaction-sources-filter'

export type SelectedFilters = {
  accountType: BookAccount
  startDate: string | null
  endDate: string | null
  transactionSources: TransactionSource[]
}

type FiltersProps = {
  filters: SelectedFilters
  setFilters: (filters: SelectedFilters) => void
  onExportMovements: () => void
}

export const Filters: React.FC<FiltersProps> = ({ filters, setFilters, onExportMovements }) => {
  const { t } = useTranslation()
  const [localFilters, setLocalFilters] = React.useState(filters)

  const handleFilterChange = React.useCallback((filter: Partial<SelectedFilters>) => {
    setLocalFilters((prevFilters) => ({ ...prevFilters, ...filter }))
  }, [])

  const handleDateRange = React.useCallback(
    (from: string, to: string) => {
      handleFilterChange({ startDate: from, endDate: to })
    },
    [handleFilterChange],
  )

  const {
    transactionSourceFilters,
    handleSourceSelect: originalHandleSourceSelect,
    handleSourceUnselect: originalHandleSourceUnselect,
    isSourceSelected,
  } = useTransactionSourcesFilter(localFilters.transactionSources, handleFilterChange, localFilters.accountType)

  const handleSourceSelect = React.useCallback(
    (filter: TransactionSourceFilter) => {
      originalHandleSourceSelect(filter)
      const updatedSources = [...localFilters.transactionSources, ...filter.values]
      handleFilterChange({ transactionSources: updatedSources })
    },
    [originalHandleSourceSelect, localFilters.transactionSources, handleFilterChange],
  )

  const handleSourceUnselect = React.useCallback(
    (filter: TransactionSourceFilter) => {
      originalHandleSourceUnselect(filter)
      const updatedSources = localFilters.transactionSources.filter((source) => !filter.values.includes(source))
      handleFilterChange({ transactionSources: updatedSources })
    },
    [originalHandleSourceUnselect, localFilters.transactionSources, handleFilterChange],
  )

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setFilters(localFilters)
    }, 100)

    return () => clearTimeout(timer)
  }, [localFilters, setFilters])

  return (
    <div className="flex flex-row justify-between items-center my-6 space-x-4 overflow-x-auto overflow-y-hidden">
      <div className="flex flex-row gap-2 items-center">
        <TimeRangePicker
          onChangeFromTo={handleDateRange}
          initialRange={{ from: localFilters.startDate ?? '', to: localFilters.endDate ?? '' }}
        />

        {transactionSourceFilters.map((filter: TransactionSourceFilter) => (
          <Button
            key={filter.id}
            data-testid={filter.id}
            variant={isSourceSelected(filter.id) ? 'default' : 'outline'}
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation()
              if (isSourceSelected(filter.id)) {
                handleSourceUnselect(filter)
              } else {
                handleSourceSelect(filter)
              }
            }}
          >
            {t(`filters.sources.${filter.id}`)}
            {isSourceSelected(filter.id) && (
              <X
                className="w-4 h-4 ml-1.5 hover:bg-primary-foreground/20 rounded-sm"
                onClick={(e) => {
                  e.stopPropagation()
                  handleSourceUnselect(filter)
                }}
              />
            )}
          </Button>
        ))}
      </div>

      <Button variant="outline" onClick={onExportMovements} className="whitespace-nowrap">
        <FileDown className="mr-2 h-4 w-4" />
        {t('filters.exportSpreadsheet')}
      </Button>
    </div>
  )
}

import { withAccountVerification } from '@hub-la/fe-account-verification'
import {
  Button,
  Card,
  Checkbox,
  cn,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Label,
} from '@hub-la/shadcn'
import { BookOpen, Check, ChevronDown, ChevronUp } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ReceiverStatus } from '../../../domain/enums/receiver-status'
import { useUpdateReceiverStatus } from '../../hooks/use-update-receiver-status'

type AcceptInviteProps = {
  receiverId: string
  termsText: string
  commission: number
  expiresAt?: string
  onChange?: () => void
}

const ButtonWithAccountVerification = withAccountVerification(Button, {
  tooltip: 'Você precisa ativar sua conta para aceitar uma co-produção',
})

export const AcceptInvite = ({ receiverId, termsText, commission, expiresAt, onChange }: AcceptInviteProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(false)

  const { t } = useTranslation()
  const { mutateAsync: updateStatus, isLoading } = useUpdateReceiverStatus()

  const handleAccept = async () => {
    await updateStatus({ id: receiverId, status: ReceiverStatus.ACCEPTED, termsText })
    onChange?.()
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <ButtonWithAccountVerification variant="outline" size="sm" onClick={() => setOpen(true)} disabled={isLoading}>
        <Check className="w-4 h-4 mr-1" />
        {t('partnerships.accept')}
      </ButtonWithAccountVerification>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('partnerships.acceptInvite.title')}</DialogTitle>
        </DialogHeader>
        <Card className="p-4">
          <div className="grid gap-2">
            <div className="grid grid-cols-3 items-center gap-4">
              <Label> Condição </Label>
              <p className="text-sm text-muted-foreground col-span-2">
                <Trans
                  i18nKey={'partnerships.acceptInvite.commission'}
                  values={{ commission }}
                  components={[<strong />]}
                  className="h-8"
                />
              </p>
            </div>
            <div className="grid grid-cols-3 items-center gap-4">
              <Label> Validade </Label>
              <p className="text-sm text-muted-foreground col-span-2">
                <Trans
                  i18nKey={expiresAt ? 'partnerships.acceptInvite.expiresAt' : 'partnerships.acceptInvite.noExpires'}
                  values={{ expiresAt: moment(expiresAt).format('D MMM. YYYY') }}
                  components={[<strong />]}
                />
              </p>
            </div>
            <div className="grid grid-cols-3 items-center gap-4">
              <Label> Observações </Label>
              <p className="text-sm text-muted-foreground col-span-2">{t('partnerships.acceptInvite.notes')}</p>
            </div>
          </div>
        </Card>

        <Card className="p-4">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => setShowDetails(!showDetails)}
          >
            <div className="flex items-center space-x-2">
              <BookOpen className="h-5 w-5" />
              <span className="text-sm">{t('partnerships.acceptInvite.terms')}</span>
            </div>

            {showDetails ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
          </div>

          {showDetails && (
            <div className="mt-4">
              <div dangerouslySetInnerHTML={{ __html: termsText }} />
            </div>
          )}
        </Card>

        <div className="flex items-center py-2 space-x-2">
          <Checkbox id="terms" checked={checked} onCheckedChange={(checked) => setChecked(checked as boolean)} />
          <label
            htmlFor="terms"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {t('partnerships.acceptInvite.label')}
          </label>
        </div>

        <DialogFooter className={cn('sm:space-x-4', 'flex flex-col-reverse sm:flex-row justify-end items-center')}>
          <Button variant="outline" onClick={() => setOpen(false)}>
            {t('partnerships.back')}
          </Button>

          <Button onClick={handleAccept} disabled={!checked || isLoading}>
            {isLoading ? t('Carregando...') : t('partnerships.accept')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

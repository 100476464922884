import { useIsMobile } from '@hub-la/design-system'
import { UploadAssetBySignedUrl, UploadStatus } from '@hub-la/fe-asset'
import { Card, CardContent, CardHeader } from '@hub-la/shadcn'
import { useQueryClient } from '@tanstack/react-query'
import { ChevronDown, ChevronUp, X } from 'lucide-react'
import { memo, useEffect, useState } from 'react'
import { QueryKey } from '../../../domain/dtos'
import { Video } from '../../../domain/dtos/video'
import { useVideoStatus } from '../../hooks/use-video-status'
import { useVideosUploader } from '../../hooks/use-videos-uploader'
import { EmptyState } from './empty-state'
import { Item } from './item'

const VideosUploader = memo(() => {
  const isMobile = useIsMobile()
  const queryClient = useQueryClient()
  const { videos } = useVideosUploader()
  const { getStatus, updateStatus } = useVideoStatus(videos)
  const hasVideos = videos.length
  const [expanded, setExpanded] = useState(true)
  const [showCard, setShowCard] = useState(!!hasVideos)
  const [uploading, setUploading] = useState({
    progress: 0,
    name: '',
  })

  const countVideosByStatus = (status: UploadStatus) => {
    return videos.filter((video) => status.includes(getStatus(video.file.name))).length
  }

  const countSuccessful = countVideosByStatus(UploadStatus.SUCCESS)
  const countFailed = countVideosByStatus(UploadStatus.ERROR)

  const toggle = () => setExpanded((expanded) => !expanded)
  const closed = () => setShowCard((opened) => !opened)

  const upload = async (video: Video) => {
    updateStatus(video.file.name, UploadStatus.PROCESSING)

    await new UploadAssetBySignedUrl().execute({
      onUploadProgress: (progress) => {
        setUploading({
          progress,
          name: video.file.name,
        })
      },
      writeUrl: video.writeUrl,
      file: video.file,
      mimeType: video.file.type,
      asset: video.asset,
    })

    updateStatus(video.file.name, UploadStatus.SUCCESS)
    await queryClient.refetchQueries([QueryKey.listSections])
  }

  const getTitle = () => {
    const empty = 'Nenhum arquivo sendo enviado'
    if (!videos.length) return empty

    const successfully = 'Upload concluído!'
    return videos.length === countSuccessful
      ? successfully
      : countFailed
      ? `${countSuccessful} vídeos enviados e ${countFailed} falharam...`
      : `Enviando ${countSuccessful + 1}/${videos.length} arquivos...`
  }

  useEffect(() => {
    window.onbeforeunload = hasVideos === countSuccessful ? null : () => true
  }, [hasVideos, countSuccessful])

  useEffect(() => {
    ;(async function () {
      if (!hasVideos) return
      setShowCard(true)

      for (const video of videos) {
        const status = getStatus(video.file.name)
        if (status === UploadStatus.NO_CALLED) {
          await upload(video)
        }
      }
    })()
  }, [videos])

  if (!showCard) return null

  return (
    <div
      className={`fixed bottom-0 z-20 ${isMobile ? 'w-full left-0' : 'w-[360px] left-12'}`}
      data-testid="videos-uploader"
    >
      <Card className="rounded-t-lg rounded-b-none border-b-0">
        <CardHeader className="p-3 border-b">
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium">{getTitle()}</span>

            <div className="flex items-center gap-3">
              {expanded ? (
                <ChevronDown className="h-5 w-5 cursor-pointer" onClick={toggle} />
              ) : (
                <ChevronUp className="h-5 w-5 cursor-pointer" onClick={toggle} />
              )}

              {hasVideos === countSuccessful && <X className="h-5 w-5 cursor-pointer" onClick={closed} />}
            </div>
          </div>
        </CardHeader>

        {expanded && (
          <CardContent className="p-0 max-h-[250px] overflow-auto">
            {hasVideos ? (
              videos.map((video) => (
                <Item
                  status={getStatus(video.file.name)}
                  key={video.file.name}
                  name={video.file.name}
                  progress={uploading.name === video.file.name ? uploading.progress : 0}
                />
              ))
            ) : (
              <EmptyState />
            )}
          </CardContent>
        )}
      </Card>
    </div>
  )
})

export { VideosUploader }

import { useHasAccountsAccess } from '@hub-la/fe-roleplay'
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from '../presentation/hooks/use-auth'

export const AuthRedirectContext = createContext<{
  redirectAutoIfAuthenticated: () => void
  isFirstLoading: boolean
  parentLoaded: boolean
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  redirectAutoIfAuthenticated: () => {},
  isFirstLoading: true,
  parentLoaded: false,
})

type AuthRedirectProviderProps = {
  children: ReactNode
}

const navigationTargetRoute = {
  buyer: '/user_groups',
  creator: '/dashboard',
}

const AuthRedirectProvider = ({ children }: AuthRedirectProviderProps) => {
  const history = useHistory()
  const location = useLocation()
  const { currentUser, isAuthenticated } = useAuth()
  const { isFetched, refetch, data: hasAccountsAccessData, isError } = useHasAccountsAccess({ enabled: false })

  const [selectUserLogin, setSelectUserLogin] = useState(false)
  // prevent multiple redirects because sdk remount and useEffect trigger redirectAutoIfAuthenticated
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [isFirstLoading, setFirstLoading] = useState(true)

  const redirectAutoIfAuthenticated = useCallback(async () => {
    if (!currentUser) {
      setFirstLoading(false)
      return
    }

    if (isError) {
      setFirstLoading(false)
      return setSelectUserLogin(true)
    }

    const { data: hasAccountsAccess } = isFetched ? { data: hasAccountsAccessData } : await refetch()

    const params = new URLSearchParams(location.search)
    const redirectTo = params.get('redirectTo')

    if (redirectTo && redirectTo !== 'null' && redirectTo !== 'undefined' && !isRedirecting) {
      // todo: check redirect to is hubla url, to avoid redirect to external urls
      setIsRedirecting(true)
      document.title = 'Hubla'
      // redirect with window to change metatags and remount providers
      window.location.href = redirectTo
      return
    }

    if (hasAccountsAccess && !isRedirecting) {
      setIsRedirecting(true)
      document.title = 'Hubla'
      history.push('/roleplay')
      return
    }

    const navigationTarget = currentUser?.loginPreferences?.navigationTarget
    const navigationTargetRoutePath = navigationTargetRoute[navigationTarget]

    if (navigationTargetRoutePath && !isRedirecting) {
      setIsRedirecting(true)
      document.title = 'Hubla'
      history.push(navigationTargetRoutePath)
      return
    }

    if (selectUserLogin) return
    setFirstLoading(false)
    // Select navigation target page
    history.push('/')
    return setSelectUserLogin(true)
  }, [currentUser, isError])

  useEffect(() => {
    if (isAuthenticated === null) return

    setFirstLoading(isAuthenticated)
  }, [isAuthenticated])

  useEffect(() => {
    if (!isAuthenticated || !currentUser) {
      return
    }

    redirectAutoIfAuthenticated()
  }, [isAuthenticated, currentUser])

  return (
    <AuthRedirectContext.Provider value={{ redirectAutoIfAuthenticated, isFirstLoading, parentLoaded: true }}>
      {children}
    </AuthRedirectContext.Provider>
  )
}

export { AuthRedirectProvider }

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Input, Label, Separator, Tabs, TabsContent } from '@hub-la/shadcn'
import { X } from 'lucide-react'
import { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useGetProduct } from '../../hooks/use-get-product'
import { cohortSchemaValidation, CohortSchemaValidationType } from '../../validations/cohort-validation'
import { AccessesContent } from './accesses/accesses-content'
import { AccessesPreview } from './accesses/accesses-preview'

type CohortPageProps = {
  isEdit?: boolean
  isLoading?: boolean
  productId: string
  defaultValues?: CohortSchemaValidationType
  onSubmit: (data) => void
}

export const CohortForm: React.FC<CohortPageProps> = ({ isEdit, productId, defaultValues, onSubmit, isLoading }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const { data: product } = useGetProduct(productId)

  const form = useForm<CohortSchemaValidationType>({
    defaultValues: {
      name: '',
      sections: [],
      groups: [],
    },
    resolver: yupResolver(cohortSchemaValidation),
    mode: 'onChange',
  })
  const { isValid, isDirty } = form.formState

  useEffect(() => {
    form.reset(defaultValues)
  }, [form, defaultValues])

  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab') || 'accesses'

  const updateTabParam = (newTab: string) => {
    searchParams.set('tab', newTab)
    history.push({ search: searchParams.toString() })
  }

  const tabContent = {
    accesses: <AccessesContent productId={productId} mainOfferId={product?.mainOfferId} />,
  }

  const tabPreview = {
    accesses: <AccessesPreview productId={productId} mainOfferId={product?.mainOfferId} />,
  }

  return (
    <FormProvider {...form}>
      <form className="min-h-screen" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="p-4 flex justify-between items-center border-b  bg-background">
          <div className="flex items-center">
            <Button size="icon" variant="ghost" onClick={() => history.goBack()}>
              <X className="h-4 w-4" />
            </Button>

            <div className="hidden sm:flex gap-4 items-center">
              <Separator orientation="vertical" className="min-h-5" />

              <h1 className="text-sm font-medium">{isEdit ? t('cohort.header.edit') : t('cohort.header.create')}</h1>
            </div>
          </div>

          <Button type="submit" disabled={!isValid || !isDirty} loading={isLoading}>
            {isEdit ? t('cohort.header.edit') : t('cohort.header.create')}
          </Button>
        </div>

        <div className="min-h-[calc(100vh-75px)] flex flex-col md:grid md:grid-cols-5">
          <div className="px-6 py-8 flex md:col-span-2 md:pl-24 md:pr-12 flex-col gap-4">
            <h2 className="text-xl font-semibold">{isEdit ? t('cohort.title.edit') : t('cohort.title.new')}</h2>

            <Controller
              name="name"
              render={({ field: { ...register }, fieldState }) => (
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">{t('cohort.fields.name.label')}</Label>

                  <Input
                    {...register}
                    id="name"
                    placeholder={t('cohort.fields.name.placeholder')}
                    className={fieldState.error?.type ? 'border-red-500' : ''}
                  />

                  {fieldState.error?.type && (
                    <p className="text-red-500 text-sm">{t(`fieldErrors.${fieldState.error?.type}`)}</p>
                  )}
                </div>
              )}
            />

            <Tabs value={tab} onValueChange={updateTabParam}>
              <TabsContent value="accesses">{tabContent[tab]}</TabsContent>
            </Tabs>
          </div>

          <div className="px-6 py-6 min-w-80 md:col-span-3 background border-l ">{tabPreview[tab]}</div>
        </div>
      </form>
    </FormProvider>
  )
}

import { Alert, AlertDescription, AlertTitle, Button, Input, Label } from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import { AlertCircle, CheckCircle, Loader2 } from 'lucide-react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { AuthCard } from '../../components/auth-card/auth-card'
import { AuthLayout } from '../../components/auth-layout'
import { MetaTags } from '../../components/meta-tags'
import { PasswordField } from '../../components/password-field'
import { PasswordStatus } from '../../components/password-status'
import { useGetRecoverPassword } from '../../hooks/use-get-recover-password'
import { usePutRecoverPassword } from '../../hooks/use-put-recover-password'
import { recoverPasswordChangeSchema } from '../../validations/recover-password-change'

const RecoverPasswordChange = ({ token }: { token: string }) => {
  const { isError, data, isLoading } = useGetRecoverPassword({ token })
  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const {
    mutate,
    isSuccess: isSuccessChangePassword,
    error,
    isError: putRecoverPasswordIsError,
    reset,
    isLoading: putRecoverPasswordIsLoading,
  } = usePutRecoverPassword()

  const form = useFormik({
    initialValues: {
      email: data?.email,
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: async (values) => {
      const captcha = await executeRecaptcha?.('recover_password_change')

      mutate({
        password: values.password,
        token,
        captcha,
      })
    },
    validationSchema: recoverPasswordChangeSchema,
  })

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <Loader2 className="h-16 w-16 animate-spin text-primary-foreground" />
      </div>
    )
  }

  if (isError) {
    return <RecoverPasswordError />
  }

  if (isSuccessChangePassword) {
    return <RecoverPasswordSuccess />
  }

  return (
    <AuthCard
      showTerms={false}
      title="Redefina sua senha"
      description="Redefina sua senha para continuar acessando sua conta"
    >
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit} className="space-y-4 w-full">
          <div className="space-y-2">
            <Label htmlFor="email">E-mail</Label>
            <Input id="email" type="email" name="email" disabled value={data?.email} />
          </div>
          <PasswordField
            name="password"
            label="Senha"
            onChange={form.handleChange}
            value={form.values.password}
            error={form.touched.password && !!form.errors.password}
            helperText={form.touched.password && !!form.errors.password ? t(form.errors.password) : null}
            autoComplete="new-password"
          />
          <PasswordField
            name="passwordConfirmation"
            label="Confirmar senha"
            onChange={form.handleChange}
            value={form.values.passwordConfirmation}
            error={form.touched.passwordConfirmation && !!form.errors.passwordConfirmation}
            helperText={
              form.touched.passwordConfirmation && !!form.errors.passwordConfirmation
                ? t(form.errors.passwordConfirmation)
                : null
            }
            autoComplete="new-password"
          />
          <PasswordStatus passwordValue={form.values.password} />
          <Button type="submit" className="w-full" loading={putRecoverPasswordIsLoading}>
            Redefinir senha
          </Button>
        </form>
      </FormikProvider>
      {putRecoverPasswordIsError && (
        <Alert variant="destructive" className="mt-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Erro</AlertTitle>
          <AlertDescription>{t((error as Error)?.message)}</AlertDescription>
        </Alert>
      )}
    </AuthCard>
  )
}

const RecoverPasswordError = () => {
  return (
    <AuthCard
      showTerms={false}
      icon={<AlertCircle className="h-16 w-16 text-yellow-500" />}
      title="Link expirado"
      description={
        <>
          O link para redefinir sua senha expirou, solicite novamente.
          <RouterLink to="/recover-password">
            <Button className="w-full">Solicitar novamente</Button>
          </RouterLink>
        </>
      }
    />
  )
}

const RecoverPasswordSuccess = () => {
  return (
    <AuthCard
      showTerms={false}
      icon={<CheckCircle className="h-16 w-16 text-green-500" />}
      title="Senha redefinada com sucesso"
      description="Sua senha foi redefina com sucesso, agora realize o login para acessar sua conta."
    >
      <RouterLink to="/signin">
        <Button className="w-full">Realizar login</Button>
      </RouterLink>
    </AuthCard>
  )
}

const RecoverPasswordToken = () => {
  const { token } = useParams<{ token: string }>()

  return (
    <AuthLayout showTerms={false}>
      <MetaTags />
      <RecoverPasswordChange token={token} />
    </AuthLayout>
  )
}

export { RecoverPasswordToken }

import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'

import { useQuery } from '@tanstack/react-query'
import { ListPartnershipsInput } from '../../domain/dtos/list-parnerships-input'
import { QueryKey } from '../../domain/enums/query-key'
import { ListPartnerships } from '../../usecases/list-partnerships'

export const useListPartnerships = (input: ListPartnershipsInput) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.listPartnerships, input.page, input.pageSize],
    ({ signal }) => new ListPartnerships(container.get(HttpClient)).execute(input, signal),
    {
      refetchOnWindowFocus: false,
      retry: 3,
    },
  )
}

import { CopyIcon } from '@radix-ui/react-icons'
import { Check } from 'lucide-react'
import * as React from 'react'
import { cn } from '../utils'
import { Button, ButtonProps } from './button'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './tooltip'

export interface CopyClipboardButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  copyContent: string
  label?: string
  labelClassName?: string
  size?: ButtonProps['size']
  tooltipCopiedLabel: string
  tooltipCopyLabel: string
}

const CopyClipboardButton = React.forwardRef<HTMLButtonElement, CopyClipboardButtonProps>(
  ({
    className = '',
    copyContent,
    disabled,
    label = '',
    labelClassName = '',
    tooltipCopiedLabel,
    tooltipCopyLabel,
    onClick,
    size = 'sm',
    ...props
  }) => {
    const [contentCopied, setContentCopied] = React.useState(false)
    const [isTooltipVisible, setIsTooltipVisible] = React.useState(false)

    const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled) {
        return
      }

      navigator.clipboard.writeText(copyContent)

      setContentCopied(true)
      setIsTooltipVisible(true)

      setTimeout(() => {
        setContentCopied(false)
        setIsTooltipVisible(false)
      }, 2000)

      if (onClick) {
        onClick(event)
      }
    }

    return (
      <TooltipProvider>
        <Tooltip open={isTooltipVisible} onOpenChange={setIsTooltipVisible}>
          <TooltipTrigger asChild>
            <Button
              {...props}
              size={size}
              variant="secondary"
              className={cn('h-7 gap-2 text-sm', className)}
              onClick={(e) => {
                e.stopPropagation()
                handleCopy(e)
              }}
            >
              {label && (
                <span className={cn('flex-1 max-w-36 font-normal truncate', labelClassName)} onClick={handleCopy}>
                  {label}
                </span>
              )}

              {contentCopied ? (
                <Check className="h-4 w-4 text-green-600" />
              ) : (
                <CopyIcon className="h-4 w-4 text-muted-foreground" />
              )}
            </Button>
          </TooltipTrigger>

          <TooltipContent>
            <p>{contentCopied ? tooltipCopiedLabel : tooltipCopyLabel}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  },
)

CopyClipboardButton.displayName = 'CopyClipboardButton'

export { CopyClipboardButton }

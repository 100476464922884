import { useGetUser } from '@hub-la/fe-get-user'
import { Alert, AlertDescription, AlertTitle, Button, Input, Label } from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import { useContext, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Trans, useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import { Link as RouterLink } from 'react-router-dom'
import { EmailAlreadyExistsError } from '../../../domain/errors/email-already-exists-error'
import { AuthRedirectContext } from '../../../providers/auth-redirect-provider'
import { usePostCompleteRegistration } from '../../hooks/use-post-complete-registration'
import { completeRegistrationSchema } from '../../validations/complete-registration'
import { AuthCard } from '../auth-card/auth-card'
import { PasswordField } from '../password-field'
import { PasswordStatus } from '../password-status'
import { CompleteRegistrationVerifyCard } from './complete-registration-verify-card'

const CompleteRegistrationCard = () => {
  const [showVerifyCode, setShowVerifyCode] = useState<boolean>(false)
  const [showSkipButton, setShowSkipButton] = useState<boolean>(false)

  const { t } = useTranslation()
  const { redirectAutoIfAuthenticated } = useContext(AuthRedirectContext)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { data: userRefData, isLoading: isLoadingUserRef } = useGetUser()
  const { error, mutate, isLoading, data, isError, reset } = usePostCompleteRegistration({
    onSuccess: () => {
      setShowVerifyCode(true)
    },
    onError: () => {
      setShowSkipButton(true)
    },
  })

  const form = useFormik({
    initialValues: {
      email: userRefData?.email || '',
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: async (values) => {
      const captcha = await executeRecaptcha?.('complete_registration')

      mutate({ ...values, captcha })
    },
    validationSchema: completeRegistrationSchema,
    enableReinitialize: true,
  })

  const isAlreadyExistsEmail = Boolean(error) && error instanceof EmailAlreadyExistsError
  const emailErrorMessage = isAlreadyExistsEmail
    ? error?.message
    : form.touched.email && !!form.errors.email
    ? form.errors.email
    : null

  if (showVerifyCode)
    return (
      <CompleteRegistrationVerifyCard
        email={form.values.email}
        password={form.values.password}
        authId={data?.authId}
        retryCode={async () => {
          const captcha = await executeRecaptcha?.('complete_registration')

          mutate({ ...form.values, captcha })
        }}
        loadingRetry={isLoading}
        nextRetryDate={data?.nextRetryDate}
      />
    )

  return (
    <AuthCard
      showTerms={false}
      title="Complete seu cadastro"
      description="Crie a sua senha para continuar acessando sua conta"
    >
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit} className="space-y-4 w-full">
          <div className="space-y-2">
            <Label htmlFor="email">E-mail</Label>
            <Input
              id="email"
              name="email"
              type="email"
              autoComplete="off"
              onChange={form.handleChange}
              value={form.values.email}
              className={isAlreadyExistsEmail || (form.touched.email && !!form.errors.email) ? 'border-red-500' : ''}
              disabled={!!userRefData?.email || isLoadingUserRef}
            />
            {emailErrorMessage && (
              <p className="text-sm text-red-500">
                <Trans
                  i18nKey={emailErrorMessage}
                  components={[<RouterLink to="/signin" className="text-primary hover:underline" />]}
                />
              </p>
            )}
          </div>
          <PasswordField
            autoComplete="new-password"
            name="password"
            label="Senha"
            onChange={form.handleChange}
            value={form.values.password}
            error={form.touched.password && !!form.errors.password}
            helperText={form.touched.password && !!form.errors.password ? t(form.errors.password) : null}
          />
          <PasswordField
            autoComplete="new-password"
            name="passwordConfirmation"
            label="Confirmar senha"
            onChange={form.handleChange}
            value={form.values.passwordConfirmation}
            error={form.touched.passwordConfirmation && !!form.errors.passwordConfirmation}
            helperText={
              form.touched.passwordConfirmation && !!form.errors.passwordConfirmation
                ? t(form.errors.passwordConfirmation)
                : null
            }
          />
          <PasswordStatus passwordValue={form.values.password} />
          <Button type="submit" className="w-full" loading={isLoading}>
            Criar senha
          </Button>
          {showSkipButton && (
            <Button variant="outline" className="w-full" onClick={() => redirectAutoIfAuthenticated()} type="button">
              Pular essa etapa
            </Button>
          )}
        </form>
      </FormikProvider>
      {isError && (
        <Alert variant="destructive" className="mt-4">
          <AlertTitle>Erro</AlertTitle>
          <AlertDescription>{t((error as Error)?.message)}</AlertDescription>
        </Alert>
      )}
    </AuthCard>
  )
}

export { CompleteRegistrationCard }

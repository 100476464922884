import { Button, Card, CardContent, Separator } from '@hub-la/shadcn'
import { FilePlus, Info, X } from 'lucide-react'
import moment from 'moment'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { UpgradePaymentMethods } from '../../../../domain/dtos/payment-method'
import { SubscriptionStatus } from '../../../../domain/enums/subscription-status'
import { formatCurrencyWithInstallments } from '../../../../domain/vos/format-currency'
import { MapLrError } from '../../../../usecases/map-lr-error'
import inactive_order from '../../../assets/inactive_order.svg'
import { useGetPendingInvoiceForSubscription } from '../../../hooks/use-get-pending-invoice-for-subscription'

type Props = {
  subscriptionId?: string
  cancelEta?: string
  status?: SubscriptionStatus
}

const Header = ({ icon, content }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-2 items-center">
        {icon}
        <span className="text-sm">{content}</span>
      </div>
      <Separator />
    </div>
  )
}

export const PendingInvoiceCard: React.FC<Props> = (props) => {
  const { subscriptionId, cancelEta, status } = props
  const { data: pendingInvoice } = useGetPendingInvoiceForSubscription(subscriptionId)
  const { t } = useTranslation()
  const history = useHistory()

  const renders = {
    shouldRenderLrCode: (
      <Header
        icon={<X className="text-red-500" />}
        content={
          <Trans
            i18nKey="pendingInvoiceCard.overdueCard"
            components={[<strong />, <span className="text-sm text-muted-foreground" />]}
            values={{ reason: new MapLrError().execute(pendingInvoice?.lrCode ?? 'default') }}
          />
        }
      />
    ),
    unpaidPixLabel: (
      <Header
        icon={<FilePlus className="text-blue-500" />}
        content={
          <Trans
            i18nKey="pendingInvoiceCard.unpaidPix"
            components={[<strong />, <span className="text-sm text-muted-foreground" />]}
          />
        }
      />
    ),
    overduePixLabel: (
      <Header
        icon={<Info className="text-red-500" />}
        content={
          <Trans
            i18nKey="pendingInvoiceCard.overduePix"
            components={[<strong />, <span className="text-sm text-muted-foreground" />]}
            values={{ cancelEta: moment(cancelEta).format('D [de] MMM. YYYY') }}
          />
        }
      />
    ),
    inactivePixSubscription: (
      <Header
        icon={<X className="text-red-500" />}
        content={
          <Trans
            i18nKey="pendingInvoiceCard.inactivePixSubscription"
            components={[<strong />, <span className="text-sm text-muted-foreground" />]}
          />
        }
      />
    ),
    default: <></>,
  }

  const getHeader = () => {
    switch (true) {
      case pendingInvoice?.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_CARD &&
        pendingInvoice.type === 'overdue':
        return renders.shouldRenderLrCode
      case pendingInvoice?.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_PIX &&
        pendingInvoice.type === 'unpaid':
        return renders.unpaidPixLabel
      case pendingInvoice?.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_PIX &&
        pendingInvoice.type === 'overdue' &&
        status === SubscriptionStatus.ACTIVE:
        return renders.overduePixLabel
      case pendingInvoice?.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_PIX &&
        pendingInvoice.type === 'overdue' &&
        status === SubscriptionStatus.INACTIVE:
        return renders.inactivePixSubscription
      default:
        return renders.default
    }
  }

  if (!pendingInvoice) return null

  return (
    <Card className="w-full">
      <CardContent className="p-6">
        <div className="flex flex-col gap-4">
          {getHeader()}

          <div className="flex flex-row items-start justify-between">
            <div className="flex flex-col gap-2 sm:gap-0.5">
              <span className="text-muted-foreground">
                {t('pendingInvoiceCard.product', {
                  productName: pendingInvoice.items[0].description,
                })}
              </span>
              <div className="flex flex-col-reverse sm:flex-row gap-0 sm:gap-2 items-start sm:items-center">
                <h2 className="text-2xl font-bold">
                  {formatCurrencyWithInstallments(
                    pendingInvoice.installmentsAmount,
                    pendingInvoice.priceInCents / 100,
                    pendingInvoice.installmentsPriceInCents / 100,
                  )}
                </h2>
              </div>
              <span className="text-muted-foreground">
                {t(`pendingInvoiceCard.date.${pendingInvoice.type}`)}{' '}
                {moment(pendingInvoice.dueDate).format('D MMM. YYYY HH:mm')}
              </span>
            </div>
            <img src={inactive_order} alt="inactive_order" className="w-16 h-16" />
          </div>
          <Separator />
          <div className="flex flex-col gap-2">
            <Button
              className="w-full"
              onClick={() =>
                window.open(`${process.env['INVOICE_PAYMENT_URL']}/${pendingInvoice?.invoiceId}`, '_blank')
              }
            >
              {t('pendingInvoiceCard.actions.pay')}
            </Button>
            <Button
              variant="outline"
              className="w-full"
              onClick={() => history.push(`/user_invoices/${pendingInvoice?.invoiceId}/pdf`)}
            >
              {t('pendingInvoiceCard.actions.download')}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

import React from 'react'

interface DottedDividerProps {
  height?: number
}

export const DottedDivider: React.FC<DottedDividerProps> = ({ height = 2 }) => {
  return (
    <div
      className="my-4 border-b border-dashed "
      style={{ borderBottomWidth: `${height}px` }}
      data-testid="dotted-divider"
    />
  )
}

import { Card, cn, Switch, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  PaymentMethodItem,
  paymentMethodsFieldOptions,
} from '../../../../../domain/constants/payment-methods-field-options'
import { OfferSchemaValidationType } from '../../../../validations/offer-validation'

interface CheckboxTooltipProps {
  isDisabled: boolean
  paymentMethod: PaymentMethodItem
  tooltip?: string
  checked: boolean
}

const CheckboxTooltip: React.FC<CheckboxTooltipProps> = ({ isDisabled, paymentMethod, tooltip, checked }) => {
  const { control } = useFormContext()

  const renderSwitch = ({ field }) => (
    <Switch
      checked={isDisabled ? false : checked}
      type="button"
      onCheckedChange={(isChecked) => {
        const needOne = checked && field.value.length <= 1
        if (isDisabled || needOne) return

        if (isChecked) {
          field.onChange([...field.value, paymentMethod.name])
        } else {
          field.onChange(field.value.filter((value) => value !== paymentMethod.name))
        }
      }}
      disabled={isDisabled}
    />
  )

  if (!tooltip) {
    return <Controller control={control} name="checkout.paymentMethods" render={renderSwitch} />
  }

  return (
    <Tooltip>
      <TooltipTrigger className="h-6" disabled>
        <Controller control={control} name="checkout.paymentMethods" render={renderSwitch} />
      </TooltipTrigger>
      <TooltipContent>
        <p>{tooltip}</p>
      </TooltipContent>
    </Tooltip>
  )
}

export const PaymentMethodSelect: React.FC = () => {
  const { t } = useTranslation()
  const { control } = useFormContext<OfferSchemaValidationType>()

  const pricingPriceType = useWatch({
    control,
    name: 'pricing.priceType',
    defaultValue: 'one_time',
  })

  const paymentMethodsSelected = useWatch({
    control,
    name: 'checkout.paymentMethods',
    defaultValue: [],
  })

  return (
    <TooltipProvider>
      <Card>
        {paymentMethodsFieldOptions.map((paymentMethod) => {
          const needOneOption =
            paymentMethodsSelected.length <= 1 && paymentMethodsSelected.includes(paymentMethod.name)
          const paymentMethodNotAllow = !paymentMethod.priceTypeAllowed.includes(pricingPriceType)

          const tooltipMessage = paymentMethodNotAllow
            ? t('offer.checkoutTab.paymentMethods.priceTypeNotAllowed', {
              priceType: t(`priceType.${pricingPriceType}`),
              paymentMethod: t(`paymentMethod.${paymentMethod.name}`),
            })
            : needOneOption
              ? t('offer.checkoutTab.paymentMethods.disabledWarn')
              : ''

          return (
            <div
              key={paymentMethod.name}
              className={cn(
                'flex justify-between items-center p-3.5',
                paymentMethodNotAllow ? 'bg-muted' : '',
                paymentMethod === paymentMethodsFieldOptions[paymentMethodsFieldOptions.length - 1] ? '' : 'border-b'
              )}
            >
              <div className="flex items-center">
                {paymentMethod.icon}
                <span className="ml-3">{t(`paymentMethod.${paymentMethod?.name}`)}</span>
              </div>

              <CheckboxTooltip
                paymentMethod={paymentMethod}
                tooltip={tooltipMessage}
                isDisabled={paymentMethodNotAllow}
                checked={paymentMethodsSelected.includes(paymentMethod.name)}
              />
            </div>
          )
        })}
      </Card>
    </TooltipProvider>
  )
}

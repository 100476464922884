import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import {
  Badge,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  useToast,
} from '@hub-la/shadcn'
import times from 'lodash/times'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MakeInviteUrl } from '../../../../usecases/members/make-invite-url'
import { GenericConfirmModal } from '../../../components/generic-confirm-modal'
import { useGetPendingInvites } from '../../../hooks/members/use-get-pending-invites'
import { usePutCancelInvite } from '../../../hooks/members/use-put-cancel-invite'
import { SkeletonRow } from '../participants-table/participants-table'
import { Actions } from './actions'
import { RowInviteActions } from './row-invite-actions'

export const SekeletonRow = () => (
  <TableRow>
    <TableCell>
      <Skeleton className="w-4/5 h-6" />
    </TableCell>

    <TableCell>
      <Skeleton className="w-[130px] h-6" />
    </TableCell>

    <TableCell>
      <Skeleton className="w-[120px] h-6" />
    </TableCell>

    <TableCell>
      <div className="flex justify-end gap-2">
        <Skeleton className="w-6 h-6" />
        <Skeleton className="w-6 h-6" />
      </div>
    </TableCell>
  </TableRow>
)

export const InvitesTable = () => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const { productId } = useParams<{ productId: string }>()

  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)
  const { hasPermission } = useRoleplay()

  const [openCancelInviteModal, setOpenCancelInviteModal] = useState<{
    isOpen: boolean
    inviteId: string
  }>({ isOpen: false, inviteId: '' })

  const { data: pendingInvites, isFetching } = useGetPendingInvites({
    productId,
    page,
    pageSize,
    search,
  })
  const { mutateAsync: cancelInvite, isLoading: cancelInviteLoading } = usePutCancelInvite({
    onSuccess: () => setOpenCancelInviteModal({ isOpen: false, inviteId: '' }),
  })

  const isEmpty = !pendingInvites?.items || pendingInvites?.items.length === 0
  const canRenderEmptyState = !isFetching && (pendingInvites?.items ?? []).length <= 0

  return (
    <div className="mt-3 flex flex-col gap-4">
      <Actions onSearchChange={(value) => setSearch(value)} />

      <p className="text-sm mb-3">{pendingInvites?.total} membros</p>

      <div className="bg-background rounded-lg border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t('tabs.invites.table.email')}</TableHead>

              <TableHead>{t('tabs.invites.table.status')}</TableHead>

              <TableHead>{t('tabs.invites.table.createdAt')}</TableHead>

              <TableHead></TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {isFetching
              ? times(3, (i) => <SkeletonRow key={`skeleton-row-${i}`} />)
              : (pendingInvites?.items ?? []).map((invite) => (
                  <TableRow key={invite?.id}>
                    <TableCell>{invite?.receiverEmail}</TableCell>

                    <TableCell>
                      <Badge variant="secondary">Pendente</Badge>
                    </TableCell>

                    <TableCell className="text-muted-foreground">
                      {invite?.createdAt ? moment(invite?.createdAt).format('D MMM. YYYY') : '–'}
                    </TableCell>

                    <TableCell>
                      <RowInviteActions
                        onCancelInvite={() =>
                          setOpenCancelInviteModal({
                            isOpen: true,
                            inviteId: invite.id,
                          })
                        }
                        onCopyInviteLink={() => new MakeInviteUrl().execute(invite?.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        {canRenderEmptyState && <TableEmptyState title={t('participants.emptyState')} />}
      </div>

      {hasPermission(PermissionResource.PRODUCTS_MANAGE_MEMBERS) && (
        <GenericConfirmModal
          title={t('tabs.invites.cancelInviteModal.title')}
          description={t('tabs.invites.cancelInviteModal.description')}
          onDelete={() => cancelInvite({ inviteId: openCancelInviteModal.inviteId })}
          onCancel={() => setOpenCancelInviteModal({ isOpen: false, inviteId: '' })}
          isOpen={openCancelInviteModal.isOpen}
          confirmText={t('tabs.invites.cancelInviteModal.confirmText')}
          isLoading={cancelInviteLoading}
        />
      )}

      {!isEmpty && (
        <TablePagination
          page={page}
          setPage={setPage}
          lastPage={pendingInvites?.lastPage ?? 1}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  )
}

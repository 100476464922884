import { FirebaseStorage, getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { IFile } from '../domain/dtos'

export class UploadFileToFirebaseStorage {
  private root: ReturnType<typeof ref>
  private storage: FirebaseStorage

  constructor() {
    this.storage = getStorage()
    this.root = ref(this.storage, 'private')
  }

  public async upload(file: File, path: string, onLoad?: (n: number) => void): Promise<IFile> {
    return new Promise((resolve, reject) => {
      /** Add a file to firebase */
      const fileRef = ref(this.root, `${path}/${file.name}`)
      const uploadTask = uploadBytesResumable(fileRef, file)

      /**
       * @function on handles all the callbacks in firebase.
       */
      uploadTask.on(
        'state_changed',

        onLoad
          ? (snapshot) => {
              /**
               * When there is progress update, the @callback next function will be called.
               * The upload progress equals (bytes transferred / file size)
               */
              onLoad((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            }
          : undefined,

        /* Handle error */
        (error) => reject(error),
        /**
         * On completion, we will pass the URL to the @callback complete
         */
        async () => {
          try {
            const URL = await getDownloadURL(uploadTask.snapshot.ref)
            resolve({
              name: file.name,
              URL,
            })
          } catch (error) {
            reject(error as Error)
          }
        },
      )
    })
  }
}

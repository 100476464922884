import { Badge, Button, Card, CardContent } from '@hub-la/shadcn'
import { Edit, GripVertical, Plus, Trash2 } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PlaceholderLogo from '../../../../../../../assets/placeholder-image.svg'
import { SwitchToggleChildren } from '../../../../../../../components/switch-toggle-children'
import { MultipleOrderBumpModal } from './multiple-order-bump-modal'
import { OfferSchemaValidationType } from '../../../../../../../validations/offer-validation'
import { DragDropContext, Draggable, Droppable, DroppableProps } from 'react-beautiful-dnd'
import { formatLocalNumber } from 'libs/frontend/modules/offer/src/lib/domain/vos/format-local-number'

const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))
    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])
  if (!enabled) {
    return null
  }
  return <Droppable {...props}>{children}</Droppable>
}

export const MultipleOrderBumpSwitch: React.FC = () => {
  const { watch, resetField, setValue } = useFormContext<OfferSchemaValidationType>()

  const { t } = useTranslation()

  const [orderbumpModal, setOrderBumpModal] = useState({
    selectedId: '',
    isEdit: false,
    open: false,
  })

  const { append, move, update, remove } = useFieldArray({
    name: "checkout.orderbump.offers",
  });

  const orderBumps = watch('checkout.orderbump.offers')

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (value.checkout?.orderbump?.offers?.length === 0) {
        setValue('checkout.orderbump', null)
      }
      if (!value.checkout?.isOrderBump && name === "checkout.isOrderBump") {
        setValue('checkout.orderbump', null)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const onDragEnd = (result) => {
    if (!result.destination) return
    move(result.source.index, result.destination.index)
  }

  const onDelete = (index: number) => {
    remove(index)
  }

  const onEdit = (id: string) => {
    setOrderBumpModal({
      selectedId: id,
      open: true,
      isEdit: true
    })
  }

  const defaultValues = orderBumps?.find((orderBump) => orderBump.id === orderbumpModal.selectedId)

  const renderOfferNamePrice = (orderBump) => {
    let str = ''

    if (orderBump.price) {
      str += formatLocalNumber(orderBump.price)
    }

    if (orderBump.maxInstallment > 1) {
      str += ` em ate ${orderBump.maxInstallment}x `
    }

    if (orderBump.planinterval) {
      str += ` / ${t(`plansPerPeriod.${orderBump.planinterval}`)}`
    }

    return str
  }

  return (
    <>
      <MultipleOrderBumpModal
        open={orderbumpModal.open}
        isEditing={orderbumpModal.isEdit}
        orderBumps={orderBumps}
        onCancel={() => {
          if (!orderbumpModal.isEdit) {
            resetField('checkout.isOrderBump')
          }
          setOrderBumpModal({
            selectedId: '',
            isEdit: false,
            open: false,
          })
        }}
        onSubmit={(values) => {
          const index = orderBumps?.findIndex((orderBump) => orderBump.id === values.offerId)

          if (index >= 0) {
            update(index, {
              id: values.offerId,
              title: values.title,
              description: values.description,
              callToAction: values.callToAction,
              productExtraOfferId: values.productId,
              extraOfferName: values.offer?.name,
              planinterval: values.planInterval,
              price: values.price,
              maxInstallment: values.maxInstallments,
              extraOfferPicture: values.product?.picture
            })
          } else {
            append({
              id: values.offerId,
              title: values.title,
              description: values.description,
              callToAction: values.callToAction,
              productExtraOfferId: values.productId,
              extraOfferName: values.offer?.name,
              planinterval: values.planInterval,
              price: values.price,
              maxInstallment: values.maxInstallments,
              extraOfferPicture: values.product?.picture
            })
          }

          setOrderBumpModal({
            selectedId: '',
            isEdit: false,
            open: false,
          })
        }}
        defaultValues={defaultValues}
        actionSave={
          orderbumpModal.isEdit
            ? t('offer.checkoutTab.options.orderBump.edit')
            : t('offer.checkoutTab.options.orderBump.add')
        }
        actionCancel={
          orderbumpModal.isEdit
            ? t('offer.checkoutTab.options.orderBump.cancel')
            : t('offer.checkoutTab.options.orderBump.close')
        }
      />
      <SwitchToggleChildren name="checkout.isOrderBump" label={t('offer.checkoutTab.options.orderBump.title')}>
        <div className="space-y-4">
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="products">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                  {orderBumps?.map((orderBump, index) => (
                    <Draggable key={orderBump.id} draggableId={orderBump.id} index={index}>
                      {(provided) => (
                        <Card ref={provided.innerRef} {...provided.draggableProps} className="bg-background">
                          <CardContent className="flex items-center p-4">
                            <div {...provided.dragHandleProps} className="mr-4">
                              <GripVertical className="text-muted-foreground" />
                            </div>
                            <div className="flex flex-1 items-center gap-3">
                              <img
                                src={orderBump?.extraOfferPicture ?? PlaceholderLogo}
                                alt="Product logo"
                                className="w-[70px] h-[46px] object-cover rounded"
                              />
                              <div className="text-sm">
                                <p>{orderBump?.extraOfferName}</p>
                                <span>{renderOfferNamePrice(orderBump)}</span>
                              </div>
                            </div>
                            <div className="flex items-center gap-3">
                              <Button variant="ghost" size="icon" onClick={() => onEdit(orderBump.id)} type="button">
                                <Edit className="h-4 w-4" />
                              </Button>
                              <Button variant="ghost" size="icon" onClick={() => onDelete(index)} type="button">
                                <Trash2 className="h-4 w-4" />
                              </Button>
                            </div>
                          </CardContent>
                        </Card>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
          <Button variant="outline" onClick={() => setOrderBumpModal({ isEdit: false, open: true, selectedId: '' })} type="button">
            <Plus className="mr-2 h-4 w-4" />
            {t('offer.checkoutTab.options.orderBump.add')}
          </Button>
        </div>
      </SwitchToggleChildren>
    </>
  )
}

import { OfferDto } from "@hub-la/sdk-bff-product"
import { formatLocalNumber } from "../domain/vos/format-local-number"

export const getOfferPrice = (offer: OfferDto) => {
  if (offer.plans && offer?.plans?.length > 0) {
    const longerPlan = offer.plans?.sort((a, b) => b.installments - a.installments)[0]

    return formatLocalNumber(longerPlan.price)
  }
  return formatLocalNumber(offer.price ?? 0)
}

import {
  Alert,
  AlertDescription,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@hub-la/shadcn'
import { MousePointerClick } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Rule } from '../../domain/dtos/rule'
import { GetEventName } from '../../usecases/get-event-name'
import { RuleDetailItem } from './rule-detail-item'

type ConfirmSendRuleTestModalProps = {
  isOpen: boolean
  rule: Rule
  isLoading: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmSendRuleTestModal = (props: ConfirmSendRuleTestModalProps) => {
  const { isOpen, isLoading, rule, onClose, onConfirm } = props
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <div className="flex items-center gap-4">
            <div className="flex items-center p-3 bg-muted rounded-lg">
              <MousePointerClick className="h-5 w-5" />
            </div>
            <DialogTitle>{t('histories.sandboxModal.title')}</DialogTitle>
          </div>
        </DialogHeader>

        <div className="space-y-4" data-testid="confirm-send-rule-test-modal-content">
          <RuleDetailItem title={t('rules.table.columns.name')}>
            <span className="text-sm text-muted-foreground text-right">{rule.name}</span>
          </RuleDetailItem>

          <RuleDetailItem title={t('rules.table.columns.url')}>
            <span className="text-sm text-muted-foreground text-right">{rule.url}</span>
          </RuleDetailItem>

          <RuleDetailItem title={t('rules.table.columns.products')}>
            <span className="text-sm text-muted-foreground text-right">
              {rule.products.map((list) => list.name).join('; ')}
            </span>
          </RuleDetailItem>

          <RuleDetailItem title={t('histories.sandboxModal.events')}>
            <div className="flex flex-wrap justify-end">
              {(rule.events ?? []).map((event) => {
                const eventName = new GetEventName().execute(event)
                if (!eventName) return null
                return (
                  <Badge key={event} variant="secondary" className="mb-2 ml-2 text-xs">
                    {t(eventName)}
                  </Badge>
                )
              })}
            </div>
          </RuleDetailItem>

          <Alert>
            <AlertDescription>{t('histories.sandboxModal.disclaimer')}</AlertDescription>
          </Alert>
        </div>

        <DialogFooter className="sm:justify-end">
          <Button variant="outline" onClick={onClose} disabled={isLoading}>
            {t('buttons.cancel')}
          </Button>

          <Button onClick={onConfirm} disabled={isLoading}>
            {isLoading ? t('common.loading') : t('buttons.sendEvents')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

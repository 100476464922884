import { Button, Card, CardContent, CardHeader, CardTitle, Separator, WhatsApp } from '@hub-la/shadcn'
import { Mail } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { PaymentMethod } from '../../../domain/enums/payment-method'
import { Phone } from '../../../domain/vos/phone'
import { CardRow } from './card-row'

type DetailsProps = {
  transactionId: string
  email?: string
  phoneNumber?: string
  paymentMethod: PaymentMethod
  createdAt: string
  paidAt: string
  isRenewing: boolean
}

export const Details = ({
  transactionId,
  email,
  phoneNumber,
  paymentMethod,
  createdAt,
  paidAt,
  isRenewing,
}: DetailsProps) => {
  const { t } = useTranslation()

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader className="py-2 px-4">
          <CardTitle className="text-base font-semibold flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <span>Cliente</span>
            </div>
            <div className="flex space-x-2">
              <Button size="icon" variant="outline" onClick={() => window.open(`mailto:${email}`)}>
                <Mail className="w-4 h-4" />
              </Button>
              <Button size="icon" variant="outline" onClick={() => window.open(`https://wa.me/${phoneNumber}`)}>
                <WhatsApp className="w-[18px] h-[18px]" />
              </Button>
            </div>
          </CardTitle>
        </CardHeader>
        <Separator />
        <CardContent className="pt-4 space-y-4">
          {email && <CardRow title="Email" content={<span>{email}</span>} />}

          {phoneNumber && (
            <CardRow title="Telefone" content={<span>{Phone.build(phoneNumber).getFormattedNumber()}</span>} />
          )}

          <CardRow title="Método de Pagamento" content={<span>{t(`paymentMethod.${paymentMethod}`)}</span>} />
        </CardContent>
      </Card>
    </div>
  )
}

/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CommissionDto } from './commission-dto';
// May contain unused imports in some cases
// @ts-ignore
import { OfferRefDto } from './offer-ref-dto';
// May contain unused imports in some cases
// @ts-ignore
import { PartnershipTermDto } from './partnership-term-dto';
// May contain unused imports in some cases
// @ts-ignore
import { UserRefDto } from './user-ref-dto';

/**
 * 
 * @export
 * @interface PartnerDto
 */
export interface PartnerDto {
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    'id': string;
    /**
     * 
     * @type {UserRefDto}
     * @memberof PartnerDto
     */
    'user': UserRefDto;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    'type'?: PartnerDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    'status'?: PartnerDtoStatusEnum;
    /**
     * 
     * @type {OfferRefDto}
     * @memberof PartnerDto
     */
    'offer': OfferRefDto;
    /**
     * 
     * @type {CommissionDto}
     * @memberof PartnerDto
     */
    'commission': CommissionDto;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    'expiresAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerDto
     */
    'isSuspended': boolean;
    /**
     * 
     * @type {PartnershipTermDto}
     * @memberof PartnerDto
     */
    'terms'?: PartnershipTermDto;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    'updatedAt': string;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerDtoTypeEnum {
    AFFILIATE = 'affiliate',
    PARTNER = 'partner'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerDtoStatusEnum {
    UNKNOWN = 'unknown',
    INVITED = 'invited',
    REJECTED = 'rejected',
    ACCEPTED = 'accepted',
    FINISHED = 'finished',
    CANCELLATION_REQUESTED = 'cancellation_requested'
}



import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, cn, useIsMobile } from '@hub-la/shadcn'
import { ChevronRight } from 'lucide-react'
import { MouseEvent, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { GetPostId } from '../../../usecases/get-post-id'
import { useGetSections } from '../../hooks/use-get-sections'
import { CloseAlertModal } from '../close-alert-modal'
import { LoadingAlertModal } from '../loading-alert-modal'

type SectionsSidebarProps = {
  disabled: boolean
  isLoading: boolean
  saveAlert: boolean
}

const SectionsSidebar = ({ disabled, isLoading, saveAlert }: SectionsSidebarProps) => {
  const router = useHistory()
  const { productId, sectionId } = useParams<{ productId: string; sectionId: string }>()
  const postId = new GetPostId().execute()
  const isMobile = useIsMobile()
  const { data: sections } = useGetSections({ productId, enabled: !isMobile })
  const [showSidebar, setShowSidebar] = useState(false)
  const [showAlertClose, setShowAlertClose] = useState({
    open: false,
    path: '',
  })
  const [showLoadingAlert, setShowLoadingAlert] = useState(false)

  if (isMobile && !sections) {
    return null
  }

  const handleShowAlert = (e: MouseEvent<HTMLAnchorElement, unknown>, path: string) => {
    if (isLoading) {
      e.preventDefault()
      setShowLoadingAlert(true)
      return
    }
    if (saveAlert) {
      e.preventDefault()
      setShowAlertClose({
        open: true,
        path,
      })
      return
    }
  }

  return (
    <div
      className={cn(
        'w-64 bg-background border-r bottom-0 h-screen hidden md:block fixed pt-[64px] overflow-y-auto transition-transform duration-300 overflow-visible z-10',
        disabled && 'pointer-events-none opacity-75',
        showSidebar && 'translate-x-0',
        !showSidebar && '-translate-x-[95%]',
      )}
    >
      <CloseAlertModal
        open={showAlertClose?.open}
        onClose={() => {
          setShowAlertClose({ open: false, path: '' })
        }}
        onConfirm={() => {
          router.push(showAlertClose.path)
          setShowAlertClose({ open: false, path: '' })
        }}
      />
      <LoadingAlertModal open={showLoadingAlert} onClose={() => setShowLoadingAlert(false)} />
      <button
        className="absolute top-32 -right-5  rounded-full bg-background border p-1"
        onClick={() => setShowSidebar(!showSidebar)}
      >
        <ChevronRight className={cn('text-muted-foreground', showSidebar ? 'rotate-180' : 'rotate-0')} />
      </button>
      <Accordion type="multiple" className="w-full p-2" defaultValue={sectionId ? [`item-${sectionId}`] : []}>
        {sections.map((section) => (
          <AccordionItem value={`item-${section.id}`} key={section.id} className="border-none mb-1">
            <AccordionTrigger className="px-4 py-2 hover:bg-secondary hover:no-underline text-left [&[data-state=open]]:bg-muted text-primary rounded-lg [&[data-state=closed]]:text-muted-foreground text-sm">
              {section.name}
            </AccordionTrigger>
            <AccordionContent>
              <nav>
                <ul className="py-2">
                  {section.posts.map((post) => (
                    <Link
                      to={`/hub/create_post/${productId}/${section.id}?postId=${post.id}`}
                      key={post.id}
                      onClick={(e) =>
                        handleShowAlert(e, `/hub/create_post/${productId}/${section.id}?postId=${post.id}`)
                      }
                    >
                      <li
                        key={post.id}
                        className={cn(
                          'px-6 py-2 hover:background cursor-pointer',
                          post.id === postId && 'bg-muted rounded-lg',
                        )}
                      >
                        {post?.title}
                      </li>
                    </Link>
                  ))}
                </ul>
              </nav>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}

export { SectionsSidebar }

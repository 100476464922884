import { IntegrationEvent } from '../domain/dtos/integration-event'

export class GetEventName {
  private events = [
    { id: IntegrationEvent.ABANDONED_CHECKOUT, name: 'rules.events.abandonedCheckout' },
    { id: IntegrationEvent.PENDING_SALE, name: 'rules.events.pendingSale' },
    { id: IntegrationEvent.NEW_SALE, name: 'rules.events.newSale' },
    { id: IntegrationEvent.NEW_USER, name: 'rules.events.newUser' },
    { id: IntegrationEvent.CANCELED_SUBSCRIPTION, name: 'rules.events.canceledSubscription' },
    { id: IntegrationEvent.IN_PROTEST_SALE, name: 'rules.events.inProtestSale' },
    { id: IntegrationEvent.REFUND_REQUESTED, name: 'rules.events.refundRequested' },
    { id: IntegrationEvent.CANCELED_SALE, name: 'rules.events.canceledSale' },
    { id: IntegrationEvent.EXPIRED_SALE, name: 'rules.events.expiredSale' },
    { id: IntegrationEvent.REFUNDED_SALE, name: 'rules.events.refundedSale' },
    { id: IntegrationEvent.REFUSED_SALE, name: 'rules.events.refusedSale' },
    { id: IntegrationEvent.CHARGEBACK_SALE, name: 'rules.events.chargebackSale' },
    { id: IntegrationEvent.EXTERNAL_PAGE_VIEWED, name: 'rules.events.externalPageViewed' },
    { id: IntegrationEvent.EXTERNAL_PAGE_CLICKED, name: 'rules.events.externalPageClicked' },
    { id: IntegrationEvent.CREATOR_PAGE_VIEWED, name: 'rules.events.creatorPageViewed' },
    { id: IntegrationEvent.PRODUCT_PAGE_VIEWED, name: 'rules.events.productPageViewed' },
    { id: IntegrationEvent.CHECKOUT_PAYMENT_VIEWED, name: 'rules.events.checkoutPaymentViewed' },
    { id: IntegrationEvent.CHECKOUT_PURCHASE_SUBMITTED, name: 'rules.events.checkoutPurchaseSubmitted' },
    { id: IntegrationEvent.CHECKOUT_PURCHASE_FAILED, name: 'rules.events.checkoutPurchaseFailed' },
    { id: IntegrationEvent.CHECKOUT_PURCHASE_SUCCESSFUL, name: 'rules.events.checkoutPurchaseSuccessful' },
    { id: IntegrationEvent.CHECKOUT_PURCHASE_REJECTED, name: 'rules.events.checkoutPurchaseRejected' },
    { id: IntegrationEvent.CUSTOMER_MEMBER_ADDED, name: 'rules.events.customerMemberAdded' },
    { id: IntegrationEvent.CUSTOMER_MEMBER_REMOVED, name: 'rules.events.customerMemberRemoved' },
    { id: IntegrationEvent.SUBSCRIPTION_CREATED, name: 'rules.events.subscriptionCreated' },
    { id: IntegrationEvent.SUBSCRIPTION_ACTIVATED, name: 'rules.events.subscriptionActivated' },
    { id: IntegrationEvent.SUBSCRIPTION_DEACTIVATED, name: 'rules.events.subscriptionDeactivated' },
    { id: IntegrationEvent.SUBSCRIPTION_EXPIRING, name: 'rules.events.subscriptionExpiring' },
    { id: IntegrationEvent.SUBSCRIPTION_RENEW_ENABLED, name: 'rules.events.subscriptionRenewEnabled' },
    { id: IntegrationEvent.SUBSCRIPTION_RENEW_DISABLED, name: 'rules.events.subscriptionRenewDisabled' },
    { id: IntegrationEvent.INVOICE_CREATED, name: 'rules.events.invoiceCreated' },
    { id: IntegrationEvent.INVOICE_STATUS_UPDATED, name: 'rules.events.invoiceStatusUpdated' },
    { id: IntegrationEvent.INVOICE_PAYMENT_SUCCEEDED, name: 'rules.events.invoicePaymentSucceeded' },
    { id: IntegrationEvent.INVOICE_PAYMENT_FAILED, name: 'rules.events.invoicePaymentFailed' },
    { id: IntegrationEvent.INVOICE_EXPIRED, name: 'rules.events.invoiceExpired' },
    { id: IntegrationEvent.INVOICE_REFUNDED, name: 'rules.events.invoiceRefunded' },
    { id: IntegrationEvent.REFUND_REQUEST_CREATED, name: 'rules.events.refundRequestCreated' },
    { id: IntegrationEvent.REFUND_REQUEST_ACCEPTED, name: 'rules.events.refundRequestAccepted' },
    { id: IntegrationEvent.REFUND_REQUEST_REJECTED, name: 'rules.events.refundRequestRejected' },
    { id: IntegrationEvent.REFUND_REQUEST_CANCELED, name: 'rules.events.refundRequestCanceled' },
  ]

  public execute(id: string) {
    return this.events.find((event) => event.id === id)?.name
  }
}

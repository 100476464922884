import { useToast } from '@hub-la/shadcn'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { UploadImage } from '../../usecases/upload-image'

interface UploadImageInput {
  input: File
  path: string
  onLoad?: (n: number) => void
}

export const useUploadImage = () => {
  const { toast } = useToast()
  const { t } = useTranslation()
  return useMutation(
    [],
    ({ input, path, onLoad }: UploadImageInput) => new UploadImage().execute(input, path, onLoad),
    { onError: (error: Error) => toast({ description: t(error.message), variant: 'destructive' }) },
  )
}

export const pt = {
  refundStatus: {
    created: 'Em análise',
    pending: 'Em análise',
    rejected: 'Recusado',
    failed: 'Falha',
    canceled: 'Cancelado',
    processing: 'Processando',
    accepted: 'Aprovado',
  },
  invoiceType: {
    sell: 'Venda',
    renewal: 'Renovação',
    upgrade: 'Upgrade',
  },
  userRefund: {
    title: 'Pedidos de reembolso',
    bankAccountData: {
      title: 'Dados bancários',
      description:
        'Informe a chave Pix ou os dados bancários da conta de sua titularidade que gostaria de receber o valor referente ao pedido de reembolso.',
      pix: {
        pixKey: 'Chave pix',
      },
      bankAccount: {
        bankNumber: 'Banco',
        agencyNumber: 'Agência + Digíto',
        accountNumber: 'Conta + Dígito',
        accountType: 'Tipo de conta',
      },
    },
    buttons: {
      historyBack: 'Histórico de compras',
      createRefund: 'Fazer pedido de reembolso',
      cancel: 'Cancelar',
      cancelRefundRequest: 'Cancelar pedido de reembolso',
      reactivateRefund: 'Reativar pedido de reembolso',
    },
    modals: {
      confirmCancel: {
        title: 'Deseja cancelar o pedido de reembolso?',
        description:
          'Ao cancelar um pedido de reembolso, notificaremos o vendedor e removeremos o pedido do período de análise.',
      },
      confirmReactivate: {
        title: 'Deseja reativar o pedido de reembolso?',
        description:
          'Ao reativar um pedido de reembolso, notificaremos o vendedor e colocaremos o pedido em análise novamente. Você pode cancelar esse pedido de reembolso a qualquer momento.',
      },
    },
  },
  rules: {
    title: 'Política de reembolso',
    rule1: 'A Hubla honrará pedidos de reembolsos feitos dentro de 7 dias após a data de compra do produto.',
    rule2: 'A Hubla não permite pedidos de reembolso feitos após 90 dias após a data de compra do produto.',
    rule3:
      'O produtor tem o direito de negar qualquer pedido de reembolsos de renovações de assinaturas, mesmo feitos dentro de 7 dias após a data da renovação da assinatura.',
    rule4:
      'O produtor tem o direito de negar qualquer pedido de reembolso feito após 7 dias após a data de compra do produto.',
    rule5:
      'O reembolso é processado de acordo com os prazos estabelecidos pela operadora de cartão de crédito, podendo levar de 1 a 2 faturas. Em caso de pagamento com Pix, a devolução do seu dinheiro será realizada diretamente na conta bancária que originou o Pix no momento da compra. Ela é processada pelo banco em até 3 dias úteis. Em caso de pagamento com Boleto, a devolução do seu dinheiro será realizada diretamente na conta bancária informada no momento da solicitação do reembolso em até 3 dias úteis',
  },
  errors: {
    validation: {
      required: 'Campo obrigatório',
      invalidAccountNumber: 'Número da conta inválido',
      invalidAccountType: 'Tipo de conta inválida',
      invalidAgencyNumber: 'Número da agência inválido',
      invalidBank: 'Banco inválido',
    },
    alreadyExists: 'Já existe uma solicitação de reembolso para essa fatura',
    general: 'Falha ao solicitar pedido de reembolso. Tente novamente',
  },
  snackbar: {
    error: 'Erro ao efetuar o pedido de reembolso. Tente novamente.',
    success: 'Pedido de reembolso solicitado com sucesso',
  },
}

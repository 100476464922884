import React from 'react'
import { PreviewCheckout } from '../../../../components/preview-checkout/preview-checkout'

interface PricingPreviewProps {
  productName?: string
  productOwner?: string
  productPicture?: string | null
}

export const PricingPreview: React.FC<PricingPreviewProps> = ({
  productName = '',
  productOwner = '',
  productPicture = null,
}) => {
  return (
    <div className="flex flex-col w-full">
      <div className="w-full flex justify-center">
        {/** @todo: Add the product details */}
        <PreviewCheckout
          group={{
            name: productName,
            owner: productOwner,
            picture: productPicture,
          }}
        />
      </div>
    </div>
  )
}

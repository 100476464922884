import React from 'react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@hub-la/shadcn';
import { ChevronsUpDown } from 'lucide-react';

export const CustomCollapsible = (props: React.PropsWithChildren<{ title: string, Icon: any, defaultOpen?: boolean }>) => {
  return (
    <Collapsible
      defaultOpen={props.defaultOpen}
      className="w-full bg-background border rounded-lg shadow-sm overflow-hidden"
    >
      <CollapsibleTrigger className="flex w-full items-center justify-between px-6 py-4 bg-background">
        <div className="flex items-center gap-3">
          <div className="p-2 rounded-full bg-muted">
            <props.Icon />
          </div>
          <span className="text-sm font-medium">{props.title}</span>
        </div>
        <ChevronsUpDown
          className="h-4 w-4 text-gray-500 transition-transform duration-200"
        />
      </CollapsibleTrigger>
      <CollapsibleContent className="p-6">
        {props.children}
      </CollapsibleContent>
    </Collapsible>
  );
};

import { Theme as MuiTheme, useTheme } from '@mui/material/styles'
import { alpha as _alpha, Breakpoints, Spacing } from '@mui/system'
import { Tokens } from '../foundation/theme/tokens/tokens.types'
import { _palette } from '../foundation/utils'
export { css, keyframes, styled, ThemeProvider } from '@mui/material/styles'

type Utility = {
  alpha: (color: string, value: number) => string
  spacing: Spacing
  breakpoints: Breakpoints
  palette: (token: keyof Tokens) => string
  isLight: boolean
}

export const useUtility = (): Utility => {
  const theme = useTheme<MuiTheme>()
  return {
    alpha: _alpha,
    spacing: theme.spacing,
    breakpoints: theme.breakpoints,
    palette: _palette({ theme }),
    isLight: theme.palette.mode === 'light',
  }
}

import { Separator } from '@hub-la/shadcn'
import { Account } from '../../../domain/dtos/account'
import { Document } from '../../../domain/vos/document'
import { Phone } from '../../../domain/vos/phone'

type InvoiceInfoProps = {
  payer: Account
  seller: Account
}

export const InvoiceInfo = ({ payer, seller }: InvoiceInfoProps) => {
  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold">Dados da fatura</h2>
      <Separator />
      <div className="grid md:grid-cols-2">
        <div className="space-y-1">
          <h3 className="text-sm text-muted-foreground space-y-1">Cobrança</h3>
          <p className="text-sm">{payer.identity?.fullName}</p>
          <p className="text-sm">{payer.identity?.email}</p>
          {payer.identity?.phone && (
            <p className="text-sm">{Phone.build(payer.identity?.phone).getFormattedNumber()}</p>
          )}
        </div>
        <div>
          <div>
            <h3 className="text-sm text-muted-foreground space-y-1">Faturamento</h3>
          </div>
          <p className="text-sm">{seller.identity?.fullName}</p>
          {seller.identity?.document && (
            <p className="text-sm">{Document.build(seller.identity?.document).getValue()}</p>
          )}
        </div>
      </div>
    </div>
  )
}

import { container } from '@hub-la/fe-container'
import {
  IntegrationDetailPage,
  IntegrationProvider,
  IntegrationsListPage,
  IntegrationsProvider,
} from '@hub-la/fe-integrations'

export const DashboardIntegrationsInit = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const provider = urlParams.get('provider')

  return (
    <IntegrationsProvider container={container}>
      {provider ? <IntegrationDetailPage provider={provider as IntegrationProvider} /> : <IntegrationsListPage />}
    </IntegrationsProvider>
  )
}

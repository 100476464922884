export enum IntegrationEvent {
  NEW_USER = 'NewUser',
  NEW_SALE = 'NewSale',
  CANCELED_SUBSCRIPTION = 'CanceledSubscription',
  CANCELED_SALE = 'CanceledSale',
  PENDING_SALE = 'PendingSale',
  IN_PROTEST_SALE = 'InProtestSale',
  REFUND_REQUESTED = 'RefundRequested',
  ABANDONED_CHECKOUT = 'AbandonedCheckout',
  EXPIRED_SALE = 'ExpiredSale',
  REFUNDED_SALE = 'RefundedSale',
  REFUSED_SALE = 'RefusedSale',
  CHARGEBACK_SALE = 'ChargebackSale',
  // Analytics
  EXTERNAL_PAGE_VIEWED = 'ExternalPageViewed',
  EXTERNAL_PAGE_CLICKED = 'ExternalPageClicked',
  CREATOR_PAGE_VIEWED = 'CreatorPageViewed',
  PRODUCT_PAGE_VIEWED = 'ProductPageViewed',
  CHECKOUT_PAYMENT_VIEWED = 'CheckoutPaymentViewed',
  CHECKOUT_PURCHASE_SUBMITTED = 'CheckoutPurchaseSubmitted',
  CHECKOUT_PURCHASE_FAILED = 'CheckoutPurchaseFailed',
  CHECKOUT_PURCHASE_SUCCESSFUL = 'CheckoutPurchaseSuccessful',
  CHECKOUT_PURCHASE_REJECTED = 'CheckoutPurchaseRejected',
  // Member
  CUSTOMER_MEMBER_ADDED = 'customer.member_added',
  CUSTOMER_MEMBER_REMOVED = 'customer.member_removed',
  // Subscription
  SUBSCRIPTION_CREATED = 'subscription.created',
  SUBSCRIPTION_ACTIVATED = 'subscription.activated',
  SUBSCRIPTION_DEACTIVATED = 'subscription.deactivated',
  SUBSCRIPTION_EXPIRING = 'subscription.expiring',
  SUBSCRIPTION_RENEW_ENABLED = 'subscription.renewal_enabled',
  SUBSCRIPTION_RENEW_DISABLED = 'subscription.renewal_disabled',
  // Invoice
  INVOICE_CREATED = 'invoice.created',
  INVOICE_STATUS_UPDATED = 'invoice.status_updated',
  INVOICE_PAYMENT_SUCCEEDED = 'invoice.payment_succeeded',
  INVOICE_PAYMENT_FAILED = 'invoice.payment_failed',
  INVOICE_EXPIRED = 'invoice.expired',
  INVOICE_REFUNDED = 'invoice.refunded',
  // Refund
  REFUND_REQUEST_CREATED = 'refund_request.created',
  REFUND_REQUEST_ACCEPTED = 'refund_request.accepted',
  REFUND_REQUEST_REJECTED = 'refund_request.rejected',
  REFUND_REQUEST_CANCELED = 'refund_request.canceled',
}

export interface IntegrationEventOption {
  code: IntegrationEvent
  name: string
  group: string
}

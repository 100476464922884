export const Envs = {
  BFF_WEB_URL: process.env['NX_BFF_WEB_URL'] ?? process.env['REACT_APP_HUB_API'],
  FUNCTIONS_URL: process.env['NX_FUNCTIONS_URL'] ?? process.env['REACT_APP_FUNCTIONS_URL'],
  ENV: process.env['ENVIRONMENT'] ?? process.env['REACT_APP_CUSTOM_NODE_ENV'],
  BFF_URL: process.env['NX_BFF_CHECKOUT_URL'] ?? process.env['REACT_APP_BFF_URL'],

  ATTACHMENT_FILE_MIMETYPE_ALLOWED:
    process.env['NX_FILE_MIMETYPE_ALLOWED'] ?? process.env['REACT_APP_FILE_MIMETYPE_ALLOWED'] ?? '',
  ATTACHMENT_VIDEO_MIMETYPE_ALLOWED:
    process.env['NX_VIDEO_MIMETYPE_ALLOWED'] ?? process.env['REACT_APP_VIDEO_MIMETYPE_ALLOWED'] ?? '',
  ATTACHMENT_IMAGE_MIMETYPE_ALLOWED:
    process.env['NX_IMAGE_MIMETYPE_ALLOWED'] ?? process.env['REACT_APP_IMAGE_MIMETYPE_ALLOWED'] ?? '',
  MUX_STREAM_URL: process.env['MUX_STREAM_URL'] ?? process.env['REACT_APP_MUX_STREAM_URL'] ?? '',

  SITE_URL: process.env['NEXT_PUBLIC_SITE_URL'] ?? process.env['REACT_APP_SITE_URL'] ?? process.env['NX_SITE_URL'],
  APP_URL: process.env['NEXT_PUBLIC_APP_URL'] ?? process.env['REACT_APP_APP_URL'] ?? process.env['NX_APP_URL'],
  PAY_URL: process.env['NX_PAY_URL'] ?? process.env['REACT_APP_PAY_CHECKOUT_URL'],

  NODE_ENV:
    process.env['NX_CUSTOM_NODE_ENV'] ??
    process.env['NODE_ENV'] ??
    process.env['ENVIRONMENT'] ??
    process.env['REACT_APP_CUSTOM_NODE_ENV'],
  BFF_MEMBERS_AREA_URL: process.env['NX_BFF_MEMBERS_AREA_URL'] ?? process.env['REACT_APP_BFF_MEMBERS_AREA_URL'],
}

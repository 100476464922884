import React from 'react'
import { useTranslation } from 'react-i18next'
import isNil from 'lodash/isNil'
import { GetUpgradeStateOutput } from '../../../../../domain/dtos/get-upgrade-state-output'
import { UpgradePaymentMethods } from '../../../../../domain/dtos/payment-method'
import { UpgradeStatus } from '../../../../../domain/enums/upgrade-status'
import { formatCurrency, formatCurrencyWithInstallments } from '../../../../../domain/vos/format-currency'
import { useCancelUpgradePlan } from '../../../../hooks/use-cancel-upgrade-plan'
import { Button, Card, CardContent, Dialog, DialogContent, useIsMobile } from '@hub-la/shadcn'
import { ContentRow } from './content-row'
import { CreditCard } from './credit-card'
import { Loading } from './loading'
import { PaymentFailed } from './payment-failed'
import { Pix } from './pix'
import { Success } from './success'

type Props = {
  open: boolean
  onClose: () => void
  onCancel: () => void
  subscriptionId: string
  upgradeState?: GetUpgradeStateOutput
}

export const Submitted: React.FC<Props> = ({ open, onClose, onCancel, subscriptionId, upgradeState }) => {
  const { mutateAsync: cancelUpgradePlan, isLoading } = useCancelUpgradePlan()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const isCompleted = upgradeState?.status === UpgradeStatus.SUCCESS
  const isError = upgradeState?.status === UpgradeStatus.ERROR

  if (isNil(upgradeState)) {
    return <Loading />
  }

  if (isCompleted) {
    return (
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className={isMobile ? 'w-full h-full' : 'max-w-md'}>
          <Success />
        </DialogContent>
      </Dialog>
    )
  }

  if (isError) {
    return (
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className={isMobile ? 'w-full h-full' : 'max-w-md'}>
          <PaymentFailed />
        </DialogContent>
      </Dialog>
    )
  }

  const render = {
    [UpgradePaymentMethods.PAYMENT_METHOD_PIX]: (
      <Pix qrcode={upgradeState?.paymentMethod.url ?? ''} code={upgradeState?.paymentMethod.text ?? ''} />
    ),
    [UpgradePaymentMethods.PAYMENT_METHOD_CARD]: (
      <CreditCard
        cardBrand={upgradeState?.paymentMethod.cardBrand ?? ''}
        last4={upgradeState?.paymentMethod.last4 ?? ''}
      />
    ),
  }[upgradeState.paymentMethod.type]

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className={isMobile ? 'w-full h-full' : 'max-w-md'}>
        <div className="flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold">{t('upgradePlanModal.title')}</h2>
          </div>

          <Card>
            <CardContent className="p-6 space-y-6">
              <p className="text-sm text-muted-foreground">
                {t('upgradePlanModal.summary', {
                  from: t(`planInterval.${upgradeState.currentPlan?.billingCycle.toLowerCase()}`),
                  to: t(`planInterval.${upgradeState.upgradePlan?.billingCycle.toLowerCase()}`),
                })}
              </p>
              <div className="space-y-2">
                <ContentRow
                  title={
                    upgradeState?.upgradePlan?.items[0]?.description +
                    ' - ' +
                    t(`planType.${upgradeState.upgradePlan?.billingCycle.toLowerCase()}`)
                  }
                  content={
                    <p>
                      {formatCurrencyWithInstallments(
                        upgradeState.upgradePlan?.installments,
                        upgradeState.upgradePlan?.priceInCents / 100 + upgradeState.upgradePlan?.prorataCents / 100,
                        (upgradeState.upgradePlan?.priceInCents / 100 + upgradeState.upgradePlan?.prorataCents / 100) /
                          upgradeState.upgradePlan.installments,
                      )}
                    </p>
                  }
                />
                <ContentRow
                  showDivider
                  title={t('upgradePlanModal.credits', {
                    plan: t(`planType.${upgradeState?.currentPlan?.billingCycle.toLowerCase()}`),
                  })}
                  content={<p>- {formatCurrency((upgradeState.upgradePlan?.prorataCents ?? 0) / 100)}</p>}
                />
                <ContentRow
                  fontWeight="bold"
                  title="Total"
                  content={
                    <p>
                      {formatCurrencyWithInstallments(
                        upgradeState.upgradePlan?.installments,
                        upgradeState.upgradePlan?.priceInCents / 100,
                        upgradeState.upgradePlan?.priceInCents / 100 / upgradeState.upgradePlan.installments,
                      )}
                    </p>
                  }
                />
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent>{render}</CardContent>
          </Card>

          <Button
            variant="ghost"
            loading={isLoading}
            onClick={() => {
              cancelUpgradePlan(subscriptionId).then(() => {
                onClose()
                onCancel()
              })
            }}
          >
            {t('upgradePlanModal.cancelUpgrade')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

import { CoProductionPage, CoProductionProvider } from '@hub-la/fe-co-production'
import { container } from '@hub-la/fe-container'
import { withRoleplayRedirect } from '../components/roleplay-redirect'

export const CoProductionInit = withRoleplayRedirect(() => {
  return (
    <CoProductionProvider container={container}>
      <CoProductionPage />
    </CoProductionProvider>
  )
})

import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { CancelUpgradePlan } from '../../usecases/cancel-upgrade-plan'

export const useCancelUpgradePlan = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.cancelUpgradePlan],
    (id: string) => new CancelUpgradePlan(container.get(HttpClient)).execute(id),
    {
      retry: false,
      onSuccess: async (_, id) => {
        queryClient.invalidateQueries([QueryKey.initUpgradePlan, id])
        queryClient.invalidateQueries([QueryKey.getUpgradeState, id])
        queryClient.invalidateQueries([QueryKey.getSubscription, id])
        await new Promise((r) => setTimeout(r, 2000))
        queryClient
          .getQueryCache()
          .getAll()
          .forEach((query) => {
            if (query.queryKey[0] === QueryKey.getSubscriptionInvoices) {
              queryClient.invalidateQueries(query.queryKey)
            }
          })
      },
    },
  )
}

import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { HasAccountsAccess } from '../../usecases/has-accounts-access'

export const useHasAccountsAccess = (input: { enabled?: boolean } | undefined) => {
  const container = useContainer()

  return useQuery([QueryKey.hasAccountsAccess], () => new HasAccountsAccess(container.get(HttpClient)).execute(), {
    retry: 3,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    initialData: false,
    enabled: input?.enabled,
  })
}

export enum IntegrationProvider {
  WEBHOOK = 'webhook',
  ZAPIER = 'zapier',
  ACTIVE_CAMPAIGN = 'active-campaign',
  E_NOTAS = 'enotas',
  GOOGLE_TAG_MANAGER = 'google-tag-manager',
  META_PIXEL = 'meta-pixel',
  HUBLA_PIXEL = 'hubla-pixel',
  SELL_FLUX = 'sell-flux',
  GDIGITAL = 'g-digital',
  ZAPCERTO = 'zapcerto',
  CADEMI = 'cademi',
  MEMBERKIT = 'memberkit',
  LEAD_LOVERS = 'leadlovers',
  THE_MEMBERS = 'themembers',
  HUBSPOT = 'hubspot',
  OMIE = 'omie',
  RDSTATION = 'rdstation',
}

export const mapBackendProvider: Record<IntegrationProvider, string> = {
  [IntegrationProvider.WEBHOOK]: 'webhookEvents',
  [IntegrationProvider.ACTIVE_CAMPAIGN]: 'activeCampaign',
  [IntegrationProvider.ZAPIER]: 'zapier',
  [IntegrationProvider.E_NOTAS]: 'enotas',
  [IntegrationProvider.GOOGLE_TAG_MANAGER]: 'googleTagManager',
  [IntegrationProvider.META_PIXEL]: 'mataPixel',
  [IntegrationProvider.HUBLA_PIXEL]: 'hublaPixel',
  [IntegrationProvider.SELL_FLUX]: 'sellflux',
  [IntegrationProvider.GDIGITAL]: 'gdigital',
  [IntegrationProvider.ZAPCERTO]: 'zapCerto',
  [IntegrationProvider.CADEMI]: 'cademi',
  [IntegrationProvider.MEMBERKIT]: 'memberkit',
  [IntegrationProvider.LEAD_LOVERS]: 'leadlovers',
  [IntegrationProvider.THE_MEMBERS]: 'themembers',
  [IntegrationProvider.HUBSPOT]: 'hubspot',
  [IntegrationProvider.OMIE]: 'omie',
  [IntegrationProvider.RDSTATION]: 'rdstation',
}

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Tabs, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { Monitor, Smartphone } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

export enum View {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

interface SelectViewProps {
  onChange: (mode: View) => void
  value: View
}

export const SelectView: React.FC<SelectViewProps> = ({ onChange, value }) => {
  const { t } = useTranslation()

  return (
    <Tabs
      value={value}
      onValueChange={(value) => onChange(value as View)}
      className="max-w-max"
    >
      <TabsList className="flex">
        <TabsTrigger value={View.Mobile}>
          <div className="flex items-center">
            <Smartphone className="mr-2 h-4 w-4 text-muted-foreground" />
            <span className="text-sm text-secondary-foreground">{t('checkoutSettings.view.mobile')}</span>
          </div>
        </TabsTrigger>
        <TabsTrigger value={View.Desktop}>
          <div className="flex items-center">
            <Monitor className="mr-2 h-4 w-4 text-muted-foreground" />
            <span className="text-sm text-secondary-foreground">{t('checkoutSettings.view.desktop')}</span>
          </div>
        </TabsTrigger>
      </TabsList>
    </Tabs>
  )
}

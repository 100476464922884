import { Alert, AlertDescription, AlertTitle, ScrollArea, Separator } from '@hub-la/shadcn'
import React, { memo } from 'react'
import { PlanType } from '../../../domain/enums/plan-type'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'
import { SubscriptionType } from '../../../domain/enums/subscription-type'
import { formatCurrency } from '../../../domain/vos/format-currency'
import { useGetInvoice } from '../../hooks/use-get-invoice'
import { ChargeDetail } from './charge-detail'
import { Description } from './description'
import { Details } from './details'
import { EntitiesCards } from './entities-cards'
import { Header } from './header'
import { InvoiceInfo } from './invoice-info'
import { Offers } from './invoice-offers'
import { InvoiceTimeline } from './invoice-timeline'
import { Loading } from './loading'
import { UTMTracking } from './utm-tracking'

export const InvoiceDetail: React.FC<{ id: string; onClose: () => void }> = memo(({ id, onClose }) => {
  const { data: invoice, refetch: refetchInvoice, isError, error } = useGetInvoice(id)

  if (isError) {
    return (
      <Alert variant="destructive">
        <AlertTitle>Falha ao carregar</AlertTitle>
        <AlertDescription>
          Falha ao carregar os detalhes da faturas. Verifique sua conexão e tente mais tarde.
          {error instanceof Error && <p>{error.message}</p>}
        </AlertDescription>
      </Alert>
    )
  }

  if (!invoice) {
    return <Loading />
  }

  return (
    <ScrollArea className="h-full w-full overflow-auto">
      <div className="space-y-4 mb-8">
        <Header
          total={formatCurrency(invoice.amount.totalCents / 100)}
          status={invoice.status}
          smartInstallmentStatus={invoice.smartInstallmentRef?.status}
          installment={invoice.installment ?? 0}
          installments={invoice.smartInstallmentRef?.installments ?? 0}
          smartInstallmentId={invoice.smartInstallmentRef?.id}
          id={invoice.id}
          role={invoice.role}
          onChange={refetchInvoice}
          onClose={onClose}
        />
        <Separator />
        <Details
          invoice={invoice}
          subscription={{
            credits: invoice.subscriptionRef?.credits ?? 0,
            id: invoice.subscriptionRef?.id ?? '',
            plan: invoice.subscriptionRef?.planType ?? PlanType.MONTHLY,
            status: invoice.subscriptionRef?.status ?? SubscriptionStatus.STATUS_ACTIVE,
            _type: invoice.subscriptionRef?.type ?? SubscriptionType.TYPE_ONE_TIME,
          }}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-2 space-y-8">
          {invoice.events && <InvoiceTimeline events={invoice.events} />}
          {invoice.amountDetail && <Description detail={invoice.amountDetail} role={invoice.role} />}
          <Offers offers={invoice?.offers} />
          <InvoiceInfo payer={invoice.payer} seller={invoice.seller} />
        </div>
        <div className="space-y-6">
          <EntitiesCards
            client={{
              name: invoice.payer.identity?.fullName ?? '',
              id: invoice.payer.id,
              email: invoice.payer.identity?.email ?? '',
              phone: invoice.payer.identity?.phone ?? '',
            }}
          />
          <ChargeDetail
            payer={invoice.payer}
            detail={{
              invoiceId: invoice.id,
              createdAt: invoice.createdAt,
              paidAt: invoice.paidAt,
              paymentMethod: invoice.paymentMethod,
              installments: invoice.installments,
              cardDetails: invoice.cardDetails,
              billingAddress: invoice.billingAddress,
            }}
          />
          <UTMTracking
            detail={{
              utmSource: invoice.payerSession?.utmSource ?? '-',
              utmMedium: invoice.payerSession?.utmMedium ?? '-',
              utmCampaign: invoice.payerSession?.utmCampaign ?? '-',
              utmContent: invoice.payerSession?.utmContent ?? '-',
              utmTerm: invoice.payerSession?.utmTerm ?? '-',
            }}
          />
        </div>
      </div>
    </ScrollArea>
  )
})

import { Tabs, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { Clipboard, Layout, Link2Icon, Receipt, Settings, Ticket, Users2, UserSquare2 } from 'lucide-react'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Route, Switch, useLocation, useParams } from 'react-router-dom'
import { ProductType } from '../../domain/enums/product-type'
import { PageHeader } from '../components/page-header'
import { useGetProduct } from '../hooks/use-get-product'
import { CohortsPage } from './cohorts/cohorts'
import { MemberAreaPage } from './member-area/member-area'
import { MembersPage } from './members/members'
import { OffersPage } from './offers/offers'
import { ParticipantsPage } from './participants/participants'
import { SettingsPage } from './settings/settings'
import { ShortLinksPage } from './shortlinks/shortlinks'
import { Tickets } from './tickets/tickets'
import { WaitlistPage } from './waitlist/waitlist'

const digitalMenus = (offerId: string) => [
  { name: 'member-area', icon: Layout, content: <MemberAreaPage offerId={offerId} /> },
  { name: 'offers', icon: Receipt, content: <OffersPage /> },
  { name: 'cohorts', icon: UserSquare2, content: <CohortsPage /> },
  { name: 'members', icon: Users2, content: <MembersPage /> },
  { name: 'waitlist', icon: Clipboard, content: <WaitlistPage /> },
  { name: 'settings', icon: Settings, content: <SettingsPage offerId={offerId} /> },
  { name: 'shortlinks', icon: Link2Icon, content: <ShortLinksPage /> },
]

const eventMenus = (offerId: string) => [
  { name: 'tickets', icon: Ticket, content: <Tickets offerId={offerId} /> },
  { name: 'offers', icon: Receipt, content: <OffersPage isEvent={true} /> },
  { name: 'participants', icon: Users2, content: <ParticipantsPage /> },
  { name: 'waitlist', icon: Clipboard, content: <WaitlistPage /> },
  { name: 'settings', icon: Settings, content: <SettingsPage offerId={offerId} /> },
  { name: 'shortlinks', icon: Link2Icon, content: <ShortLinksPage /> },
]

const menusByProductType = {
  digital: digitalMenus,
  event: eventMenus,
}

export const EditProductPage = () => {
  const { productId } = useParams<{ productId: string }>()
  const { data: product } = useGetProduct(productId)
  const { t } = useTranslation()
  const location = useLocation()

  const menuByProductType = product?.type && menusByProductType[product.type](product.mainOfferId)
  const tabsRef = useRef<HTMLDivElement>(null)

  const getCurrentTab = () => {
    const path = location.pathname
    const currentTab = path.split('/').pop()
    return currentTab || (menuByProductType && menuByProductType[0].name)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="flex flex-col min-h-screen">
      <PageHeader productType={product?.type ?? ProductType.DIGITAL} />

      <div className="w-full px-4 lg:px-24 border-b">
        <Tabs value={getCurrentTab()}>
          <div ref={tabsRef} className="w-full overflow-x-auto overflow-y-hidden">
            <TabsList variant="underlined" className="border-none">
              {menuByProductType?.map((menu) => (
                <TabsTrigger key={menu.name} value={menu.name} variant="underlined">
                  <Link
                    to={`/edit/${productId}/${menu.name}${product?.type === ProductType.EVENT ? '?event=true' : ''}`}
                    className="whitespace-nowrap flex items-center"
                  >
                    {menu.icon && <menu.icon className="h-4 w-4 mr-1.5" />}
                    {t(`menu.${menu.name}`)}
                  </Link>
                </TabsTrigger>
              ))}
            </TabsList>
          </div>
        </Tabs>
      </div>

      <div className="flex w-full flex-1 gap-4 py-4 px-4 lg:px-24">
        <div className="w-full p-1 overflow-x-auto">
          <Switch>
            {menuByProductType?.map((menu) => (
              <Route key={menu.name} path={`/edit/:productId/${menu.name}`}>
                {menu.content}
              </Route>
            ))}
            {menuByProductType && (
              <Route exact path="/edit/:productId">
                {menuByProductType[0].content}
              </Route>
            )}
          </Switch>
        </div>
      </div>
    </div>
  )
}

import {
  cn,
  Drawer,
  DrawerContent,
  Sheet,
  SheetContent,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useIsMobile,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { ChevronDown, ChevronsUpDown, ChevronUp } from 'lucide-react'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SmartInstallment } from '../../../../domain/dtos/smart-installment'
import { OrderDirection } from '../../../../domain/enums/order-direction'
import { SmartInstallmentStatus } from '../../../../domain/enums/smart-installment-status'
import { SmartInstallmentStatusStats } from '../../../components/smart-installment-status-stats'
import { useGetSmartInstallments } from '../../../hooks/use-get-smart-installments'
import { useRefetch } from '../../../hooks/use-refetch'
import { InstallmentProgress } from '../../installment-progress'
import { SmartInstallmentDetail } from '../../smart-installment-detail/smart-installment-detail'
import { TableLoading } from './table-loading'

export const SmartInstallmentsTable = ({ filters, setFilters, offers, isLoading }) => {
  const { page, pageSize } = filters

  const [openSmartInstallmentDetail, setOpenSmartInstallmentDetail] = useState<string>()
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.DESC)
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const history = useHistory()

  const {
    data = { items: [], total: 0, page, pageSize, lastPage: 1 },
    isFetching,
    refetch,
  } = useGetSmartInstallments(
    {
      offers,
      offerIds: filters.offerIds,
      statuses: filters.statuses as SmartInstallmentStatus[],
      startDate: filters.startDate,
      endDate: filters.endDate,
      dateRangeBy: filters.dateRangeBy,
      page: filters.page,
      pageSize: parseInt(filters.pageSize, 10),
      search: filters.search,
      orderDirection,
      orderBy,
    },
    isLoading,
  )

  useRefetch({ offers, ...filters, orderDirection }, refetch)

  const getHeadCells = () => {
    return [
      {
        key: 'client',
        label: 'Cliente',
        sortable: false,
        width: 220,
      },
      {
        key: 'product',
        label: 'Produto',
        sortable: false,
        width: 170,
      },
      {
        key: 'status',
        label: 'Status',
        tooltip: t('smartInstallmentsTable.tooltip.status'),
        sortable: false,
        width: 130,
      },
      {
        key: 'installments',
        label: 'Total em parcelas',
        width: 160,
        sortable: false,
      },
      {
        key: 'createdAt',
        label: 'Data da criação',
        sortable: true,
        defaultSortOrder: 'desc',
        width: 178,
      },
      {
        key: 'nextDueAt',
        label: 'Próximo vencimento',
        sortable: false,
        width: 172,
      },
    ]
  }

  const handleRequestSort = useCallback(
    (property: string) => {
      const isAsc = orderBy === property && orderDirection === OrderDirection.ASC
      setOrderDirection(isAsc ? OrderDirection.DESC : OrderDirection.ASC)
      setOrderBy(property)
    },
    [orderDirection, orderBy],
  )

  const isRowsEmpty = isEmpty(data?.items)
  const canRenderEmptyState = !isFetching && !isLoading && isRowsEmpty

  return (
    <div className="flex flex-col gap-4">
      <div className="rounded-md border bg-background">
        <TooltipProvider>
          <Table>
            <TableHeader>
              <TableRow className="hover:bg-background">
                {getHeadCells().map((column) => (
                  <TableHead
                    onClick={() => handleRequestSort(column.key)}
                    className={cn(['createdAt', 'nextDueAt'].includes(column.key) && 'cursor-pointer')}
                  >
                    {['createdAt', 'nextDueAt'].includes(column.key) ? (
                      <div className="flex items-center">
                        {column.label}
                        {orderBy === column.key ? (
                          orderDirection === OrderDirection.ASC ? (
                            <ChevronUp className="ml-2 h-4 w-4" />
                          ) : (
                            <ChevronDown className="ml-2 h-4 w-4" />
                          )
                        ) : (
                          <ChevronsUpDown className="ml-2 h-4 w-4" />
                        )}
                      </div>
                    ) : (
                      column.label
                    )}
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {(isFetching && isRowsEmpty) || isLoading
                ? times(pageSize, (idx) => <TableLoading key={idx} />)
                : (data?.items ?? []).map((smartInstallment: SmartInstallment) => (
                    <TableRow
                      onClick={() => {
                        history.push(`/dashboard/sales/smart-installments/${smartInstallment.id}`)
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                      }}
                      className="cursor-pointer"
                    >
                      <TableCell className="max-w-[170px]">
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <p className="truncate text-sm hover:text-clip">
                              {smartInstallment.payer?.identity?.fullName}
                            </p>
                          </TooltipTrigger>
                          <TooltipContent>{smartInstallment.payer?.identity?.fullName}</TooltipContent>
                        </Tooltip>
                      </TableCell>
                      <TableCell className="max-w-[170px]">
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <p className="truncate text-sm hover:text-clip">
                              {smartInstallment.invoiceRef?.items[0].productName}
                            </p>
                          </TooltipTrigger>
                          <TooltipContent>{smartInstallment.invoiceRef?.items[0].productName}</TooltipContent>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <SmartInstallmentStatusStats status={smartInstallment.status} />
                      </TableCell>
                      <InstallmentProgress
                        installments={smartInstallment.installments}
                        paidInstallments={smartInstallment.installmentTotalPaid}
                        overdueInstallments={smartInstallment.installmentTotalOverdue}
                        draftInstallments={smartInstallment.installmentTotalDraft}
                        paid={smartInstallment.amountTotalPaidCents}
                        overdue={smartInstallment.amountTotalOverdueCents}
                        draft={smartInstallment.amountTotalDraftCents}
                      />
                      <TableCell className="text-muted-foreground">
                        <p>{moment(smartInstallment.createdAt).format('D MMM. YY')}</p>
                      </TableCell>
                      <TableCell className="text-muted-foreground">
                        <p>{moment(smartInstallment.nextDueAt).format('D MMM. YY')}</p>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TooltipProvider>

        {canRenderEmptyState && <TableEmptyState title={t('empty')} subtitle={t('emptySubtitle')} />}
      </div>
      {isMobile ? (
        <Drawer open={!!openSmartInstallmentDetail} onClose={() => setOpenSmartInstallmentDetail(undefined)}>
          <DrawerContent className="max-h-[940px]">
            {openSmartInstallmentDetail && (
              <SmartInstallmentDetail
                id={openSmartInstallmentDetail}
                onClose={() => setOpenSmartInstallmentDetail(undefined)}
              />
            )}
          </DrawerContent>
        </Drawer>
      ) : (
        <Sheet
          key="right"
          open={!!openSmartInstallmentDetail}
          onOpenChange={(open) => open === false && setOpenSmartInstallmentDetail(undefined)}
        >
          <SheetContent side="right" className="w-[400px] background sm:w-[1340px] sm:max-w-8xl">
            {openSmartInstallmentDetail && (
              <SmartInstallmentDetail
                id={openSmartInstallmentDetail}
                onClose={() => setOpenSmartInstallmentDetail(undefined)}
              />
            )}
          </SheetContent>
        </Sheet>
      )}
      <div className="flex justify-end">
        {!isRowsEmpty && (
          <TablePagination
            page={page}
            setPage={(page) => setFilters({ ...filters, page })}
            lastPage={data.lastPage}
            pageSize={+pageSize}
            setPageSize={(pageSize) => setFilters({ ...filters, pageSize })}
          />
        )}
      </div>
    </div>
  )
}

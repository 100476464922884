import { Refund } from '../../../../domain/dtos/refund'
import { RefundStatusStats } from '../../../components/refund-status-stats'
import { ColumnDef } from '@tanstack/react-table'
import { RefundStatus } from '../../../../domain/enums/refund-status.enum'
import moment from 'moment'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { PaymentMethodIcon } from './payment-method-icon'
import { PaymentMethod } from '../../../../domain/enums/payment-method'

export const columns: ColumnDef<Refund>[] = [
  {
    header: 'Valor',
    accessorKey: 'amount',
    cell: ({ row }) => {
      const total: number = row.getValue("amount")
      const paymentMethod = row.original.transaction?.paymentMethod as PaymentMethod

      return (
        <div className='flex items-center gap-2'>
          <PaymentMethodIcon paymentMethod={paymentMethod} />
          <p>{formatCurrency(total)}</p>
        </div>
      )
    }
  },
  {
    header: 'Status',
    accessorFn: (refund) => refund.status,
    cell: (status) => {
      return <RefundStatusStats status={status.getValue() as RefundStatus} />
    }
  },
  {
    header: 'Produto',
    accessorKey: 'transaction.item.name',
  },
  {
    header: 'Data da compra',
    accessorFn: (refund) => refund.transaction?.paidAt,
    cell: (data) => {
      const paidAt = data.getValue() as string

      if (!paidAt) {
        return null
      }

      return <>{moment(paidAt).format('D MMM. YYYY')}</>
    },
  },
  {
    header: 'Data do pedido',
    accessorFn: (refund) => refund.createdAt,
    cell: (data) => {
      const createdAt = data.getValue() as string

      if (!createdAt) {
        return null
      }

      return <>{moment(createdAt).format('D MMM. YYYY')}</>
    },
  },
]

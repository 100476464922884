import { useAuth } from '@hub-la/fe-auth'
import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>
  /* Permissions required to access the route with roleplay */
  roleplayPermissions?: PermissionResource[]
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, roleplayPermissions, ...rest }) => {
  const { isAuthenticated } = useAuth()
  const { isRoleplay, hasPermission } = useRoleplay()

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated === null) {
          return null
        }
        if (isRoleplay && roleplayPermissions && !hasPermission(roleplayPermissions)) {
          return <Redirect to="/dashboard" />
        }
        if (isAuthenticated) {
          return <Component {...props} />
        }
        return <Redirect to="/signin" />
      }}
    />
  )
}

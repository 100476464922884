import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SubmitUpgradePlanInput } from '../../domain/dtos/submit-upgrade-plan-input'
import { QueryKey } from '../../domain/enums/query-key'
import { SubmitUpgradePlan } from '../../usecases/submit-upgrade-plan'

export const useSubmitUpgradePlan = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.submitUpgradePlan],
    (input: Partial<SubmitUpgradePlanInput>) => new SubmitUpgradePlan(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: async (_, input) => {
        queryClient.invalidateQueries([QueryKey.initChangePaymentMethod, input.subscriptionId])
        await new Promise((r) => setTimeout(r, 2000))
        queryClient
          .getQueryCache()
          .getAll()
          .forEach((query) => {
            if (query.queryKey[0] === QueryKey.getSubscriptionInvoices) {
              queryClient.invalidateQueries(query.queryKey)
            }
          })
      },
    },
  )
}

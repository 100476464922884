import { FormikProvider, useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { VerifyCodeInvalidError } from '../../../domain/errors/verify-code-invalid-error'
import { AuthRedirectContext } from '../../../providers/auth-redirect-provider'
import { usePostSignInByEmailVerifyCode } from '../../hooks/use-post-sign-in-by-email-verify-code'
import { completeRegistrationVerifySchema } from '../../validations/complete-registration-verify'
import { AuthCard } from '../auth-card/auth-card'
import { CountdownButton } from '../countdown-button'
import { SignInByEmailCompleteRegistration } from './sign-in-by-email-complete-registration'

import { Alert, AlertDescription, Button, Input, Label } from '@hub-la/shadcn'

interface SignInByEmailVerifyCodeProps {
  authId: string
  email: string
  nextRetryDate: Date
  retryCode: () => void
  loadingRetry: boolean
}

const SignInByEmailVerifyCode: React.FC<SignInByEmailVerifyCodeProps> = ({
  authId,
  email,
  nextRetryDate,
  retryCode,
  loadingRetry,
}) => {
  const [showSkipButton, setShowSkipButton] = useState<boolean>(false)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { t } = useTranslation()
  const { isError, error, mutateAsync, isLoading, reset, isSuccess } = usePostSignInByEmailVerifyCode({
    onError: (error) => {
      if (!(error instanceof VerifyCodeInvalidError)) {
        setShowSkipButton(true)
      }
    },
  })
  const { redirectAutoIfAuthenticated } = useContext(AuthRedirectContext)

  const form = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: async (values) => {
      if (!authId) return
      const captcha = await executeRecaptcha?.('email_signin')

      await mutateAsync({ code: parseInt(values.code), authId, captcha })
    },
    validationSchema: completeRegistrationVerifySchema,
    validateOnChange: true,
    validateOnMount: true,
  })

  if (isSuccess) {
    return <SignInByEmailCompleteRegistration code={form.values.code} authId={authId} />
  }

  return (
    <AuthCard
      showTerms={false}
      title="Verifique seu e-mail"
      description={
        <>
          Enviamos um código de verificação para
          <br />
          <strong>{email}</strong>
        </>
      }
    >
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit} className="space-y-4 w-full">
          <div className="space-y-2">
            <Label htmlFor="code">Código de verificação</Label>
            <Input
              id="code"
              name="code"
              autoComplete="one-time-code"
              onChange={form.handleChange}
              value={form.values.code}
            />
            {form.touched.code && form.errors.code && <p className="text-sm text-red-500">{t(form.errors.code)}</p>}
          </div>

          <Button className="w-full" type="submit" disabled={!form.isValid || isLoading || isSuccess}>
            {isLoading || isSuccess ? 'Carregando...' : 'Confirmar código'}
          </Button>

          {nextRetryDate && (
            <CountdownButton endDate={nextRetryDate} onClick={retryCode} isLoading={loadingRetry}>
              Reenviar código de verificação
            </CountdownButton>
          )}

          {showSkipButton && (
            <Button variant="link" className="w-full" onClick={() => redirectAutoIfAuthenticated()} type="button">
              Pular essa etapa
            </Button>
          )}
        </form>
      </FormikProvider>

      {isError && (
        <Alert variant="destructive" className="mt-4">
          <AlertDescription>{t((error as Error)?.message)}</AlertDescription>
        </Alert>
      )}
    </AuthCard>
  )
}

export { SignInByEmailVerifyCode }

/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { BNPLSettingsDto } from './bnplsettings-dto';

/**
 * 
 * @export
 * @interface EditOfferCheckoutSettingsDto
 */
export interface EditOfferCheckoutSettingsDto {
  /**
   * 
   * @type {Array<string>}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'paymentMethods': Array<EditOfferCheckoutSettingsDtoPaymentMethodsEnum>;
  /**
   * 
   * @type {boolean}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'showCouponField': boolean;
  /**
   * 
   * @type {boolean}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'showBillingAddress': boolean;
  /**
   * 
   * @type {string}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'defaultPlan'?: EditOfferCheckoutSettingsDtoDefaultPlanEnum;
  /**
   * 
   * @type {string}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'purchaseButtonText'?: string | null;
  /**
   * 
   * @type {number}
   * @memberof EditOfferCheckoutSettingsDto
   */
  /**
  * 
  * @type {string}
  * @memberof EditOfferCheckoutSettingsDto
  */
  'sideBannerMobileUrl'?: string | null;
  /**
  * 
  * @type {string}
  * @memberof EditOfferCheckoutSettingsDto
  */
  'sideBannerDesktopUrl'?: string | null;

  /**
  * 
  * @type {string}
  * @memberof EditOfferCheckoutSettingsDto
  */
  'footerBannerMobileUrl'?: string | null;

  /**
  * 
  * @type {string}
  * @memberof EditOfferCheckoutSettingsDto
  */
  'footerBannerDesktopUrl'?: string | null;

  /**
  * 
  * @type {string}
  * @memberof EditOfferCheckoutSettingsDto
  */
  'guaranteeSealMobileUrl'?: string | null;

  /**
  * 
  * @type {string}
  * @memberof EditOfferCheckoutSettingsDto
  */
  'guaranteeSealDesktopUrl'?: string | null;

  /**
  * 
  * @type {string}
  * @memberof EditOfferCheckoutSettingsDto
  */
  'purchaseButtonBgColor'?: string | null;
  /**
  * 
  * @type {string}
  * @memberof EditOfferCheckoutSettingsDto
  */
  'purchaseButtonTextColor'?: string | null;
  /**
  * 
  * @type {number}
  * @memberof EditOfferCheckoutSettingsDto
  */
  'defaultInstallment'?: number | null;
  /**
  * 
  * @type {string}
  * @memberof EditOfferCheckoutSettingsDto
  */
  'priceFormat': string | null
  /**
   * @type {string}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'layoutTemplate'?: CheckoutTemplate | null;
  /**
   * @type {string}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'lightDarkMode'?: LightDarkMode | null;
  /**
   * @type {string}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'purchaseExperience'?: PurchaseExperience | null;
  /**
   * 
   * @type {string}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'logoPosition'?: LogoPosition | null;
  /**
   * 
   * @type {string}
   * @memberof EditOfferResponseDto
   */
  'bgColor'?: string | null;
  /**
   * 
   * @type {string}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'timerBgColor'?: string | null;
  /**
   * 
   * @type {number}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'timerSeconds'?: number | null;
  /**
   * 
   * @type {string}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'logoUrl'?: string | null;
  /**
   * 
   * @type {string}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'bannerDesktopUrl'?: string | null;
  /**
   * 
   * @type {string}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'bannerMobileUrl'?: string | null;
  /**
   * 
   * @type {string}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'supportUrl'?: string | null;
  /**
   * 
   * @type {string}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'termsUrl'?: string | null;
  /**
   * 
   * @type {string}
   * @memberof EditOfferCheckoutSettingsDto
   */
  'youtubeEmbedUrl'?: string | null;
}
/**
    * @export
    * @enum {string}
    */
export enum CheckoutTemplate {
  NO_BANNER = 'no_banner',
  TOP_AND_BOTTOM_BANNER = 'top_and_bottom_banner',
  FULL_BANNER = 'full_banner',
}
/**
    * @export
    * @enum {string}
    */
export enum LightDarkMode {
  LIGHT = 'light',
  DARK = 'dark',
}
/**
    * @export
    * @enum {string}
    */
export enum LogoPosition {
  LEFT = 'left',
  CENTER = 'center',
}
/**
    * @export
    * @enum {string}
    */
export enum EditOfferCheckoutSettingsDtoPaymentMethodsEnum {
  CREDIT_CARD = 'credit_card',
  BANK_SLIP = 'bank_slip',
  PIX = 'pix',
  BNPL = 'bnpl'
}
/**
    * @export
    * @enum {string}
    */
export enum EditOfferCheckoutSettingsDtoDefaultPlanEnum {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMIANNUALLY = 'semiannually',
  ANNUALLY = 'annually'
}
/**
    * @export
    * @enum {string}
    */
export enum EditOfferCheckoutSettingsDtoLightDarkModeEnum {
  LIGHT = 'light',
  DARK = 'dark'
}
/**
    * @export
    * @enum {string}
    */
export enum EditOfferCheckoutSettingsDtoLayoutTemplateEnum {
    NO_BANNER = 'no_banner',
    TOP_AND_BOTTOM_BANNER = 'top_and_bottom_banner',
    FULL_BANNER = 'full_banner'
}
/**
    * @export
    * @enum {string}
    */
export enum EditOfferCheckoutSettingsDtoPurchaseExperienceEnum {
    ONE_STEP = 'one-step',
    TWO_STEPS = 'two-steps'
}
/**
    * @export
    * @enum {string}
    */
export enum EditOfferCheckoutSettingsDtoLogoPositionEnum {
  LEFT = 'left',
  CENTER = 'center'
}
/**
    * @export
    * @enum {string}
    */
export enum PriceFormat {
  FULL = 'full',
  PARTIAL = 'partial'
}
export enum PurchaseExperience {
  ONE_STEP = 'one-step',
  TWO_STEP = 'two-step',
}
